import { Button, Modal } from 'antd';
import React, { useEffect } from 'react';
import styles from '../../../css/DesignerScreenV2.module.css';
import { useReactive } from 'ahooks';
import { getImageDataFromSide, getShapeDataFromSide } from '../tools';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { addDesignToCart } from '../../../actions/designActions';
import { addToCart } from '../../../actions/cartActions';

const ImageCom = ({ shadowOverlayUrl, imagePreview, text }) => (
  <div className={styles.previewImgWrap}>
    <img
      src={shadowOverlayUrl}
      alt="preview-overlay"
      className={styles.previewImg}
      style={{
        position: 'absolute',
      }}
    />
    <img
      src={imagePreview}
      alt="preview-image"
      className={styles.previewImg}
      style={{ display: 'block', verticalAlign: 'middle', height: 'auto' }}
    />
    <strong className="mt-2">{text}</strong>
  </div>
);

export default function PreviewModal(props) {
  console.log('props - >:', props);
  const { row } = props;
  const { front, back, tempShapeJson, historyMap } = row;

  const dispatch = useDispatch();
  const designInfo = useSelector((state) => state.designInfo);

  const state = useReactive({
    frontImagePreview: '',
    backImagePreview: '',
    towimgbase64data: '',

    loading: false,
  });

  useEffect(() => {
    state.frontImagePreview = getImageDataFromSide(front, true, tempShapeJson);
    state.backImagePreview = getImageDataFromSide(back, true, tempShapeJson);

    drawAndShareImage();
  }, []);

  const drawAndShareImage = () => {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    canvas.width = 800; // 400
    canvas.height = 300; // 150

    context.rect(0, 0, canvas.width, canvas.height);
    context.fillStyle = '#fff';
    context.fill();

    let frontImage = new Image(400, 300);
    frontImage.src = state.frontImagePreview;
    frontImage.crossOrigin = 'Anonymous';
    frontImage.onload = function () {
      let w = 400,
        h = (400 * frontImage.height) / frontImage.width;
      let y = (300 - h) / 2;
      context.drawImage(frontImage, 0, y, w, h);
    };

    let backImage = new Image(400, 300);
    backImage.src = state.backImagePreview;
    backImage.crossOrigin = 'Anonymous';
    backImage.onload = function () {
      let w = 400,
        h = (400 * backImage.height) / backImage.width;
      let y = (300 - h) / 2;
      context.drawImage(backImage, 400, y, w, h);
      let base64 = canvas.toDataURL('image/png');

      state.towimgbase64data = base64;
    };
  };

  const handleCheckout = async () => {
    styles.loading = true;

    //上传数据的函数后
    dispatch(
      addDesignToCart(
        {
          name: row.name,
          imgFront: state.frontImagePreview,
          imgBack: state.backImagePreview,
          imgdata: state.towimgbase64data,
          state1: JSON.stringify(getShapeDataFromSide(front)),
          state2: JSON.stringify(getShapeDataFromSide(back)),
          jsondata: JSON.stringify(designInfo.scents),
          price: designInfo.price,
          total: designInfo.total,
          quality: 1,
          pk: designInfo.fresheners.id,
        },
        (res) => {
          dispatch(
            addToCart(
              {
                ...designInfo.fresheners,
                name: row.name,
                id: res.productId,
                price: designInfo.price,
                scentTotal: designInfo.total,
                image: res.image,
              },
              1,
              designInfo.fresheners.color,
              designInfo.fresheners.combo,
              false,
            ),
          );
          styles.loading = false;

          if (localStorage.getItem('reOrder')) {
            localStorage.removeItem('reOrder');
          }
          
          props.ok();
        },
      ),
    );
  };

  return (
    <Modal
      {...props}
      zIndex={5000}
      footer={[
        <Button
          key="back"
          onClick={() => props.cancel()}
        >
          Back to Design
        </Button>,

        props.type === 'addToBasket' && (
          <Button
            type="primary"
            key="continue"
            loading={styles.loading}
            onClick={() => handleCheckout()}
          >
            Checkout <ArrowRightOutlined />
          </Button>
        ),
      ]}
      onCancel={() => props.cancel()}
      className={styles.previewModal}
    >
      <div className={styles.previewContainer}>
        <ImageCom
          imagePreview={state.frontImagePreview}
          shadowOverlayUrl={tempShapeJson.shadow_overlay}
          text="Front"
        />
        <ImageCom
          imagePreview={state.backImagePreview}
          shadowOverlayUrl={tempShapeJson.shadow_overlay_inverse}
          text="Back"
        />
      </div>
    </Modal>
  );
}
