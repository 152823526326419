import React, { useEffect } from 'react';
import styles from '../../../css/DesignerScreenV2.module.css';
import { Button, Input, Tabs } from 'antd';
import { useReactive } from 'ahooks';
import { addTextToCanvas, changeCommon, DEFAULT_FONT, FONT_FAMILY } from '../tools';
import ColorSelector from '../../../components/ColorSelector';
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
} from '@ant-design/icons';

export default function FontPanel(props) {
  const state = useReactive({
    inputText: '',

    selectFontFamily: DEFAULT_FONT,

    fontColor: '',
    bgcolor: '',

    fontSize: '',

    underLine: false,
    fontWeight: false,
    fontStyle: false,

    textAlign: '',

    showAttrPanel: false,
  });

  return (
    <>
      <div className={styles.actionCard}>
        <div className={styles.textPanelContent}>
          {FONT_FAMILY.map((font) => (
            <div
              key={font}
              className={[
                styles.textPanelFont,
                state.selectFontFamily === font ? styles.activeTextPanelFont : null,
              ].join(' ')}
              style={{
                fontFamily: font,
                fontSize: [
                  'Kumar_One_Outline',
                  'Stint_Ultra_Expanded',
                  'Monoton',
                  'Permanent_Marker',
                ].includes(font)
                  ? '0.15rem'
                  : '',
              }}
              onClick={() => {
                state.selectFontFamily = font;
                changeCommon(props, 'fontFamily', font);
              }}
            >
              {font.split('_').join(' ')}
            </div>
          ))}
        </div>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: 'Font',
              children: (
                <div className={styles.fontBottom}>
                  <Input
                    className={styles.fontInput}
                    value={state.fontSize}
                    onChange={(e) => {
                      state.fontSize = Number(e.target.value);
                      changeCommon(props, 'fontSize', state.fontSize);
                    }}
                  />
                  <Button
                    style={{ borderRadius: '6px 0 0 6px' }}
                    icon={<UnderlineOutlined />}
                    onClick={(e) => {
                      state.underLine = !state.underLine;
                      changeCommon(props, 'underline', state.underLine);
                    }}
                  />
                  <Button
                    style={{ borderRadius: 0 }}
                    icon={<BoldOutlined />}
                    onClick={(e) => {
                      state.fontWeight = !state.fontWeight;
                      changeCommon(props, 'fontWeight', state.fontWeight ? 'bold' : 'normal');
                    }}
                  />
                  <Button
                    style={{
                      borderRadius: '0 6px 6px 0',
                      marginRight: 10,
                    }}
                    icon={<ItalicOutlined />}
                    onClick={(e) => {
                      state.fontStyle = !state.fontStyle;
                      changeCommon(props, 'fontStyle', state.fontStyle ? 'italic' : 'normal');
                    }}
                  />
                  <Button
                    style={{ borderRadius: '6px 0 0 6px' }}
                    onClick={(e) => {
                      state.textAlign = 'left';
                      changeCommon(props, 'textAlign', state.textAlign);
                    }}
                    icon={<AlignLeftOutlined />}
                  />
                  <Button
                    style={{ borderRadius: 0 }}
                    icon={<AlignCenterOutlined />}
                    onClick={(e) => {
                      state.textAlign = 'center';
                      changeCommon(props, 'textAlign', state.textAlign);
                    }}
                  />
                  <Button
                    style={{ borderRadius: '0 6px 6px 0' }}
                    icon={<AlignRightOutlined />}
                    onClick={(e) => {
                      state.textAlign = 'right';
                      changeCommon(props, 'textAlign', state.textAlign);
                    }}
                  />
                </div>
              ),
            },
            {
              key: '2',
              label: 'Color',
              children: (
                <ColorSelector
                  activeColor={state.fontColor}
                  handleClick={(color) => {
                    state.fontColor = color;
                    changeCommon(props, 'fill', color);
                  }}
                />
              ),
            },
            {
              key: '3',
              label: 'Background',
              children: (
                <ColorSelector
                  activeColor={state.bgcolor}
                  handleClick={(color) => {
                    state.bgcolor = color;
                    changeCommon(props, 'backgroundColor', color);
                  }}
                />
              ),
            },
          ]}
        />
      </div>
    </>
  );
}
