import React from 'react';
import ColorSelector from '../../../components/ColorSelector';
import { useReactive } from 'ahooks';
import { setBackgroundColor } from '../tools';

export default function BackgroundPanel(props) {
  const state = useReactive({
    activeColor: '',
  });

  return (
    <>
      <ColorSelector
        activeColor={state.activeColor}
        handleClick={(color) => {
          state.activeColor = color;
          setBackgroundColor(props, color);
        }}
      />
    </>
  );
}
