import React from 'react';
import styles from '../../../css/DesignerScreenV2.module.css';
import { Button, Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { duplicateToOtherSide, SIDES } from '../tools';

export default function StickerPanel(props) {
  const otherSideHasObjects = (props) => {
    const { currentType, allCanvas } = props;

    return currentType === SIDES.FRONT
      ? allCanvas.back.getObjects().length > 0
      : allCanvas.front.getObjects().length > 0;
  };

  const handleCopyToAnother = () => {
    if (otherSideHasObjects(props)) {
      Modal.confirm({
        title: 'Are You Sure?',
        icon: <ExclamationCircleFilled />,
        content:
          'Any artwork on the other side of this design will be lost. This action can not be undone.',
        okText: 'Duplicate',
        onOk() {
          duplicateToOtherSide(props);
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      duplicateToOtherSide(props);
    }
  };

  return (
    <>
      <div className={styles.actionCard}>
        <div className={styles.actionCardTitle}>Copy</div>
        <div>
          <div className={styles.actionCardSubTitle}>Duplicate Design to Other Side:</div>
          <div className={styles.formatActions}>
            <Button onClick={handleCopyToAnother}>Duplicate Design to Other Side</Button>
          </div>
        </div>
      </div>
    </>
  );
}
