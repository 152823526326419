import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { Link } from 'react-router-dom';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Autoplay]);

function DiscoverMoreCarousel({ slidesPerView }) {
  return (
    <div className="relative discover-more-carousel">
      <div className="swiperTitle">Discover More</div>

      <Swiper
        direction="horizontal"
        loop={true}
        slidesPerView={slidesPerView}
        spaceBetween={20}
        autoplay
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          disabledClass: 'disable'
        }}
      >
        <SwiperSlide>
          <Link to="/store/3" className="swiperImg">
            <img src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui1.png" className="carUi" />
            <div className="name flex">Chemical Products</div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/store/4" className="swiperImg">
            <img src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui2.png" className="carUi" />
            <div className="name flex">Air Fresheners</div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/store/6" className="swiperImg">
            <img src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui3.png" className="carUi" />
            <div className="name flex">H.P. Pump and Parts</div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/store/7" className="swiperImg">
            <img src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui4.png" className="carUi" />
            <div className="name flex">Vacuum Cleaners</div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
            <Link to="/store/8" className="swiperImg">
                <img src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui5.png" className="carUi"/>
                <div className="name flex">Aerosol Products</div>
            </Link>
        </SwiperSlide>
        <SwiperSlide>
            <Link to="/store/5" className="swiperImg">
                <img src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui6.png" className="carUi"/>
                <div className="name flex">Compression Sprayers</div>
            </Link>
        </SwiperSlide>
        <SwiperSlide>
            <Link to="/store/9" className="swiperImg">
                <img src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui7.png" className="carUi"/>
                <div className="name flex">Valeting Accessories</div>
            </Link>
        </SwiperSlide>
        <SwiperSlide>
            <Link to="/store/20" className="swiperImg">
                <img src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui7.png" className="carUi"/>
                <div className="name flex">Pipe Fixing And Fitting</div>
            </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/store/12" className="swiperImg">
            <img src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui12.png" className="carUi" />
            <div className="name flex">Microfibre</div>
          </Link>
        </SwiperSlide>
      </Swiper>

      <div className="swiper-button-prev swiper-button">
        <img src="./images/index/left.png"  />
      </div>
      <div className="swiper-button-next swiper-button">
        <img src="./images/index/right.png" />
      </div>
    </div>
  );
}

export default DiscoverMoreCarousel;
