import React from 'react';
import styles from '../../../css/DesignerScreenV2.module.css';

export default function StickerPanel() {
  return (
    <>
      <div className={styles.actionCard}>
        <div className={styles.actionCardTitle}>Stickers</div>
      </div>
    </>
  );
}
