import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_SAVE_SHIPPING_ADDRESS,

    CART_SAVE_PAYMENT_METHOD,

    CART_CLEAR_ITEMS,
    CART_REQUEST_DATA,
    SET_SIDEBAR_SHOW
} from '../constants/cartConstants'



export const cartReducer = (state = { cartItems: 0, itemsList: [], shippingAddress: null, sidebarShow: false }, action) => {
    switch (action.type) {
        case CART_REQUEST_DATA:
            if(!(action.payload &&action.payload.cartItems)) return state;
            let obj = {
                ...state,
                cartItems: action.payload.cartItems || 0,
                itemsList: action.payload.itemsList,
            };
            localStorage.setItem('cartItems', JSON.stringify(obj))
            return obj

        case CART_ADD_ITEM:

            const item = action.payload
            const existItem = state.itemsList? state.itemsList.find(x => x.product === item.product && item.color===x.color && item.combo===x.combo) : null
            if (existItem) {
                let qty = item.qty
                item.qty = existItem.qty * 1 + (item.qty  || 1);
                return {
                    ...state,
                    cartItems: (state.cartItems*1 || 0) + (qty  || 1),
                    itemsList: state.itemsList.map(x =>
                        x.product === item.product && item.color===x.color && item.combo===x.combo ? item : x),
                    sidebarShow:  typeof item.sidebarShow === 'undefined'? true:item.sidebarShow
                }

            } else {
                return {
                    ...state,
                    cartItems: (state.cartItems*1 || 0) + 1,
                    itemsList: [...(state.itemsList || []), item],
                    sidebarShow:  typeof item.sidebarShow === 'undefined'? true:item.sidebarShow
                }
            }

        case CART_REMOVE_ITEM:
            let item2 = state.itemsList[action.payload] || {};
            let cartItems = (state.cartItems*1 || 0) - item2.qty
            let obj2 = {
                ...state,
                cartItems: cartItems<0?0:cartItems ,
                itemsList: state.itemsList.filter((x, index) => index !== action.payload)
            };
            localStorage.setItem('cartItems', JSON.stringify(obj2))
            return obj2

        case CART_SAVE_SHIPPING_ADDRESS:
            return {
                ...state,
                shippingAddress: action.payload
            }



        case CART_SAVE_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.payload
            }

        case CART_CLEAR_ITEMS:
            let obj3 = {
                ...state,
                itemsList: [],
                cartItems: 0
            };
            localStorage.setItem('cartItems', JSON.stringify(obj3))
            return obj3;

        case SET_SIDEBAR_SHOW:
            const cartItemsStr = localStorage.getItem('cartItems')
            if (cartItemsStr) {
                const cartItemsObj = JSON.parse(cartItemsStr)
                cartItemsObj.sidebarShow = action.payload
                localStorage.setItem('cartItems', JSON.stringify(cartItemsObj))
            }
            return {
                ...state,
                sidebarShow: action.payload
            }

        default:
            return state
    }
}
