import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarShow, removeFromCart } from "../actions/cartActions";
import { CART_REQUEST_DATA } from "../constants/cartConstants";
import { Link, withRouter } from "react-router-dom";
import Checkout from "./Checkout";
import styles from "./CartSidebar.module.css";
import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Flex } from "antd";

function CartSidebar({ history }) {
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const sidebarShow = useSelector((state) => state.cart.sidebarShow);
  const lastItem =
    cart && cart.itemsList && cart.itemsList[cart.itemsList.length - 1];
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const onClose = () => {
    dispatch(setSidebarShow(false));
  };

  const changeNum = (index, num) => (e) => {
    let arr = [...cart.itemsList];
    let value = parseInt(e.target.value) || "";
    if (arr[index]) {
      arr[index].qty =
        num !== undefined ? (arr[index].qty || 0) * 1 + num : value;
      if (arr[index].qty !== "" && arr[index].qty <= 0) {
        arr[index].qty = 1;
      }
      dispatch({
        type: CART_REQUEST_DATA,
        payload: {
          itemsList: arr,
          cartItems: arr.reduce((pre, now) => pre + now.qty, 0),
        },
      });
    }
  };

  const removeFromCartHandler = (index) => () => {
    dispatch(removeFromCart(index));
  };

  // Function to calculate subtotal
  const calculateSubtotal = () => {
    return cart.itemsList.reduce((acc, item) => {
      const price = typeof item.price === "object" ? item.price[(userInfo && userInfo.name) || "A"] : item.price;
      return acc + (price * item.qty);
    }, 0).toFixed(2);
  };

  return (
    <>
      <div
        onClick={onClose}
        className="f-loading bg-black"
        style={{
          transform: sidebarShow ? "translateX(0)" : "translateX(100%)",
        }}
      ></div>
      <div className={["cart-sidebar", sidebarShow ? "open" : ""].join(" ")}>
        <div className="cart-sidebar-header">
          <div className={[styles.sidebarClose, "sidebar-close"].join(" ")}>
            <span style={{ fontWeight: "bold", fontSize: "30px", color: "#5d9eab" }}>CART</span>
            <img src="./images/index/err.png" alt="close" onClick={onClose} />
          </div>
          <div className="cart-cur-item">
            {lastItem && (
              <div className="basket-content">
                <div
                  className="flex basket-content-item"
                  style={{ justifyContent: "flex-start" }}
                >
                  <div className="flex flex-column item-left">
                    <div className="flex flex-center item-left-header">
                      <img
                        src="./images/index/dui1.png"
                        style={{ borderRadius: "50%", width: "25px", height: "25px" }}
                      />
                    </div>
                    {/* <img src={lastItem.image} style={{ width: '0.5rem', height: '0.5rem' }} /> */}
                  </div>
                  <div
                    className="flex flex-column"
                    style={{ alignItems: "flex-start" }}
                  >
                    <div
                      className="flex-between"
                      style={{ color: "#5d9eab", fontSize: "20px" }}
                    >
                      Your order qualifiles for FREE Delivery in the UK.
                    </div>
                    <p style={{ fontWeight: "bold", color: "black", fontSize: "20px" }}>
                      Subtotal: £{calculateSubtotal()}
                    </p>
                    <div className="flex-center">
                      <Link className={["cart-btn", styles.cartBtn].join(" ")} to="/cart" onClick={onClose}>
                        Basket
                      </Link>
                      <Checkout
                        className="checkout-btn slide-confirm"
                        confirmClass="slide-confirm"
                        history={history}
                        onClose={onClose}
                        text={`Proceed to checkout (${cart.itemsList.length} items)`}
                      />
                    </div>
                  </div>
                </div>
                <div className="checkout-wrapper">
                  <div className="checkout-desc">
                    Taxes and shipping calculated at checkout
                  </div>
                  <Checkout
                    className="checkout-btn1"
                    history={history}
                    onClose={onClose}
                    text={`Proceed to checkout (${cart.itemsList.length} items)`}
                  />
                  <div className="checkout-division"></div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="cart-sidebar-list ">
          {!cart || !cart.itemsList || cart.itemsList.length == 0 ? (
            <div className="no-data-box">
              <img
                src="./images/index/cart.jpg"
                style={{ width: "100px", height: "100px", marginRight: "30px" }}
              />
              <p>Your cart is empty.</p>
              <Link to="/" className="return-to-shop" onClick={onClose}>
                RETURN TO SHOP
              </Link>
            </div>
          ) : (
            ""
          )}
          {cart &&
            cart.itemsList &&
            cart.itemsList.map((item, index) => {
              return (
                <div className={["basket-content greenCard", styles.basketContent].join(" ")} key={item.product}>
                  <div
                    className="flex-between"
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <DeleteOutlined className={styles.trash} onClick={removeFromCartHandler(index)} />
                    <div>
                      <img
                        src={item.image.split('?')[0]} // 只取?之前的部分
                        style={{
                          width: "100px",
                          height: "100px",
                          marginRight: "30px",
                        }}
                      />
                    </div>

                    <div
                      className="flex-between flex-column"
                      style={{ alignItems: "flex-start", whiteSpace: "nowrap", width: "100%" }}
                    >
                      <div className="product-name" style={{maxWidth: "100%"}}>{item.name}</div>
                      <Flex gap="20px">
                        <div className="product-color">{item.color}</div>
                        <div className="product-combo">{item.combo}</div>
                      </Flex>
                      <Flex align="center" justify="space-between" style={{width: "100%", margin: "0.1rem 0"}}>
                        <div className={styles.quantityWrapper}>
                          <div
                            className={styles.quantityBtn}
                            onClick={item.qty == 1 ? removeFromCartHandler(index) : changeNum(index, -1)}
                          >
                            {item.qty == 1 ? <DeleteOutlined /> : <MinusOutlined />}
                          </div>

                          <input
                            type="text"
                            className={styles.quantityInput}
                            value={item.qty}
                            onChange={changeNum(index)}
                          />

                          <div
                            className={styles.quantityBtn}
                            onClick={changeNum(index, 1)}
                          >
                            <PlusOutlined />
                          </div>
                        </div>
                        <div className="product-price">
                          {typeof item.price === "object" ? (
                            item.discount !== 1 ? (
                              <div className="basket-money">
                                <del>
                                  £
                                  {item.price[
                                  (userInfo && userInfo.name) || "A"
                                    ].toFixed(2)}
                                </del>
                                <span
                                  style={{
                                    color: "#FFC008",
                                    marginLeft: "0.2rem",
                                  }}
                                >
                                £
                                  {(
                                    item.discount *
                                    item.price[(userInfo && userInfo.name) || "A"]
                                  ).toFixed(2)}
                              </span>
                              </div>
                            ) : (
                              <span style={{ color: "#FFC008" }}>
                              £
                                {item.price[
                                (userInfo && userInfo.name) || "A"
                                  ].toFixed(2)}
                            </span>
                            )
                          ) : (
                            <div className="basket-money">
                              £{(item.discount || 0) * item.price}
                            </div>
                          )}
                        </div>
                      </Flex>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default withRouter(CartSidebar);
