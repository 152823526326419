// import { Container } from 'react-bootstrap'
import React, { Suspense, lazy, useEffect, useState } from "react";
import {HashRouter as Router, BrowserRouter, Route, Switch, useLocation} from 'react-router-dom';
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import LoginScreen from './screens/LoginScreen'
import AboutScreen from './screens/AboutScreen'
import ContactScreen from './screens/ContactScreen'
import DesignScreen from './screens/DesignScreen'
import ProductScreen from './screens/ProductScreen'
import AccountScreen from './screens/AccountScreen'
import CartScreen from './screens/CartScreen'
import ShippingScreen from './screens/ShippingScreen'
import SelAddressScreen from './screens/SelAddressScreen'
// import OrderScreen from './screens/OrderScreen'
import OrderDetailScreen from './screens/OrderDetailScreen'
import OrderDetailScreeniwoca from './screens/OrderDetailScreeniwoca'
import OrderTouristScreen from './screens/OrderTouristScreen'
import ShapesScreen from './screens/ShapesScreen'
import FreshenerScreen from './screens/FreshenerScreen'
import DesignerScreen from './screens/DesignerScreen'
import StoreScreen from './screens/StoreScreen'
import StoreScreenNew from './screens/StoreScreenNew'
import ProductScreenNew from './screens/ProductScreenNew.js'
import PrivatepolicyScreen from './screens/PrivatepolicyScreen'
import ConditionScreen from './screens/ConditionScreen'
import TermsdeliveryScreen from './screens/TermsdeliveryScreen'
import {   useSelector } from 'react-redux'
import CartSidebar from './components/CartSidebar'
import {ConfigProvider} from "antd";
import BackTop from "./components/BackTop";
import DesignerScreenV2 from "./screens/DesignerScreenV2";
import DesignerScreenV3 from "./screens/DesignerScreenV3/index.jsx";
let AccountScreen_mobile = lazy(() => import('./screens/顶部菜单.jsx'))
let address_mobile = lazy(() => import('./components/选择地址.jsx'))
let OrderwanchengScreen = lazy(() => import('./screens/orderwanchengScreen.js'))
function App(props) {
  const headFootInfo = useSelector(state => state.headFootInfo)
  const [isShapesRoute, setIsShapesRoute] = useState(false);

  useEffect(() => {
    // Check if the current path is /shapes
    setIsShapesRoute(window.location.pathname === '/shapes');
  }, []);

    //是否移动端
    const isMobile = window.navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)
  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#FFC008' } }}>
      <Suspense fallback={<div>Loading...</div>}>
          {isShapesRoute ? (
            <BrowserRouter>
              <ScrollToTop />
              <Header />
              <main>
                <Route path='/shapes' component={ShapesScreen} exact />
                <BackTop />
              </main>
              <Footer />
            </BrowserRouter>
          ) : (
            <Router>
              <ScrollToTop />
              {headFootInfo.show && <Header />}
              <CartSidebar />
              <main>
                  {/* <Container> */}
                  <Route path='/' component={HomeScreen} exact />
                  <Route path='/about' component={AboutScreen} />
                  <Route path='/login' component={LoginScreen} />
                  <Route path='/contact' component={ContactScreen} />
                  <Route path='/design' component={DesignScreen} />
                  <Route path='/product/:id' component={ProductScreen} />
                  <Route path='/productnew/:plid/:key/:id' component={ProductScreenNew} />
                  <Route path='/account' component={isMobile?AccountScreen_mobile:AccountScreen} />
                  <Route path='/cart' component={CartScreen} />
                  <Route path='/placeorder/:id' component={ShippingScreen} />
                  <Route path='/payment/:id' component={ShippingScreen} />
                  <Route path='/selAddress' component={isMobile?address_mobile:SelAddressScreen} />
                  {/* <Route path='/order/:id' component={OrderScreen} /> */}
                  <Route path='/order/:id' component={OrderDetailScreen} />
                  <Route path='/orderwancheng/:id' component={OrderwanchengScreen} />
                  <Route path='/orderiwoca/:id' component={OrderDetailScreeniwoca} />
                  <Route path='/order' exact component={OrderTouristScreen} />
                  <Route path='/zitiquhuo/tourist' component={OrderTouristScreen} />
                  <Route path='/shapes' component={ShapesScreen} />
                  <Route path='/freshener/:id' component={FreshenerScreen} />
                  <Route path='/designer' component={DesignerScreen} />
                  <Route path='/store/:id' component={StoreScreen} />
                  <Route path='/storenew/:plid/:key' component={StoreScreenNew} />
                  <Route path='/privatepolicy' component={PrivatepolicyScreen} />
                  <Route path='/condition' component={ConditionScreen} />
                  <Route path='/termsdelivery' component={TermsdeliveryScreen} />
                  {/* <Route path='/designerScreen' component={DesignerScreenV2} /> */}
                  <Route path='/designerScreen' component={DesignerScreenV3} />
                  {/* <Route path='/register' component={RegisterScreen} />
          <Route path='/profile' component={ProfileScreen} />
          <Route path='/shipping' component={ShippingScreen} />
          <Route path='/placeorder' component={PlaceOrderScreen} />
          <Route path='/order/:id' component={OrderScreen} />
          <Route path='/payment' component={PaymentScreen} />
          <Route path='/product/:id' component={ProductScreen} />
          <Route path='/cart/:id?' component={CartScreen} />

          <Route path='/admin/userlist' component={UserListScreen} />
          <Route path='/admin/user/:id/edit' component={UserEditScreen} />

          <Route path='/admin/productlist' component={ProductListScreen} />
          <Route path='/admin/product/:id/edit' component={ProductEditScreen} />

          <Route path='/admin/orderlist' component={OrderListScreen} /> */}
                  {/* </Container> */}
                <BackTop />
              </main>
              {headFootInfo.show && <Footer />}
          </Router>
        )}
      </Suspense>
    </ConfigProvider>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
