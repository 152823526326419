import React, { useEffect } from 'react';
import styles from '../../../css/DesignerScreenV2.module.css';
import { Button, Input, Tabs } from 'antd';
import { useReactive } from 'ahooks';
import { addTextToCanvas, changeCommon, DEFAULT_FONT, FONT_FAMILY } from '../tools';
import ColorSelector from '../../../components/ColorSelector';
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
} from '@ant-design/icons';

export default function AddTextPanel(props) {
  const state = useReactive({
    inputText: '',

    selectFontFamily: DEFAULT_FONT,

    fontColor: '',
    bgcolor: '',

    fontSize: '',

    underLine: false,
    fontWeight: false,
    fontStyle: false,

    textAlign: '',

    showAttrPanel: false,
  });
  const addText = () => {
    addTextToCanvas(props, state.inputText);

    props?.changeAttr?.();
    state.inputText = '';
  };

  useEffect(() => {
    state.showAttrPanel = props.showAttrPanel;
  }, [props.showAttrPanel]);

  return (
    <>
      <div className={styles.actionCard}>
        <div className={styles.actionCardTitle}>Add Text</div>
        <Input
          style={{ marginBottom: 15 }}
          placeholder="Enter text here"
          value={state.inputText}
          onChange={(e) => (state.inputText = e.target.value)}
        />
        <Button
          className={styles.fullwidth}
          type="primary"
          onClick={() => addText()}
        >
          Add
        </Button>
      </div>
    </>
  );
}
