import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createOrder,checkCoupon, fapiao } from '../actions/orderActions'
import { ORDER_CREATE_RESET } from '../constants/orderConstants'
import {CART_CLEAR_ITEMS} from '../constants/cartConstants'
import '../css/ShippingScreen.css'
import Message from '../components/Message'
import {ToastsStore,ToastsContainer, ToastsContainerPosition } from 'react-toasts'
import Loader from '../components/Loader'
function ShippingScreen({ history, match }) {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    // Helper function to clean the image URL
    const cleanImageUrl = (url) => {
      const questionMarkIndex = url.indexOf('?');
      return questionMarkIndex !== -1 ? url.substring(0, questionMarkIndex) : url;
    };
    const [paymentMethod, setPaymentMethod] = useState(localStorage.getItem('paymentMethod') || 'PayPal')
    const dispatch = useDispatch()
    // console.log(history.location.pathname, 'history111');
    const [tab, setTab] = useState(history.location.pathname.indexOf('/payment')!=-1?2: 1)
    const orderInfo = useSelector(state => state.order)
    const messageInfo = useSelector(state => state.message)
    const { order, createSuccess, loading } = orderInfo
    const cart = useSelector(state => state.cart)
    const { shippingAddress } = cart
    const [coupon,setCoupon] = useState(JSON.parse(localStorage.getItem("coupon") || "{}"))
    const [priceTotal,setPriceTotal] = useState(0)
    const [couponCode,setCouponCode] = useState(null)
    const items = cart.itemsList.map((item, index) => ({name: item.name, id: item.id, color: item.color, qty: item.qty}));
    const jsonData = JSON.stringify(items.map(item => ({
      name: item.name,
      id: item.id,
      color: item.color,
      qty: item.qty
    })));

    useEffect(() => {
        if(cart&&cart.address_id === 'zitiquhuo' ) {
            setPaymentMethod('PayPal')
        }
        return () => {
            localStorage.removeItem("coupon")
        }
    })

    useEffect(() => {
        getTotalPrice()
    },[coupon])
    const [moneySaved, setMoneySaved] = useState(0);

    // console.log(cart,99991123)
    cart.address_id = match.params.id || undefined
    let hazard = 1
    cart.itemsPrice = cart.itemsList.reduce((acc, item) => {
        if(+item.hazard === 0 ) {
            hazard = 0
        }
        let price = item.price
        if( typeof price === 'object') {
            price = item.price[userInfo&&userInfo.name || 'A' ]
        }
        return acc + (price * (item.discount || 1)) * item.qty
    }, 0).toFixed(2)
    // cart.yunfei = (cart.itemsPrice > 10 ? 0 : 1.5).toFixed(2)
    let flag = (userInfo && +userInfo.menkan100 === 0) || !userInfo
    let flag2 = (userInfo && +userInfo.menkan300 === 0) || !userInfo
    cart.yunfei = cart.address_id === 'zitiquhuo' ? 0 : (hazard === 1 ? 
        ( flag && cart.itemsPrice < 100  ? 10 : 0) : 
        ( flag2 && cart.itemsPrice < 600 ? 50 : 0))
    cart.Shui = Number( cart.itemsPrice - ( cart.itemsPrice / 1.2)).toFixed(2)

    const getTotalPrice = () => {
        let price = (Number(cart.itemsPrice) + Number(cart.yunfei)).toFixed(2);
        let moneySaved = 0; // initialize the moneySaved variable to 0

        if (!coupon.type) {
            setPriceTotal(price);
            return;
        }

        const { type, num, man } = coupon;

        if (type === 2) {
            // Percentage discount
            let discount = price * num;
            price -= discount;
            moneySaved = discount; // set moneySaved to the amount of the discount
            ToastsStore.success(<p className='toasts_success'>Coupon applied successfully!</p>);
        } else if (type === 1) {
            // Fixed discount
            if (price >= man) {
                let discount = num;
                price -= discount;
                moneySaved = discount; // set moneySaved to the amount of the discount
                ToastsStore.success("Coupon applied successfully!");
            } else {
                ToastsStore.error("Not applicable");
            }
        } else if (type === 4) {
            // Discount on total price
            if (price > 0) {
                let discount = num;
                price -= discount;
                moneySaved = discount; // set moneySaved to the amount of the discount
                ToastsStore.success("Coupon applied successfully!");
            } else {
                ToastsStore.error("Not applicable");
            }
        }

        setMoneySaved(moneySaved); // store the moneySaved value in a state variable
        setPriceTotal(price);
    }

 
    let customerName = userInfo&&userInfo.email?userInfo.email:''
    if(cart.address_id === 'tourists-shipping') {
        customerName = shippingAddress.email;
    }
    
    
    const placeOrder = () => {
        if(cart.address_id === 'tourists-shipping') {
            history.push(`/order`)
            return;
        }
        if(cart.address_id === 'zitiquhuo' && !userInfo) {
           
            history.push(`/zitiquhuo/tourist`)
            return;
        }
        console.log(cart,'cart');

        let param = {
            shui: cart.Shui,
            orderItems: cart.itemsList.map(item=>{
                let it = {...item}
                
                delete it.scentTotal;
                delete it.sidebarShow;
                if(typeof it.product === 'object')  it.product = it.product.productId 
                it.price = typeof it.price === 'object' ? it.price[userInfo&&userInfo.name || 'A']:it.price
               
                it.type = item.combo
                it.productId = it.product
                return it;
            }),
            itemsPrice: cart.itemsPrice,
            jiage: priceTotal,
            yunfei: cart.yunfei,
            customer_name: customerName,
            ztaddress: '',
            addressid: 0,
            huodaofukuan: paymentMethod === 'Cash on Delivery' ? 1 : 0,
            couponCode
        }
        // if(cart.shippingAddress && cart.shippingAddress.name) param.shippingAddress = cart.shippingAddress;
        if(typeof cart.address_id !=='undefined' && cart.address_id !== 'zitiquhuo') param.addressid = cart.address_id;
        if(cart.address_id === 'zitiquhuo') {
            param.ztaddress = shippingAddress.dizhi+','+
                              shippingAddress.dz1+','+
                              shippingAddress.dz2+','+
                              shippingAddress.dz2+','+
                              shippingAddress.postcode+','+
                              shippingAddress.tel;
        }
        dispatch(createOrder(param,paymentMethod==='Cash on Delivery'? (data)=>{
            dispatch(fapiao(data.order_id, data))
        }:undefined))
    }
    const next = () => {
        // setTab(2);
        localStorage.setItem('paymentMethod', paymentMethod)
        history.push('/payment/'+(cart.address_id||''));
    }
    useEffect(() => {
        if (createSuccess) {
            const id = order.order_id;
            console.log(222)
            dispatch({ type: CART_CLEAR_ITEMS });
            dispatch({ type: ORDER_CREATE_RESET });
            console.log(1111,paymentMethod)
            if (paymentMethod === "PayPal" || paymentMethod === "Cash on Delivery") {
                history.push(`/order/${id}`);
            } else if (paymentMethod === "Iwoca") {
                history.push(`/orderiwoca/${id}`);
            }
        }
    }, [createSuccess, history, paymentMethod, order]);
    return (
        <div className="ShippingScreen">
            {loading && <Loader />}
            {messageInfo.msg && <Message variant={messageInfo.variant}>{messageInfo.msg}</Message> }
            <div className="nav">
                <span className={tab >= 1 ? 'active' : ''}>Payment
                    {tab == 1 ? <img className="cart-logo" src="./images/index/cart.jpg" /> : ''}
                </span>
                <span className={tab >= 2 ? 'active' : ''}>Place Order
                {tab == 2 ? <img className="cart-logo" src="./images/index/cart.jpg" /> : ''}
                </span>
            </div>
            {
                tab == 1 ? (<form >
                    <div className="form-group">
                        <legend className="form-label">Select Method</legend>
                        <div className="col">
                            <div className="form-check">
                                <input onClick={() => setPaymentMethod('PayPal')} name="paymentMethod" type="radio" id="paypal" className="form-check-input" checked={paymentMethod==='PayPal'} />
                                <label title="" htmlFor="paypal" className="form-check-label">PayPal or Credit Card</label>
                            </div>
                            {
                                userInfo && +userInfo.xianjin > 0 && cart.address_id !== 'zitiquhuo' &&
                                <div className="form-check">
                                    <input onClick={() => setPaymentMethod('Cash on Delivery')} name="paymentMethod" type="radio" id="paypal2" className="form-check-input" checked={paymentMethod==='Cash on Delivery'} />
                                    <label title="" htmlFor="paypal2" className="form-check-label">Vip Delivery Checkout</label>
                                </div>
                            }
                            {
                                userInfo && cart.address_id !== 'zitiquhuo' &&
                                <div className="form-check">
                                <input
                                    onClick={() => {
                                    if (priceTotal >= 150) {
                                        setPaymentMethod('Iwoca');
                                    } else {
                                        setPaymentMethod('PayPal');
                                    }
                                    }}
                                    name="paymentMethod"
                                    type="radio"
                                    id="iwoca"
                                    className="form-check-input"
                                    disabled={priceTotal < 150}
                                />
                                    <label title="" htmlFor="iwoca" className="form-check-label">
                                    {priceTotal >= 150 ? "Iwoca Financing" : "Iwoca Financing not available for order below £150"}
                                    </label>
                                </div>
                            }

                        </div>

                    </div>
                    <div className="list-group-item no-border"><button type="submit" className="btn TextGreenBtn" onClick={next}>Continue</button></div>
                </form>) : ''
            }
            {
                tab == 2 ? <div className="row PayOrder">
                    <div className="col-md-8">
                        <div className="list-group list-group-flush">
                            <div className="list-group-item">
                                <h2 className="jiesuantq">Shipping</h2>
                                {
                                    cart.address_id === 'zitiquhuo' ? 
                                    <p><strong>Picking up:</strong>
                                       {shippingAddress.dizhi},{shippingAddress.dz1},{shippingAddress.dz2},{shippingAddress.dz3}
                                    </p>
                                    :
                                    <p>
                                    <strong>Shipping:
                                        
                                        {shippingAddress&&shippingAddress.country},  {shippingAddress&&shippingAddress.county},  {shippingAddress&&shippingAddress.city},  {shippingAddress&&shippingAddress.gs},  {shippingAddress&&shippingAddress.address}</strong>
                                    
                                    </p>
                                }
                                
                            </div>
                            <div className="list-group-item">
                                <h2 className="jiesuantq">Payment Method</h2>
                            <p><strong>Method: </strong>{paymentMethod}</p>
                            </div>
                            <div className="list-group-item">
                                <h2 className="jiesuantq">Order Items</h2>
                                <div className="list-group list-group-flush">
                                    {
                                        cart.itemsList.map((item, index) => {
                                            return <div className="list-group-item">
                                                        <div className="row"><div className="col-md-1">
                                                            <img src={cleanImageUrl(item.image)} alt={item.name} className="img-fluid rounded" />
                                                        </div>
                                                            <div className="col">
                                                                <a href={"#/product/"+item.id}>{item.name}</a>
                                                            </div>
                                                            <div className="col-md-4">{item.qty} X £{((typeof item.price === 'object'?  item.price[userInfo&&userInfo.name || 'A' ] :item.price)*(item.discount || 1)).toFixed(2)} = £{(item.qty * (typeof item.price === 'object'?  item.price[userInfo&&userInfo.name || 'A' ] :item.price)*(item.discount || 1)).toFixed(2)}</div>
                                                        </div>
                                                    </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4" style={{padding: '0 .15rem'}}>
                        <div className="card">
                            <div className="list-group list-group-flush">
                                <div className="list-group-item">
                                    <h2>Order Summary</h2>
                                </div>
                                <div className="list-group-item">
                                    <div className="row"><div className="col">Subtotal:</div>
                                <div className="col">£{cart.itemsPrice}</div>
                                    </div>
                                </div>
                                <div className="list-group-item">
                                    <div className="row">
                                        <div className="col">Shipping:</div>
                                        <div className="col">£{cart.yunfei}</div>
                                    </div>
                                </div>
                                <div className="list-group-item">
                                    <div className="row">
                                        <div className="col">VAT Included:</div>
                                        <div className="col">£{cart.Shui}</div>
                                    </div>
                                </div>
                                <div className="list-group-item">
                                    <div className="row">
                                        <div className="col">Total:</div>
                                        <div className="col">£{priceTotal}</div>
                                    </div>
                                    <div className="row" id="money-saved">
                                        <div className="col">Money Saved:</div>
                                        <div className={`col ${moneySaved > 0 ? 'money-saved-positive' : ''}`}>£<span id="money-saved-value">{moneySaved.toFixed(2)}</span></div>
                                    </div>
                                </div>

                                <div className="list-group-item">
                                     <p style={{
                                         color:'rgb(67,117,123)',
                                         fontSize:'.35rem',
                                         letterSpacing:'1px',
                                         margin:'0 0 10px 0',
                                         fontFamily: 'AktivGrotesk_Trial_Bd',
                                         fontWeight:'bold'
                                         }}>
                                         Promotion and Rewards
                                         </p>
                                    <div className="row" 
                                        style={{justifyContent: 'space-between',borderBottom:'1px solid #000',padding:'4px 0'}}
                                    >
                                        <input placeholder="Enter Promo Code" value={coupon.code} onChange={(e) => {
                                            console.log(e.target.value);
                                            setCouponCode(e.target.value)
                                        }} id='couponInput' className=""/>
                                         <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER}/>
                                        <div className='apply_btn' style={{cursor:'pointer',color:'#000'}} onClick={() => {
                                            //B889999 折扣
                                            //AJIODA 满减
                                            console.log(couponCode);
                                            if(!couponCode){
                                                ToastsStore.error(<p>Please enter coupon code</p>)
                                            }else{
                                                dispatch(checkCoupon(jsonData, couponCode,setCoupon,priceTotal,(data)=>{
                                                    ToastsStore.error(data)
                                                }))
                                            }
                                        }}>Apply</div>
                                       
                                    </div>
                                </div>
                                <div className="list-group-item">
                                    <button type="button" onClick={placeOrder} className="btn TextGreenBtn PayBtn">Place Order</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ''
            }

        </div>
    )
}

export default ShippingScreen
