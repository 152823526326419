import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Confirm from './Confirm'

function Checkout({text, onClose, history, className, style, confirmClass}) {
    const [showTip, setShowTip] = useState(false);
    const [showMsg, setShowMsg] = useState('');
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const cart = useSelector(state => state.cart);
    const [isLogin, setIslogin] = useState(false);
    
    const nextStep = () => {
        setShowMsg('')
        setShowTip(false)
        if (userInfo) {
            history.push('/selAddress')
            if(typeof onClose === 'function') onClose()
        } else {
            setIslogin(true);
        }
        
        
    }
    const toLogin = () => {
        setIslogin(false);
        if(typeof onClose === 'function') onClose()
		history.push('/login?redirect=/cart');
	}
    const CheckoutFun = () => {
        let hazard = 1
		cart.itemsPrice = cart.itemsList.reduce((acc, item) => {
			if(+item.hazard === 0 ) {
				hazard = 0
            }
			return acc + item.price[userInfo&&userInfo.name || 'A' ] * item.qty * (item.discount || 1)
		}, 0).toFixed(2)
		if (!cart || !cart.itemsList || cart.itemsList.length == 0) {
			return;
        }
        if(hazard===0 && cart.itemsPrice < 600 && (!userInfo || +userInfo.menkan300 === 0) ){
            setShowTip(1)
        }else  if(userInfo && +userInfo.menkan100 === 0 && cart.itemsPrice < 100 ) {
			setShowTip(2)
		}else {
            if(userInfo && cart.itemsPrice < +userInfo.last_name) {
                setShowMsg(`Cart total needs to be minimum £${userInfo.last_name} in order to proceed to check out.`)
            }else {
                nextStep()
            }

			
		}
		
    }
    const noFun = () => {
        history.push('/');
        setShowTip(false)
        if(typeof onClose === 'function') onClose()
    }
    const closeFun = () => {
        setShowMsg('')
    }
    return (
        <>
            {isLogin && <Confirm className={confirmClass}  okFun={toLogin}  tip="Please log into your account or choose guest checkout." />}
            {showTip&&<Confirm className={confirmClass} title="Confirm Extra Fee"  noFun={noFun} okFun={nextStep}   tip={''} cancelText="Continue shopping." confirmText="Yes,checkout" >
                <div style={{padding: "15px", flexGrow: 1}}>
                    <p style={{fontWeight: "bold", fontSize: "14px"}}>You will be charged £{showTip === 1 ? '50 hazard' : '10'}  shipping fee. you need to order £{((showTip === 1?600: 100) - cart.itemsPrice).toFixed(2)} more chemical products to get free hazard shipping fee. Would you still like to check out?</p>
                    <p style={{ fontSize: "12px", marginTop: "20px"}}><span style={{fontWeight: "bold"}}>Please note:</span> Any chemical products will be classed as hazard products. Any hazard products below £600 will be charged £50 hazard shipping fee. Hazard products over £600 will be free UK delivery. Normal products over £100 will be free UK delivery.</p>
                </div>
                <hr  />
            </Confirm>}
            {showMsg && <Confirm className={confirmClass} okFun={closeFun}   tip={showMsg} />}
            <div className={className} onClick={CheckoutFun} style={style}>{text}</div>
        </>
        
    )
}

export default Checkout
