import React from 'react';

import Slider from 'react-slick';
 import 'slick-carousel/slick/slick.css';
 import 'slick-carousel/slick/slick-theme.css';
 import './btn.css'
import {Link} from "react-router-dom";

const Slider_ = props => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    let { slides } = props;
    return (
        <div>
            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <div key={index} className="car">
                        <div className="content absolute">
                            <div className="title">{slide.title}</div>
                            <div className="cont">{slide.content}</div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

Slider_.defaultProps = {
    slides: [
        {
            title: 'Cleaner cars, better drives',
            content: '',
            // link: '/store/3',
        },
    ]
}
export default Slider_;
