import React, { useRef } from 'react';
import styles from '../../../css/DesignerScreenV2.module.css';
import { Button } from 'antd';
import { useRafState, useReactive } from 'ahooks';
import { fabric } from 'fabric';
import { insertImgFile } from '../tools';

export function getImgStr(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file) {
      //通过文件流将文件转换成Base64字符串
      reader.readAsDataURL(file);
      //转换成功后
      reader.onloadend = function () {
        //输出结果
        resolve(reader.result);
      };
    }
  });
}

export default function AddImagePanel(props) {
  const input = useRef(null);
  const state = useReactive({
    file: null,
  });

  return (
    <>
      <div className={styles.actionCard}>
        <div className={styles.actionCardTitle}>Add Image</div>
        <input
          type="file"
          ref={input}
          style={{ display: 'none' }}
          onChange={(e) => {
            if (!e.target.files || !e.target.files[0]) return;

            state.file = e.target.files[0];

            getImgStr(state.file).then((file) => {
              insertImgFile(file, props);
              input.current.value = '';
            });
          }}
        />
        <Button
          className={styles.fullwidth}
          type="primary"
          onClick={async () => {
            input.current.click();
          }}
        >
          UPLOAD FROM PHONE
        </Button>
      </div>
    </>
  );
}
