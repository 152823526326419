export const ORDER_REQUEST = 'ORDER_REQUEST'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
export const REQUEST_COMPLETE = 'REQUEST_COMPLETE'
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET'
export const ORDER_PAY = 'ORDER_PAY'
export const ORDER_SET = 'ORDER_SET'
export const  ORDER_DETAILS_SUCCESS = ' ORDER_DETAILS_SUCCESS'
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS'
export const ORDER_DELIVER_SUCCESS = 'ORDER_DELIVER_SUCCESS'
export const TOURIST_ORDER_PAY = 'TOURIST_ORDER_PAY'
export const CHECK_COUPON = 'CHECK_COUPON'
