import React, { useState, useEffect } from 'react'
import LoadSpinner from '../components/LoadSpinner'
import Message from '../components/Message'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
// 增加以下代码以使用"自动轮播"功能
import SwiperCore, { Navigation, Autoplay } from 'swiper';

import { useDispatch, useSelector } from 'react-redux'
import { listProductDetails,queryProductTotal } from '../actions/productActions'
import { Link } from 'react-router-dom'
import { listProducts } from '../actions/productActions'
import { addToCart } from '../actions/cartActions'
import Confirm from '../components/Confirm'
import SelectItem from '../components/SelectItem'
import '../css/ProductScreen.css'
import {Flex, Select} from "antd";
import {EyeOutlined, MinusOutlined, PlusOutlined, ShoppingCartOutlined} from "@ant-design/icons";
SwiperCore.use([ Navigation]);
function ProductScreen({ match, history }) {
    const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin
	  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    // image
    const [image, setImage] = useState('')
    const [productId, setProductId] = useState(match.params.id)
    const [qty, setQty] = useState(1);
    const [color, setColor] = useState('');
    const [combo, setCombo] = useState('');
    const cleanImageUrl = (url) => {
      const index = url.indexOf('?');
      if (index > -1) {
        return url.substring(0, index);
      }
      return url;
    };
    const [tip, setTip] = useState('');
    const dispatch = useDispatch()
    const productDetails = useSelector(state => state.productDetails)
    const { error, loading, product } = productDetails
    const productList = useSelector(state => state.productList)
    const { loading2, products } = productList
    const [slidesPerView,setSlidesPerView] = useState(4)
    const [nowItem, setNowItem] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(() => {

        dispatch(listProductDetails(productId))
        if(!products || products.length==0)dispatch(listProducts(''))
        resize()
        window.onresize = resize
    }, [dispatch, productId])
    useEffect(()=>{
        setProductId(match.params.id)
    },[match.params.id])
    const resize = () => {
        let w = window.innerWidth*1
        if(w<540){
            setSlidesPerView(1)
        }else if(w< 640){
            setSlidesPerView(2)
        }else if(w<935) {
            setSlidesPerView(3)
        }else {
            setSlidesPerView(4)
        }
    }
    const handleImg = (e) => {
      const cleanedUrl = cleanImageUrl(e.target.src);
      setImage(cleanedUrl);
    };

    const handleLink = (productId) => {
        return () => {
            setProductId(productId);
        }
    }
    const addCart = ()=>{
        if(product.ftotal <= 0) {
            return
        }
        if(!color) {
            //please choose type
            setTip('please choose type');
            return;
        }
        if(!combo) {
            setTip('please choose combo');
            return;
        }
        product.info.price = product.info.pricemember
        dispatch(addToCart(product.info, qty, color, combo));
    }
    const changeIptHandle = (key) => {
        return (value) => {
            if(key == 'color') {
                setColor(value);
                dispatch(queryProductTotal(value, combo, product))
            }else if(key == 'combo') {
                setCombo(value);
                dispatch(queryProductTotal(color, value, product))
            }
        }
    }
    const selectAddCart = (item, index) => {
        return () => {
            setNowItem(item);
            setShow(true);
        }
    }
    const noFun = () => {
        setNowItem({});
        setShow(false);
    }
    const goBack = () => {
        history.goBack()
    }

    return (
        <div className="product">
            {show ? <SelectItem item={nowItem} noFun={noFun} /> : ''}
            {tip&&<Confirm okFun={()=>setTip('')}   tip={tip} confirmText="OK" />}
            {loading || loading2 ? <div className="fullcreen"><LoadSpinner /></div>
                : error ? <Message variant='danger'>{error}</Message>
                    :
                    <div>
                        <div className="maxWidth">
                            <div className="mob-title">
                                <div className="product-name">{product && product.info && product.info.name}</div>
                                <div className="flex-center">
                                    <a style={{cursor: 'pointer'}} onClick={goBack}>
                                        <div className="new-btn">GO BACK</div>
                                    </a>

                                </div>
                            </div>

                            <div className="top-wrap" >
                                <div className="left">
                                    <div className="scroll-content">
                                        <div className="scroll-content-wrap">
                                            <div className="left-swiper-container">
                                                <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                {
                                                        product&&product.imgs && product.imgs.map(item => {
                                                            return  <div className="small-img"><img onClick={handleImg}  src={cleanImageUrl(item)} /></div>

                                                        })

                                                    }
                                                     </div>

                                                </div>
                                                <div className="swiper-scrollbar"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pro-img-box">
                                        <div className="product-img">
                                            <img className="main-img"
                                                 src={image ? image.split('?')[0] : (product && product.info && product.info.image ? product.info.image.split('?')[0] : '')}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="left-mob">
                                    <div className="product-img">
                                        <img className="main-img"
                                             src={image ? image.split('?')[0] : (product && product.info && product.info.image ? product.info.image.split('?')[0] : '')}/>
                                    </div>
                                    <div className="scroll-imgs">
                                        {
                                        product&&product.imgs && product.imgs.map(item => {
                                                return  <div className="small-img"><img onClick={handleImg} src={cleanImageUrl(item)} /></div>

                                            })

                                        }
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="product-name">{product && product.info && product.info.name}</div>
                                    {/*<div className="flex-center">*/}
                                    {/*    <a style={{cursor: 'pointer'}} onClick={goBack}>*/}
                                    {/*        <div className="new-btn">GO BACK</div>*/}
                                    {/*    </a>*/}

                                    {/*</div>*/}
                                    <div className="black">
                                        <div className="goods-desc">
                                            {/*<div className="sku-here" >Details:</div>*/}
                                            <div>
                                               {product&&product.info&&product.info.info}
							                </div>
                                        </div>
                                        <Flex className="goods-attrs" gap="middle" vertical>
                                          <Flex gap="middle" align="center">
                                            <div className="goods-attr-label">Color</div>
                                            <Select
                                              placeholder="Choose Color"
                                              value={color}
                                              style={{ width: "100%", height: "36px" }}
                                              onChange={changeIptHandle('color')}
                                              options={product&&product.colors&&product.colors.map((item,index) => ({ value: item, label: item }))}
                                            />
                                          </Flex>
                                          <Flex gap="middle" align="center">
                                            <div className="goods-attr-label">Combo</div>
                                            <Select
                                              placeholder="Choose Combo"
                                              value={combo}
                                              style={{ width: "100%", height: "36px" }}
                                              onChange={changeIptHandle('combo')}
                                              options={product&&product.combos&&product.combos.map((item,index) => ({ value: item, label: item }))}
                                            />
                                          </Flex>
                                        </Flex>
                                        <Flex align="center" justify="space-between">
                                          <Flex align="center" justify="space-between">
                                            <div className="quantity-input flex-center">
                                              <div className="quantity-btn quantity-btn-left" onClick={()=>setQty(qty-1>0?(qty-1) : 1)}><MinusOutlined /></div>
                                              <input type="text" onChange={(e)=>setQty(e.target.value ? e.target.value*1 : "")} value={qty} />
                                              <div className="quantity-btn quantity-btn-right" onClick={()=>setQty((qty+1)>product.ftotal ? product.ftotal : (qty+1))}><PlusOutlined /></div>
                                            </div>
                                            {
                                              product.ftotal > 0 ? <div className="stock-pc inStock">
                                                <img src="./images/index/gou.png" />
                                                <div>In stock</div>
                                              </div>:<div className="stock-mb notInStock">
                                                <img src="./images/index/cha.png" />
                                                <div>Not in stock</div>
                                              </div>
                                            }
                                          </Flex>
                                          {
                                            product&&product.info?
                                              <div className="flex-center goods-price">
                                                {
                                                  product.info.discount==1?'':<div className="del-price">£{(product.info.pricemember[userInfo&&userInfo.name || 'A' ] * qty).toFixed(2)}</div>
                                                }

                                                <div className="now-price">£{(product.info.pricemember[userInfo&&userInfo.name || 'A' ] * product.info.discount * qty).toFixed(2)}</div>
                                              </div>
                                              :''
                                          }
                                        </Flex>
                                        <div className={product.ftotal > 0?"add-basket-btn  ": "add-basket-btn disabled"} onClick={addCart}>
                                            Add to Basket
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="relative" style={{marginBottom: 100}}>
                                <div className="also-like">You May Also Like...</div>

                                    <Swiper

                                        direction={'horizontal'}

                                        loop={true}
                                        slidesPerView={slidesPerView}
                                        spaceBetween="-3%"

                                        navigation={{
                                            nextEl: '.swiper-button-next',
                                            prevEl: '.swiper-button-prev',
                                            disabledClass: 'disable'
                                        }}


                                    >

                                        {
                                            products&&products.map((item,index) => {
                                                return  <SwiperSlide><div  style={{marginLeft: '15%'}}>

                                                            <Link onClick={handleLink(item.id)} to={'/product/'+item.id} className="swiperImg" >

                                                                <div className="swiperImg">
                                                                    <img src={item.image.split('?')[0]}/>
                                                                </div>
                                                            </Link>
                                                            <div className="swiper-name" title={item.name}>{item.name}</div>
                                                            <Flex align="center" justify="space-between">
                                                              <div className="swiper-price">
                                                                {item.discount !== 1 ? <div><del>£{(item.pricemember[userInfo&&userInfo.name || 'A' ]).toFixed(2)}</del><span style={{color: '#FFC008'}}>£{(item.discount * item.pricemember[userInfo&&userInfo.name || 'A' ]).toFixed(2)}</span></div> : <span style={{color: '#FFC008'}}>£{(item.pricemember[userInfo&&userInfo.name || 'A' ]).toFixed(2)}</span>}
                                                              </div>
                                                              <div className="swiper-btn-wrap flex-center">
                                                                <Link onClick={handleLink(item.id)} to={'/product/'+item.id} className="btnLeft flex"><EyeOutlined /></Link>
                                                                <div className="flex btnRight"
                                                                     onClick={selectAddCart(item)}
                                                                >
                                                                  <ShoppingCartOutlined />
                                                                  {/*<img src="./images/index/box2.png" className="btnImg" />*/}
                                                                </div>
                                                              </div>
                                                            </Flex>
                                                        </div></SwiperSlide>
                                            })
                                        }



                                    </Swiper>
                                    <div className="swiper-button-prev swiper-button">
                                      <img src="./images/index/left.png" />
                                    </div>
                                    <div className="swiper-button-next swiper-button">
                                      <img src="./images/index/right.png" />
                                    </div>
                                </div>
                       </div>
                    </div>
            }
        </div >
    )
}

export default ProductScreen
