import { Button, Flex } from 'antd';
import React from 'react';
import styles from '../../../css/DesignerScreenV2.module.css';
import { CopyFilled, DeleteFilled } from '@ant-design/icons';
import { TbFlipVertical } from 'react-icons/tb';
import { BiSolidLayerMinus, BiSolidLayerPlus } from 'react-icons/bi';
import { MdOutlineAlignHorizontalCenter, MdOutlineAlignVerticalCenter } from 'react-icons/md';
import { HiOutlineMagnifyingGlassMinus, HiOutlineMagnifyingGlassPlus } from 'react-icons/hi2';
import {
  deleteItem,
  down,
  duplicateImage,
  flipImage,
  horizontalCenter,
  up,
  verticalCenter,
  zoomIn,
  zoomOut,
} from '../tools';

export default function ToolsPanel(props) {
  return (
    <>
      <Flex
        key={'dimension-viewer'}
        className={styles.toolWrap}
        style={{
          left: props.left,
          top: props.top,
        }}
      >
        <Button
          type="primary"
          icon={<CopyFilled />}
          onClick={() => {
            duplicateImage(props);
          }}
        />
        <Button
          type="primary"
          icon={<TbFlipVertical fontSize={20} />}
          onClick={() => {
            flipImage(props);
          }}
        />
        <Button
          type="primary"
          icon={
            <BiSolidLayerPlus
              fontSize={20}
              onClick={() => {
                up(props);
              }}
            />
          }
        />
        <Button
          type="primary"
          icon={
            <BiSolidLayerMinus
              fontSize={20}
              onClick={() => {
                down(props);
              }}
            />
          }
        />
        <Button
          type="primary"
          icon={
            <MdOutlineAlignHorizontalCenter
              fontSize={20}
              onClick={() => {
                horizontalCenter(props);
              }}
            />
          }
        />
        <Button
          type="primary"
          icon={
            <MdOutlineAlignVerticalCenter
              fontSize={20}
              onClick={() => {
                verticalCenter(props);
              }}
            />
          }
        />
        <Button
          type="primary"
          icon={
            <HiOutlineMagnifyingGlassPlus
              fontSize={20}
              onClick={() => {
                zoomIn(props);
              }}
            />
          }
        />
        <Button
          type="primary"
          icon={
            <HiOutlineMagnifyingGlassMinus
              fontSize={20}
              onClick={() => {
                zoomOut(props);
              }}
            />
          }
        />
        <Button
          type="primary"
          icon={<DeleteFilled />}
          onClick={() => {
            deleteItem(props);
          }}
        />
      </Flex>
    </>
  );
}
