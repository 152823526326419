import React, { useState, useEffect, useRef, createRef } from "react";
import "../css/DesignerScreen.css";
import {
  Button,
  Card,
  Flex,
  Input,
  Tabs,
  Typography,
  Checkbox,
  Modal,
} from "antd";
import styles from "../css/DesignerScreenV2.module.css";
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined, ArrowRightOutlined,
  BoldOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyFilled,
  CopyOutlined,
  DeleteFilled,
  DeleteOutlined,
  ExclamationCircleFilled,
  EyeFilled,
  ItalicOutlined,
  LeftCircleOutlined, PoweroffOutlined,
  RightCircleOutlined,
  SearchOutlined,
  UnderlineOutlined,
} from "@ant-design/icons";
import Moveable from "react-moveable";
import { TbFlipVertical } from "react-icons/tb";
import { BiSolidLayerPlus } from "react-icons/bi";
import { BiSolidLayerMinus } from "react-icons/bi";
import {
  MdOutlineAlignVerticalCenter,
  MdOutlineAlignHorizontalCenter,
} from "react-icons/md";
import {
  HiOutlineMagnifyingGlassMinus,
  HiOutlineMagnifyingGlassPlus,
} from "react-icons/hi2";
import { useMediaQuery } from "react-responsive";
import Warning from "../img/warning.png";
import { addDesignToCart } from '../actions/designActions';
import { addToCart } from '../actions/cartActions';
import { useDispatch, useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import ColorSelector from '../components/ColorSelector';
import imageCompression from 'browser-image-compression';

const { Title } = Typography;
const { confirm } = Modal;

const fonts = [
  "Aleo",
  "Amatic_SC",
  "Corinthia",
  "Fira_Sans",
  "Krona_One",
  "Kumar_One_Outline",
  "Lobster_Two",
  "Molle",
  "Monoton",
  "Nixie_One",
  "Permanent_Marker",
  "Sancreek",
  "Stint_Ultra_Expanded",
  "VT323",
];

function useSingleAndDoubleClick(
  actionSimpleClick,
  actionDoubleClick,
  delay = 250,
) {
  const [click, setClick] = useState(0);
  const [id, setId] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      // simple click
      if (click === 1) actionSimpleClick(id);
      setClick(0);
    }, delay);

    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    if (click === 2) actionDoubleClick(id);

    return () => clearTimeout(timer);
  }, [click, id]);

  return (id) =>
    setClick((prev) => {
      setId(id);
      return prev + 1;
    });
}

function convertTransformToText(transform) {
  let cssText = "";
  if (transform) {
    if (transform.translate) {
      cssText += ` translate(${transform.translate[0]}px, ${transform.translate[1]}px) `;
    }
    if (transform.scale) {
      cssText += ` scale(${transform.scale[0]}, ${transform.scale[1]}) `;
    }
    if (transform.rotate) {
      cssText += ` rotate(${(transform.rotate * 180) / Math.PI}deg) `;
    }

    if (transform.rotateY) {
      cssText += ` rotateY(180deg) `;
    }
  }
  return cssText;
}

function convertTransformToText1(transform, shapeJson) {
  let cssText = "";
  if (transform) {
    if (transform.translate) {
      cssText += ` translate(${transform.translate[0] - shapeJson.x - 20}px, ${transform.translate[1] - shapeJson.y - 20}px) `;
    }
    if (transform.scale) {
      cssText += ` scale(${transform.scale[0]}, ${transform.scale[1]}) `;
    }
    if (transform.rotate) {
      cssText += ` rotate(${(transform.rotate * 180) / Math.PI}deg) `;
    }

    if (transform.rotateY) {
      cssText += ` rotateY(180deg) `;
    }
  }
  return cssText;
}

function DesignerScreenV2({ history }) {
  const canvasRef = useRef();
  const canvasFrontRef = useRef();
  const canvasBackRef = useRef();
  const uploadImgRef = useRef();
  const [activeAction, setActiveAction] = useState(1);
  const [aspect, setAspect] = useState(0);
  const [inputText, setInputText] = useState("");
  const [showTextPanel, setShowTextPanel] = useState(false);
  const [activeFont, setActiveFont] = useState(false);
  const [activeFontIndex, setActiveFontIndex] = useState(0);
  const [showWarning, setShowWarning] = useState(true);
  const [untitledFocus, setUntitledFocus] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [settings, setSettings] = useState([
    { activeColor: "", texts: [], images: [] },
    { activeColor: "", texts: [], images: [] },
  ]);
  const [pastSettings, setPastSettings] = useState([]);
  const [futureSettings, setFutureSettings] = useState([]);
  const [shapeJson, setShapeJson] = useState({x: 0, y: 0, width: 0, height: 0, path: []});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBasketModalOpen, setIsBasketModalOpen] = useState(false);

  const [editId, setEditId] = useState(-1);
  const [name, setName] = useState("Untitled");
  const designInfo = useSelector(state => state.designInfo)
  const dispatch = useDispatch()
  const tempShapeJson = designInfo.fresheners.jsonshape


  // listen single/double click by custom hook
  const objClick = useSingleAndDoubleClick(
    (id) => {
      const matchedText = settings[aspect].texts.find((item) => item.id === id);
      if (matchedText) {
        if (matchedText.active) {
          setEditId(matchedText.id);
        } else {
          settings[aspect].texts.forEach((item) => (item.active = false));
          settings[aspect].images.forEach((item) => (item.active = false));
          matchedText.active = true;
          setSettings([...settings]);
        }
      } else {
        const matchedTImage = settings[aspect].images.find(
          (item) => item.id === id,
        );
        if (matchedTImage) {
          settings[aspect].texts.forEach((item) => (item.active = false));
          settings[aspect].images.forEach((item) => (item.active = false));
          matchedTImage.active = true;
          setSettings([...settings]);
        }
      }
    },
    (id) => {
      setEditId(id);
    },
  );
  const textTargetRef = useRef([]);
  const textMoveableTargetRef = useRef([]);
  const imageTargetRef = useRef([]);
  const imageMoveableTargetRef = useRef([]);
  textTargetRef.current = settings[aspect].texts.map(
    (element, i) => textTargetRef.current[i] ?? createRef(),
  );
  textMoveableTargetRef.current = settings[aspect].texts.map(
    (element, i) => textMoveableTargetRef.current[i] ?? createRef(),
  );
  imageTargetRef.current = settings[aspect].images.map(
    (element, i) => imageTargetRef.current[i] ?? createRef(),
  );
  imageMoveableTargetRef.current = settings[aspect].images.map(
    (element, i) => imageMoveableTargetRef.current[i] ?? createRef(),
  );

  const editInputRef = useRef()
  const rightBottomRef = useRef()
  const frontRef = useRef()
  const backRef = useRef()

  const isMobile = useMediaQuery({
    query: "(max-width: 800px)",
  });

  useEffect(() => {
    if (editId && editInputRef.current) {
      editInputRef.current.focus();
    }
  }, [editId, editInputRef.current])

  useEffect(() => {
    const designerDevice = localStorage.getItem("designer-device")
    if (designerDevice) {
      setShapeJson({...tempShapeJson[designerDevice], path: tempShapeJson.path, viewBox: tempShapeJson.viewBox})
    } else {
      const device = isMobile ? "mobile" : "desktop"
      localStorage.setItem("designer-device", device)
      setShapeJson({...tempShapeJson[device], path: tempShapeJson.path, viewBox: tempShapeJson.viewBox})
    }
  }, [])

  // useEffect(() => {
  //   if (canvasRef && canvasRef.current && rightBottomRef && rightBottomRef.current) {
  //     const ratio = window.devicePixelRatio || 1;
  //     const w = rightBottomRef.current.offsetWidth
  //     const h = rightBottomRef.current.offsetHeight
  //     canvasRef.current.width = w * ratio;
  //     canvasRef.current.height = h * ratio;
  //     canvasRef.current.style.width = `${w}px`;
  //     canvasRef.current.style.height = `${h}px`;
  //
  //     const ctx = canvasRef.current.getContext("2d");
  //     ctx.scale(ratio, ratio)
  //
  //     const strokeColors = ["#509f57", "#333333", "#d94d3d"]
  //
  //     ctx.fillStyle = settings[aspect].activeColor || "#FFFFFF"; // 白色填充
  //     ctx.setLineDash([5, 10]);
  //     const tShapeJson = JSON.parse(JSON.stringify(shapeJson))
  //     tShapeJson.path.reverse();
  //     strokeColors.reverse();
  //     tShapeJson.path.forEach((points, index) => {
  //       ctx.strokeStyle = strokeColors[index];
  //       ctx.beginPath();
  //       ctx.moveTo(points[0].startX, points[0].startY);
  //       for (let i = 0 ; i < points.length; i++) {
  //         const point = points[i];
  //         if (point.type === "line") {
  //           ctx.lineTo(point.endX, point.endY);
  //         } else if (point.type === 'arc') {
  //           ctx.arcTo(point.startX, point.startY, point.endX, point.endY, point.radius);
  //         }
  //         ctx.stroke();
  //       }
  //       ctx.closePath();
  //       if (index === 0) {
  //         ctx.fill();
  //       }
  //     })
  //   }
  // }, [settings, aspect, showTextPanel, rightBottomRef, canvasRef, shapeJson]);

  useEffect(() => {
    if (canvasFrontRef && canvasFrontRef.current && rightBottomRef && rightBottomRef.current) {
      const ratio = window.devicePixelRatio || 1;
      const ctx = canvasFrontRef.current.getContext("2d");
      ctx.clearRect(0, 0, canvasFrontRef.current.width, canvasFrontRef.current.height);
      ctx.scale(ratio, ratio)

      ctx.fillStyle = settings[0].activeColor || "#FFFFFF"; // 白色填充
      ctx.fillRect(0,0,shapeJson.width - 40,shapeJson.height - 40);
    }
  }, [settings, aspect, showTextPanel, rightBottomRef, canvasFrontRef, shapeJson, isModalOpen, isBasketModalOpen]);

  useEffect(() => {
    if (canvasBackRef && canvasBackRef.current && rightBottomRef && rightBottomRef.current) {
      const ratio = window.devicePixelRatio || 1;

      const ctx = canvasBackRef.current.getContext("2d");
      ctx.clearRect(0, 0, canvasFrontRef.current.width, canvasFrontRef.current.height);
      ctx.scale(ratio, ratio)

      ctx.fillStyle = settings[1].activeColor || "#FFFFFF"; // 白色填充
      ctx.fillRect(0, 0, shapeJson.width - 40, shapeJson.height - 40);
    }
  }, [settings, aspect, showTextPanel, rightBottomRef, canvasBackRef, shapeJson, isModalOpen, isBasketModalOpen]);

  const undo = () => {
    if (pastSettings.length === 0) return;

    const newPast = [...pastSettings];
    const newPresent = newPast.pop();

    setPastSettings(newPast);
    setFutureSettings([settings, ...futureSettings]);
    setSettings(newPresent);
  };

  const redo = () => {
    if (futureSettings.length === 0) return;

    const newFuture = [...futureSettings];
    const newPresent = newFuture.shift();

    setPastSettings([...pastSettings, settings]);
    setFutureSettings(newFuture);
    setSettings(newPresent);
  };

  const updatePresent = (newState) => {
    setPastSettings([...pastSettings, settings]);
    setSettings(newState);
    setFutureSettings([]);
  };

  const addText = () => {
    cancelAllActive();
    const newSettings = JSON.parse(JSON.stringify(settings));
    newSettings[aspect].texts.push({
      id: Date.now() + Math.random() * 1000,
      text: inputText,
      color: "#000000",
      backgroundColor: "transparent",
      fontSize: 20,
      underline: false,
      bold: false,
      italic: false,
      transform: {
        translate: [
          shapeJson.x + shapeJson.width / 2,
          shapeJson.y + shapeJson.height / 2,
          0,
        ],
      },
      active: true,
    });
    setInputText("");
    setActiveFontIndex(newSettings[aspect].texts.length - 1);
    setShowTextPanel(true);
    updatePresent(newSettings);
  };

  const handleImageUpload = (e) => {
    if (!e.target.files || !e.target.files[0]) return;

    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    }
    const imageFile = e.target.files[0];
    imageCompression(imageFile, options).then(result => {
      const reader = new FileReader();
      reader.readAsDataURL(result);
      reader.onloadend = function() {
        const base64data = reader.result;
        cancelAllActive();
        const newSettings = JSON.parse(JSON.stringify(settings));
        newSettings[aspect].images.push({
          id: Date.now() + Math.random() * 1000,
          image: base64data,
          transform: {
            translate: [
              shapeJson.x + shapeJson.width / 2,
              shapeJson.y + shapeJson.height / 2,
              0,
            ],
          },
          active: true,
        });
        updatePresent(newSettings);
      }
    })




  };

  const handleFontSelect = (font) => {
    const newSettings = JSON.parse(JSON.stringify(settings));
    newSettings[aspect].texts[activeFontIndex].font = font;
    setActiveFont(font);
    updatePresent(newSettings);
  };

  const cancelAllActive = () => {
    settings[aspect].texts.forEach((item) => (item.active = false));
    settings[aspect].images.forEach((item) => (item.active = false));
  };

  const handleCopyToAnother = () => {
    if (
      (aspect === 0 &&
        (settings[1].activeColor !== "" ||
          settings[1].texts.length > 0 ||
          settings[1].images.length > 0)) ||
      (aspect === 1 &&
        (settings[0].activeColor !== "" ||
          settings[0].texts.length > 0 ||
          settings[0].images.length > 0))
    ) {
      confirm({
        title: "Are You Sure?",
        icon: <ExclamationCircleFilled />,
        content:
          "Any artwork on the other side of this design will be lost. This action can not be undone.",
        okText: "Duplicate",
        onOk() {
          const anotherAspect = aspect === 1 ? 0 : 1;
          settings[anotherAspect] = JSON.parse(
            JSON.stringify(settings[aspect]),
          );
          setAspect(anotherAspect);
          setSettings([...settings]);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } else {
      const anotherAspect = aspect === 1 ? 0 : 1;
      settings[anotherAspect] = JSON.parse(JSON.stringify(settings[aspect]));
      setAspect(anotherAspect);
      setSettings([...settings]);
    }
  };

  const handleCheckout = async () => {
    setConfirmLoading(true);
    const frontCanvas = await html2canvas(frontRef.current);
    const backCanvas = await html2canvas(backRef.current);
    //上传数据的函数后
    dispatch(addDesignToCart(  {
      name: name,
      imgFront: frontCanvas.toDataURL(),
      imgBack: backCanvas.toDataURL(),
      imgdata: "",
      state1: JSON.stringify({ settings, pastSettings, futureSettings }),
      state2: localStorage.getItem("designer-device"),
      jsondata: JSON.stringify(tempShapeJson),
      price: designInfo.price,
      total: designInfo.total,
      quality: 1,
      pk: designInfo.fresheners.id,
    },(res)=>{

      dispatch(addToCart({
        ...designInfo.fresheners,
        name: name,
        id: res.productId,
        price: designInfo.price,
        scentTotal: designInfo.total,
        image: res.image
      },  1, designInfo.fresheners.color, designInfo.fresheners.combo, false));
      setConfirmLoading(false);
      history.push('/cart')
    }))
  }
  console.log(settings[aspect].activeColor)
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.leftActions}>
          <Button
            type={activeAction === 1 ? "primary" : ""}
            onClick={() => setActiveAction(1)}
          >
            Add Text
          </Button>
          <Button
            type={activeAction === 2 ? "primary" : ""}
            onClick={() => setActiveAction(2)}
          >
            Add Image
          </Button>
          <Button
            type={activeAction === 3 ? "primary" : ""}
            onClick={() => setActiveAction(3)}
          >
            BG Color
          </Button>
          <Button
            type={activeAction === 4 ? "primary" : ""}
            onClick={() => setActiveAction(4)}
          >
            Sticker
          </Button>
          <Button
            type={activeAction === 5 ? "primary" : ""}
            onClick={() => setActiveAction(5)}
          >
            Copy
          </Button>
        </div>
        <div className={styles.fullwidth}>
          {activeAction === 1 && (
            <div className={styles.actionCard}>
              <div className={styles.actionCardTitle}>Add Text</div>
              <Input
                style={{ marginBottom: 15 }}
                placeholder="Enter text here"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
              />
              <Button
                className={styles.fullwidth}
                type="primary"
                onClick={() => addText()}
              >
                Add
              </Button>
            </div>
          )}
          {settings[aspect].texts.some((item) => item.active) && (
            <div className={styles.actionCard}>
              <div className={styles.textPanelContent}>
                {fonts.map((font) => (
                  <div
                    key={font}
                    className={[
                      styles.textPanelFont,
                      activeFont === font ? styles.activeTextPanelFont : null,
                    ].join(" ")}
                    style={{
                      fontFamily: font,
                      fontSize: [
                        "Kumar_One_Outline",
                        "Stint_Ultra_Expanded",
                        "Monoton",
                        "Permanent_Marker",
                      ].includes(font)
                        ? "0.15rem"
                        : "",
                    }}
                    onClick={() => handleFontSelect(font)}
                  >
                    {font.split("_").join(" ")}
                  </div>
                ))}
              </div>
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    key: "1",
                    label: "Font",
                    children: settings[aspect].texts.length > 0 && (
                      <div className={styles.fontBottom}>
                        <Input
                          className={styles.fontInput}
                          value={
                            settings[aspect].texts[activeFontIndex].fontSize
                          }
                          onChange={(e) => {
                            const newSettings = JSON.parse(
                              JSON.stringify(settings),
                            );
                            newSettings[aspect].texts[
                              activeFontIndex
                            ].fontSize = Number(e.target.value);
                            updatePresent(newSettings);
                          }}
                        />
                        <Button
                          style={{ borderRadius: "6px 0 0 6px" }}
                          icon={<UnderlineOutlined />}
                          onClick={(e) => {
                            const newSettings = JSON.parse(
                              JSON.stringify(settings),
                            );
                            newSettings[aspect].texts[
                              activeFontIndex
                            ].underline = !settings[aspect].texts[0].underline;
                            updatePresent(newSettings);
                          }}
                        />
                        <Button
                          style={{ borderRadius: 0 }}
                          icon={<BoldOutlined />}
                          onClick={(e) => {
                            const newSettings = JSON.parse(
                              JSON.stringify(settings),
                            );
                            newSettings[aspect].texts[activeFontIndex].bold =
                              !settings[aspect].texts[0].bold;
                            updatePresent(newSettings);
                          }}
                        />
                        <Button
                          style={{
                            borderRadius: "0 6px 6px 0",
                            marginRight: 10,
                          }}
                          icon={<ItalicOutlined />}
                          onClick={(e) => {
                            const newSettings = JSON.parse(
                              JSON.stringify(settings),
                            );
                            newSettings[aspect].texts[activeFontIndex].italic =
                              !settings[aspect].texts[0].italic;
                            updatePresent(newSettings);
                          }}
                        />
                        <Button
                          style={{ borderRadius: "6px 0 0 6px" }}
                          icon={<AlignLeftOutlined />}
                        />
                        <Button
                          style={{ borderRadius: 0 }}
                          icon={<AlignCenterOutlined />}
                        />
                        <Button
                          style={{ borderRadius: "0 6px 6px 0" }}
                          icon={<AlignRightOutlined />}
                        />
                      </div>
                    ),
                  },
                  {
                    key: "2",
                    label: "Color",
                    children: (
                      <ColorSelector
                        activeColor={settings[aspect].texts[activeFontIndex].color}
                        handleClick={(color) => {
                            const newSettings = JSON.parse(
                              JSON.stringify(settings),
                            );
                            newSettings[aspect].texts[activeFontIndex].color = color;
                            updatePresent(newSettings);
                        }}
                      />
                    ),
                  },
                  {
                    key: "3",
                    label: "Background",
                    children: (
                      <ColorSelector
                        activeColor={settings[aspect].texts[activeFontIndex].backgroundColor}
                        handleClick={(color) => {
                          const newSettings = JSON.parse(
                            JSON.stringify(settings),
                          );
                          newSettings[aspect].texts[activeFontIndex].backgroundColor = color;
                          updatePresent(newSettings);
                        }}
                      />
                    ),
                  },
                ]}
                onChange={() => {}}
              />
            </div>
          )}
          {activeAction === 2 && (
            <div className={styles.actionCard}>
              <div className={styles.actionCardTitle}>Add Image</div>
              <input
                ref={uploadImgRef}
                type="file"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
              <Button
                className={styles.fullwidth}
                type="primary"
                onClick={() => {
                  uploadImgRef.current && uploadImgRef.current.click();
                }}
              >
                UPLOAD FROM PHONE
              </Button>
            </div>
          )}
          {activeAction === 3 && (
            <div className={styles.actionCard}>
              <div className={styles.actionCardTitle}>Background Color</div>
              <ColorSelector
                activeColor={settings[aspect].activeColor}
                handleClick={(color) => {
                  settings[aspect].activeColor = color;
                  setSettings([...settings]);
                }}
              />
            </div>
          )}
          {activeAction === 4 && (
            <div className={styles.actionCard}>
              <div className={styles.actionCardTitle}>Stickers</div>
            </div>
          )}
          {activeAction === 5 && (
            <div className={styles.actionCard}>
              <div className={styles.actionCardTitle}>Copy</div>
              <div>
                <div className={styles.actionCardSubTitle}>
                  Duplicate Design to Other Side:
                </div>
                <div className={styles.formatActions}>
                  <Button onClick={handleCopyToAnother}>Duplicate Design to Other Side</Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.rightTop}>
          <div
            className={[styles.rightTopBtns, styles.rightTopFirst].join(" ")}
          >
            <div className={styles.rightTopBtns}>
              <Input placeholder="Untitled" className={styles.title} onFocus={() => setUntitledFocus(true)} onBlur={() => setUntitledFocus(false)} value={name} onChange={e => setName(e.target.value)} />
              <Button style={{visibility: untitledFocus ? 'visible' : 'hidden'}} type="primary" icon={<CheckOutlined />}></Button>
            </div>
            <div className={styles.rightTopBtns}>
              <Button type="primary" onClick={() => setIsBasketModalOpen(true)}>ADD TO BASKET</Button>
              <Modal
                width="auto"
                title="Add To Cart"
                zIndex={5000}
                open={isBasketModalOpen}
                confirmLoading={confirmLoading}
                footer={[
                  <Button key="return" onClick={() => setIsBasketModalOpen(false)}>
                    Design More
                  </Button>,
                  confirmLoading ? (
                    <Button
                      type="primary"
                      icon={<PoweroffOutlined />}
                      loading={true}
                    >
                      Checkout
                    </Button>
                  ): (
                    <Button type="primary" key="continue" onClick={() => handleCheckout()}>
                      Checkout <ArrowRightOutlined />
                    </Button>
                  ),
                ]}
                onCancel={() => setIsBasketModalOpen(false)}
              >
                <div className={styles.previewContainer}>
                  <div className={styles.previewImg}>
                    <div
                      ref={frontRef}
                      style={{
                        position: 'relative',
                        width: shapeJson.width - 40,
                        height: shapeJson.height - 40,
                        overflow: 'hidden',
                        border: '1px solid #333',
                        borderRadius: 10
                      }}
                    >
                      <div className={styles.previewImgInner}>
                        <canvas ref={canvasFrontRef} width={shapeJson.width - 40} height={shapeJson.height - 40}></canvas>
                        <div style={{
                          left: (shapeJson.width - 40) / 2 - 5,
                          top: shapeJson.y,
                        }} className={styles.pin}></div>
                        {showWarning && (
                          <img
                            className={styles.warning}
                            src={Warning}
                            style={{
                              left: (shapeJson.width - 40) / 2 - 96,
                              top: shapeJson.height - 70,
                            }}
                          />
                        )}
                        {
                          settings[0].texts &&
                          settings[0].texts.map((item, i) => (
                            <div
                              key={item.id}
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                padding: 10,
                                fontFamily: item.font,
                                color: item.color,
                                backgroundColor: item.backgroundColor,
                                fontSize: item.fontSize,
                                textDecoration: item.underline ? "underline" : "",
                                fontWeight: item.bold ? "bold" : "",
                                fontStyle: item.italic ? "italic" : "",
                                userSelect: "none",
                                transform: convertTransformToText1(item.transform, shapeJson),
                                zIndex: item.zIndex,
                              }}
                            >
                              {item.text}
                            </div>
                          ))
                        }
                        {
                          settings[0].images &&
                          settings[0].images.map((item, i) => (
                            <img
                              key={item.id}
                              src={item.image}
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                padding: 10,
                                width: 150,
                                transform: convertTransformToText1(item.transform, shapeJson),
                                zIndex: item.zIndex,
                              }}
                            />
                          ))
                        }
                      </div>
                    </div>
                    <div>Front</div>
                  </div>
                  <div className={styles.previewImg}>
                    <div
                      ref={backRef}
                      style={{
                        position: 'relative',
                        width: shapeJson.width - 40,
                        height: shapeJson.height - 40,
                        overflow: 'hidden',
                        border: '1px solid #333',
                        borderRadius: 10
                      }}
                    >
                      <div className={styles.previewImgInner}>
                        <canvas ref={canvasBackRef} width={shapeJson.width - 40} height={shapeJson.height - 40}></canvas>
                        <div style={{
                          left: (shapeJson.width - 40) / 2 - 5,
                          top: shapeJson.y,
                        }} className={styles.pin}></div>
                        {showWarning && (
                          <img
                            className={styles.warning}
                            src={Warning}
                            style={{
                              left: (shapeJson.width - 40) / 2 - 96,
                              top: shapeJson.height - 70,
                            }}
                          />
                        )}
                        {
                          settings[1].texts &&
                          settings[1].texts.map((item, i) => (
                            <div
                              key={item.id}
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                padding: 10,
                                fontFamily: item.font,
                                color: item.color,
                                backgroundColor: item.backgroundColor,
                                fontSize: item.fontSize,
                                textDecoration: item.underline ? "underline" : "",
                                fontWeight: item.bold ? "bold" : "",
                                fontStyle: item.italic ? "italic" : "",
                                userSelect: "none",
                                transform: convertTransformToText1(item.transform, shapeJson),
                                zIndex: item.zIndex,
                              }}
                            >
                              {item.text}
                            </div>
                          ))
                        }
                        {
                          settings[1].images &&
                          settings[1].images.map((item, i) => (
                            <img
                              key={item.id}
                              src={item.image}
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                padding: 10,
                                width: 150,
                                transform: convertTransformToText1(item.transform, shapeJson),
                                zIndex: item.zIndex,
                              }}
                            />
                          ))
                        }
                      </div>
                    </div>
                    <div>Back</div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
          <div className={styles.rightTopSecond}>
            <div className={styles.rightTopBtns}>
              <Button
                onClick={() => {
                  undo();
                  setTimeout(() => {
                    textMoveableTargetRef.current.forEach((item) => {
                      item.current.updateRect();
                    });
                    imageMoveableTargetRef.current.forEach((item) => {
                      item.current.updateRect();
                    });
                  });
                }}
                disabled={pastSettings.length === 0}
                icon={<LeftCircleOutlined />}
              >
                {isMobile ? "" : "Undo"}
              </Button>
              <Button
                onClick={() => {
                  redo();
                  setTimeout(() => {
                    textMoveableTargetRef.current.forEach((item) => {
                      item.current.updateRect();
                    });
                    imageMoveableTargetRef.current.forEach((item) => {
                      item.current.updateRect();
                    });
                  });
                }}
                disabled={futureSettings.length === 0}
                icon={<RightCircleOutlined />}
              >
                {isMobile ? "" : "Redo"}
              </Button>
            </div>
            <div>
              <Button
                className={aspect === 0 ? styles.aspectActive : ""}
                style={{ borderRadius: "6px 0 0 6px", overflow: "hidden" }}
                onClick={() => setAspect(0)}
              >
                Front
              </Button>
              <Button
                className={aspect === 1 ? styles.aspectActive : ""}
                style={{ borderRadius: "0 6px 6px 0", overflow: "hidden" }}
                onClick={() => setAspect(1)}
              >
                Back
              </Button>
            </div>
            <div className={styles.rightTopBtns}>
              <Button icon={<EyeFilled />} onClick={() => setIsModalOpen(true)}>{isMobile ? "" : "Preview"}</Button>
              <Modal
                width="auto"
                title="Preview"
                zIndex={5000}
                open={isModalOpen}
                footer={[
                  <Button key="back" onClick={() => setIsModalOpen(false)}>
                    Back tp Design
                  </Button>
                ]}
                onCancel={() => setIsModalOpen(false)}
              >
                <div className={styles.previewContainer}>
                  <div className={styles.previewImg}>
                    <div style={{
                      position: 'relative',
                      width: shapeJson.width - 40,
                      height: shapeJson.height - 40,
                      overflow: 'hidden',
                      border: '1px solid #333',
                      borderRadius: 10
                    }}>
                      <div className={styles.previewImgInner}>
                        <canvas ref={canvasFrontRef} width={shapeJson.width - 40} height={shapeJson.height - 40}></canvas>
                        <div style={{
                          left: (shapeJson.width - 40) / 2 - 5,
                          top: shapeJson.y,
                        }} className={styles.pin}></div>
                        {showWarning && (
                          <img
                            className={styles.warning}
                            src={Warning}
                            style={{
                              left: (shapeJson.width - 40) / 2 - 96,
                              top: shapeJson.height - 70,
                            }}
                          />
                        )}
                        {
                          settings[0].texts &&
                          settings[0].texts.map((item, i) => (
                            <div
                              key={item.id}
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                padding: 10,
                                fontFamily: item.font,
                                color: item.color,
                                backgroundColor: item.backgroundColor,
                                fontSize: item.fontSize,
                                textDecoration: item.underline ? "underline" : "",
                                fontWeight: item.bold ? "bold" : "",
                                fontStyle: item.italic ? "italic" : "",
                                userSelect: "none",
                                transform: convertTransformToText1(item.transform, shapeJson),
                                zIndex: item.zIndex,
                              }}
                            >
                              {item.text}
                            </div>
                            ))
                        }
                        {
                          settings[0].images &&
                          settings[0].images.map((item, i) => (
                            <img
                              key={item.id}
                              src={item.image}
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                padding: 10,
                                width: 150,
                                transform: convertTransformToText1(item.transform, shapeJson),
                                zIndex: item.zIndex,
                              }}
                            />
                          ))
                        }
                      </div>
                    </div>
                    <div>Front</div>
                  </div>
                  <div className={styles.previewImg}>
                    <div style={{
                      position: 'relative',
                      width: shapeJson.width - 40,
                      height: shapeJson.height - 40,
                      overflow: 'hidden',
                      border: '1px solid #333',
                      borderRadius: 10
                    }}>
                      <div className={styles.previewImgInner}>
                        <canvas ref={canvasBackRef} width={shapeJson.width - 40} height={shapeJson.height - 40}></canvas>
                        <div style={{
                          left: (shapeJson.width - 40) / 2 - 5,
                          top: shapeJson.y,
                        }} className={styles.pin}></div>
                        {showWarning && (
                          <img
                            className={styles.warning}
                            src={Warning}
                            style={{
                              left: (shapeJson.width - 40) / 2 - 96,
                              top: shapeJson.height - 70,
                            }}
                          />
                        )}
                        {
                          settings[1].texts &&
                          settings[1].texts.map((item, i) => (
                            <div
                              key={item.id}
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                padding: 10,
                                fontFamily: item.font,
                                color: item.color,
                                backgroundColor: item.backgroundColor,
                                fontSize: item.fontSize,
                                textDecoration: item.underline ? "underline" : "",
                                fontWeight: item.bold ? "bold" : "",
                                fontStyle: item.italic ? "italic" : "",
                                userSelect: "none",
                                transform: convertTransformToText1(item.transform, shapeJson),
                                zIndex: item.zIndex,
                              }}
                            >
                              {item.text}
                            </div>
                          ))
                        }
                        {
                          settings[1].images &&
                          settings[1].images.map((item, i) => (
                            <img
                              key={item.id}
                              src={item.image}
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                padding: 10,
                                width: 150,
                                transform: convertTransformToText1(item.transform, shapeJson),
                                zIndex: item.zIndex,
                              }}
                            />
                          ))
                        }
                      </div>
                    </div>
                    <div>Back</div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
          <div className={styles.rightTopThird}>
            <Checkbox
              onChange={(e) => setShowWarning(e.target.checked)}
              checked={showWarning}
            >
              Show Warning
            </Checkbox>
          </div>
        </div>
        <div
          ref={rightBottomRef}
          className={styles.rightBottom}
          onClick={() => {
            setEditId(-1);
            cancelAllActive();
            setSettings([...settings]);
          }}
        >
          {/*<canvas ref={canvasRef} height={500}></canvas>*/}
          {
            shapeJson.path.length > 0 && (
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={shapeJson.width} height={shapeJson.height} viewBox={shapeJson.viewBox}>
                <path fill={settings[aspect].activeColor || "none"} d={shapeJson.path[2]} stroke-width="4.1693" stroke="red" stroke-miterlimit="2.613" stroke-dasharray="12.508"></path>
                <path fill="none" stroke="black" stroke-width="4.1693" stroke-miterlimit="2.613" stroke-dasharray="12.508" d={shapeJson.path[1]}></path>
                <path fill="none" d={shapeJson.path[0]} stroke="green" stroke-width="4.1693" stroke-miterlimit="2.613" stroke-dasharray="12.508"></path>
              </svg>
            )
          }

          <div style={{
            left: shapeJson.x + shapeJson.width / 2 - 10,
            top: shapeJson.y + 25,
          }} className={styles.pin}></div>
          {showWarning && (
            <img
              className={styles.warning}
              src={Warning}
              style={{
                left: shapeJson.x + shapeJson.width / 2 - 96,
                top: shapeJson.y + shapeJson.height - 45,
              }}
            />
          )}
          {settings[aspect].texts &&
            settings[aspect].texts.map((item, i) => (
              <>
                <div
                  key={item.id}
                  ref={textTargetRef.current[i]}
                  onClick={(e) => {
                    e.stopPropagation()
                    objClick(item.id)
                  }}
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    padding: 10,
                    fontFamily: item.font,
                    color: item.color,
                    backgroundColor: item.backgroundColor,
                    fontSize: item.fontSize,
                    textDecoration: item.underline ? "underline" : "",
                    fontWeight: item.bold ? "bold" : "",
                    fontStyle: item.italic ? "italic" : "",
                    userSelect: "none",
                    transform: convertTransformToText(item.transform),
                    zIndex: item.zIndex,
                  }}
                >
                  {editId === item.id ? (
                    <div
                      className={styles.editText}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <input
                        type="text"
                        value={item.text}
                        ref={editInputRef}
                        onChange={(e) => {
                          item.text = e.target.value;
                          setSettings([...settings]);
                        }}
                      />
                    </div>
                  ) : (
                    item.text
                  )}
                </div>
                {editId !== item.id && item.active && (
                  <Moveable
                    onClick={(e) => {
                      objClick(item.id)
                    }}
                    ref={textMoveableTargetRef.current[i]}
                    target={textTargetRef.current[i]}
                    draggable={true}
                    scalable={true}
                    rotatable={true}
                    rotationPosition={"bottom"}
                    onRender={(e) => {
                      item.transform = e.transformObject;
                      e.target.style.cssText += e.cssText;
                    }}
                    props={{
                      dimensionViewable: true,
                    }}
                    ables={[
                      {
                        name: "dimensionViewable",
                        props: [],
                        events: [],
                        render(moveable, React) {
                          const rect = moveable.getRect();

                          // Add key (required)
                          // Add class prefix moveable-(required)
                          return (
                            <Flex
                              key={"dimension-viewer"}
                              className={"moveable-dimension"}
                              style={{
                                position: "absolute",
                                left: `${rect.width / 2}px`,
                                top: `-30px`,
                                background: "#FFC008",
                                borderRadius: "0.06rem",
                                color: "white",
                                fontSize: "13px",
                                whiteSpace: "nowrap",
                                fontWeight: "bold",
                                willChange: "transform",
                                transform: `translate(-50%, 0px)`,
                              }}
                            >
                              <Button
                                type="primary"
                                icon={<CopyFilled />}
                                onClick={() => {
                                  const newSettings = JSON.parse(
                                    JSON.stringify(settings),
                                  );
                                  newSettings[aspect].texts.push({
                                    ...item,
                                    id: Date.now() + Math.random() * 1000,
                                    transform: {
                                      ...item.transform,
                                      translate: [
                                        item.transform.translate[0] + 10,
                                        item.transform.translate[1] + 10,
                                        0,
                                      ],
                                    },
                                  });
                                  updatePresent(newSettings);
                                }}
                              />
                              <Button
                                type="primary"
                                icon={<TbFlipVertical fontSize={20} />}
                                onClick={() => {
                                  const newSettings = JSON.parse(
                                    JSON.stringify(settings),
                                  );
                                  newSettings[aspect].texts.find(
                                    (text) => text.id === item.id,
                                  ).transform.rotateY = item.transform.rotateY
                                    ? 0
                                    : 180;
                                  updatePresent(newSettings);
                                }}
                              />
                              <Button
                                type="primary"
                                icon={
                                  <BiSolidLayerPlus
                                    fontSize={20}
                                    onClick={() => {
                                      const newSettings = JSON.parse(
                                        JSON.stringify(settings),
                                      );
                                      newSettings[aspect].texts.find(
                                        (text) => text.id === item.id,
                                      ).zIndex = item.zIndex
                                        ? item.zIndex + 1
                                        : 1;
                                      updatePresent(newSettings);
                                    }}
                                  />
                                }
                              />
                              <Button
                                type="primary"
                                icon={
                                  <BiSolidLayerMinus
                                    fontSize={20}
                                    onClick={() => {
                                      const newSettings = JSON.parse(
                                        JSON.stringify(settings),
                                      );
                                      newSettings[aspect].texts.find(
                                        (text) => text.id === item.id,
                                      ).zIndex = item.zIndex
                                        ? item.zIndex - 1
                                        : 1;
                                      updatePresent(newSettings);
                                    }}
                                  />
                                }
                              />
                              <Button
                                type="primary"
                                icon={
                                  <MdOutlineAlignHorizontalCenter
                                    fontSize={20}
                                    onClick={(e) => {
                                      const newSettings = JSON.parse(
                                        JSON.stringify(settings),
                                      );
                                      const textWidth =
                                        textTargetRef.current[
                                          i
                                        ].current.getBoundingClientRect().width;
                                      if (
                                        !item.transform ||
                                        !item.transform.translate
                                      ) {
                                        newSettings[aspect].texts.find(
                                          (text) => text.id === item.id,
                                        ).transform = {
                                          ...item.transform,
                                          translate: [
                                            shapeJson.x +
                                            shapeJson.width / 2 -
                                              textWidth / 2,
                                            0,
                                            0,
                                          ],
                                        };
                                      } else {
                                        newSettings[aspect].texts.find(
                                          (text) => text.id === item.id,
                                        ).transform.translate = [
                                          shapeJson.x +
                                          shapeJson.width / 2 -
                                            textWidth / 2,
                                          item.transform.translate[1],
                                        ];
                                      }
                                      updatePresent(newSettings);
                                      setTimeout(() => {
                                        textMoveableTargetRef.current[
                                          i
                                        ].current.updateRect();
                                      });
                                    }}
                                  />
                                }
                              />
                              <Button
                                type="primary"
                                icon={
                                  <MdOutlineAlignVerticalCenter
                                    fontSize={20}
                                    onClick={() => {
                                      const newSettings = JSON.parse(
                                        JSON.stringify(settings),
                                      );
                                      const textHeight =
                                        textTargetRef.current[
                                          i
                                        ].current.getBoundingClientRect()
                                          .height;
                                      if (
                                        !item.transform ||
                                        !item.transform.translate
                                      ) {
                                        newSettings[aspect].texts.find(
                                          (text) => text.id === item.id,
                                        ).transform = {
                                          ...item.transform,
                                          translate: [
                                            0,
                                            shapeJson.y +
                                            shapeJson.height / 2 -
                                              textHeight / 2,
                                            0,
                                          ],
                                        };
                                      } else {
                                        newSettings[aspect].texts.find(
                                          (text) => text.id === item.id,
                                        ).transform.translate = [
                                          item.transform.translate[0],
                                          shapeJson.y +
                                          shapeJson.height / 2 -
                                            textHeight / 2,
                                        ];
                                      }
                                      updatePresent(newSettings);
                                      setTimeout(() => {
                                        textMoveableTargetRef.current[
                                          i
                                        ].current.updateRect();
                                      });
                                    }}
                                  />
                                }
                              />
                              <Button
                                type="primary"
                                icon={
                                  <HiOutlineMagnifyingGlassPlus
                                    fontSize={20}
                                    onClick={() => {
                                      const newSettings = JSON.parse(
                                        JSON.stringify(settings),
                                      );
                                      if (
                                        !item.transform ||
                                        !item.transform.scale
                                      ) {
                                        newSettings[aspect].texts.find(
                                          (text) => text.id === item.id,
                                        ).transform = {
                                          ...item.transform,
                                          scale: [1.1, 1.1],
                                        };
                                      } else {
                                        newSettings[aspect].texts.find(
                                          (text) => text.id === item.id,
                                        ).transform.scale[0] *= 1.1;
                                        newSettings[aspect].texts.find(
                                          (text) => text.id === item.id,
                                        ).transform.scale[1] *= 1.1;
                                      }
                                      updatePresent(newSettings);
                                      setTimeout(() => {
                                        textMoveableTargetRef.current[
                                          i
                                        ].current.updateRect();
                                      });
                                    }}
                                  />
                                }
                              />
                              <Button
                                type="primary"
                                icon={
                                  <HiOutlineMagnifyingGlassMinus
                                    fontSize={20}
                                    onClick={() => {
                                      const newSettings = JSON.parse(
                                        JSON.stringify(settings),
                                      );
                                      if (
                                        !item.transform ||
                                        !item.transform.scale
                                      ) {
                                        newSettings[aspect].texts.find(
                                          (text) => text.id === item.id,
                                        ).transform = {
                                          ...item.transform,
                                          scale: [0.9, 0.9],
                                        };
                                      } else {
                                        newSettings[aspect].texts.find(
                                          (text) => text.id === item.id,
                                        ).transform.scale[0] *= 0.9;
                                        newSettings[aspect].texts.find(
                                          (text) => text.id === item.id,
                                        ).transform.scale[1] *= 0.9;
                                      }
                                      updatePresent(newSettings);
                                      setTimeout(() => {
                                        textMoveableTargetRef.current[
                                          i
                                        ].current.updateRect();
                                      });
                                    }}
                                  />
                                }
                              />
                              <Button
                                type="primary"
                                icon={<DeleteFilled />}
                                onClick={() => {
                                  const newSettings = JSON.parse(
                                    JSON.stringify(settings),
                                  );
                                  newSettings[aspect].texts.splice(i, 1);
                                  updatePresent(newSettings);
                                }}
                              />
                            </Flex>
                          );
                        },
                      },
                    ]}
                  />
                )}
              </>
            ))}
          {settings[aspect].images &&
            settings[aspect].images.map((item, i) => (
              <div key={item.id}>
                <img
                  src={item.image}
                  ref={imageTargetRef.current[i]}
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    padding: 10,
                    width: 150,
                    transform: convertTransformToText(item.transform),
                    zIndex: item.zIndex,
                  }}
                  onClick={() => objClick(item.id)}
                  // onTouchEnd={() => objClick(item.id)}
                />
                {item.active && (
                  <Moveable
                    ref={imageMoveableTargetRef.current[i]}
                    target={imageTargetRef.current[i]}
                    draggable={true}
                    scalable={true}
                    rotatable={true}
                    useAccuratePosition={true}
                    rotationPosition={"bottom"}
                    onRender={(e) => {
                      item.transform = e.transformObject;
                      e.target.style.cssText += e.cssText;
                    }}
                    props={{
                      dimensionViewable: true,
                    }}
                    ables={[
                      {
                        name: "dimensionViewable",
                        props: [],
                        events: [],
                        render(moveable, React) {
                          const rect = moveable.getRect();

                          // Add key (required)
                          // Add class prefix moveable-(required)
                          return (
                            <Flex
                              key={"dimension-viewer"}
                              className={"moveable-dimension"}
                              style={{
                                position: "absolute",
                                left: `${rect.width / 2}px`,
                                top: `-30px`,
                                background: "#FFC008",
                                borderRadius: "0.06rem",
                                color: "white",
                                fontSize: "13px",
                                whiteSpace: "nowrap",
                                fontWeight: "bold",
                                willChange: "transform",
                                transform: `translate(-50%, 0px)`,
                              }}
                            >
                              <Button
                                type="primary"
                                icon={<CopyFilled />}
                                onClick={() => {
                                  const newSettings = JSON.parse(
                                    JSON.stringify(settings),
                                  );
                                  newSettings[aspect].images.push({
                                    ...item,
                                    id: Date.now() + Math.random() * 1000,
                                    transform: {
                                      ...item.transform,
                                      translate: [
                                        item.transform.translate[0] + 10,
                                        item.transform.translate[1] + 10,
                                        0,
                                      ],
                                    },
                                  });
                                  updatePresent(newSettings);
                                }}
                              />
                              <Button
                                type="primary"
                                icon={<TbFlipVertical fontSize={20} />}
                                onClick={() => {
                                  const newSettings = JSON.parse(
                                    JSON.stringify(settings),
                                  );
                                  newSettings[aspect].images.find(
                                    (image) => image.id === item.id,
                                  ).transform.rotateY = item.transform.rotateY
                                    ? 0
                                    : 180;
                                  updatePresent(newSettings);
                                }}
                              />
                              <Button
                                type="primary"
                                icon={
                                  <BiSolidLayerPlus
                                    fontSize={20}
                                    onClick={() => {
                                      const newSettings = JSON.parse(
                                        JSON.stringify(settings),
                                      );
                                      newSettings[aspect].images.find(
                                        (image) => image.id === item.id,
                                      ).zIndex = item.zIndex
                                        ? item.zIndex + 1
                                        : 1;
                                      updatePresent(newSettings);
                                    }}
                                  />
                                }
                              />
                              <Button
                                type="primary"
                                icon={
                                  <BiSolidLayerMinus
                                    fontSize={20}
                                    onClick={() => {
                                      const newSettings = JSON.parse(
                                        JSON.stringify(settings),
                                      );
                                      newSettings[aspect].images.find(
                                        (image) => image.id === item.id,
                                      ).zIndex = item.zIndex
                                        ? item.zIndex - 1
                                        : 1;
                                      updatePresent(newSettings);
                                    }}
                                  />
                                }
                              />
                              <Button
                                type="primary"
                                icon={
                                  <MdOutlineAlignHorizontalCenter
                                    fontSize={20}
                                    onClick={(e) => {
                                      const newSettings = JSON.parse(
                                        JSON.stringify(settings),
                                      );
                                      const imgWidth =
                                        imageTargetRef.current[i].current.width;
                                      if (
                                        !item.transform ||
                                        !item.transform.translate
                                      ) {
                                        newSettings[aspect].images.find(
                                          (image) => image.id === item.id,
                                        ).transform = {
                                          ...item.transform,
                                          translate: [
                                            shapeJson.x +
                                            shapeJson.width / 2 -
                                              imgWidth / 2 -
                                              10,
                                            0,
                                            0,
                                          ],
                                        };
                                      } else {
                                        newSettings[aspect].images.find(
                                          (image) => image.id === item.id,
                                        ).transform.translate = [
                                          shapeJson.x +
                                          shapeJson.width / 2 -
                                            imgWidth / 2 -
                                            10,
                                          item.transform.translate[1],
                                        ];
                                      }
                                      updatePresent(newSettings);
                                      setTimeout(() => {
                                        imageMoveableTargetRef.current[
                                          i
                                        ].current.updateRect();
                                      });
                                    }}
                                  />
                                }
                              />
                              <Button
                                type="primary"
                                icon={
                                  <MdOutlineAlignVerticalCenter
                                    fontSize={20}
                                    onClick={() => {
                                      const newSettings = JSON.parse(
                                        JSON.stringify(settings),
                                      );
                                      const imgHeight =
                                        imageTargetRef.current[i].current
                                          .height;
                                      if (
                                        !item.transform ||
                                        !item.transform.translate
                                      ) {
                                        newSettings[aspect].images.find(
                                          (image) => image.id === item.id,
                                        ).transform = {
                                          ...item.transform,
                                          translate: [
                                            0,
                                            shapeJson.y +
                                            shapeJson.height / 2 -
                                              imgHeight / 2 -
                                              10,
                                            0,
                                          ],
                                        };
                                      } else {
                                        newSettings[aspect].images.find(
                                          (image) => image.id === item.id,
                                        ).transform.translate = [
                                          item.transform.translate[0],
                                          shapeJson.y +
                                          shapeJson.height / 2 -
                                            imgHeight / 2 -
                                            10,
                                        ];
                                      }
                                      updatePresent(newSettings);
                                      setTimeout(() => {
                                        imageMoveableTargetRef.current[
                                          i
                                        ].current.updateRect();
                                      });
                                    }}
                                  />
                                }
                              />
                              <Button
                                type="primary"
                                icon={
                                  <HiOutlineMagnifyingGlassPlus
                                    fontSize={20}
                                    onClick={() => {
                                      const newSettings = JSON.parse(
                                        JSON.stringify(settings),
                                      );
                                      if (
                                        !item.transform ||
                                        !item.transform.scale
                                      ) {
                                        newSettings[aspect].images.find(
                                          (image) => image.id === item.id,
                                        ).transform = {
                                          ...item.transform,
                                          scale: [1.1, 1.1],
                                        };
                                      } else {
                                        newSettings[aspect].images.find(
                                          (image) => image.id === item.id,
                                        ).transform.scale[0] *= 1.1;
                                        newSettings[aspect].images.find(
                                          (image) => image.id === item.id,
                                        ).transform.scale[1] *= 1.1;
                                      }
                                      updatePresent(newSettings);
                                      setTimeout(() => {
                                        imageMoveableTargetRef.current[
                                          i
                                        ].current.updateRect();
                                      });
                                    }}
                                  />
                                }
                              />
                              <Button
                                type="primary"
                                icon={
                                  <HiOutlineMagnifyingGlassMinus
                                    fontSize={20}
                                    onClick={() => {
                                      const newSettings = JSON.parse(
                                        JSON.stringify(settings),
                                      );
                                      if (
                                        !item.transform ||
                                        !item.transform.scale
                                      ) {
                                        newSettings[aspect].images.find(
                                          (image) => image.id === item.id,
                                        ).transform = {
                                          ...item.transform,
                                          scale: [0.9, 0.9],
                                        };
                                      } else {
                                        newSettings[aspect].images.find(
                                          (image) => image.id === item.id,
                                        ).transform.scale[0] *= 0.9;
                                        newSettings[aspect].images.find(
                                          (image) => image.id === item.id,
                                        ).transform.scale[1] *= 0.9;
                                      }
                                      updatePresent(newSettings);
                                      setTimeout(() => {
                                        imageMoveableTargetRef.current[
                                          i
                                        ].current.updateRect();
                                      });
                                    }}
                                  />
                                }
                              />
                              <Button
                                type="primary"
                                icon={<DeleteFilled />}
                                onClick={() => {
                                  const newSettings = JSON.parse(
                                    JSON.stringify(settings),
                                  );
                                  newSettings[aspect].images.splice(i, 1);
                                  updatePresent(newSettings);
                                }}
                              />
                            </Flex>
                          );
                        },
                      },
                    ]}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default DesignerScreenV2;
