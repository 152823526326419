export const CART_ADD_ITEM = 'CART_ADD_ITEM'
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'

export const CART_SAVE_SHIPPING_ADDRESS = 'CART_SAVE_SHIPPING_ADDRESS'

export const CART_SAVE_PAYMENT_METHOD = 'CART_SAVE_PAYMENT_METHOD'

export const CART_CLEAR_ITEMS = 'CART_CLEAR_ITEMS'

export const CART_REQUEST_DATA = 'CART_REQUEST_DATA'

export const SET_SIDEBAR_SHOW = 'SET_SIDEBAR_SHOW'

