import React,{useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listProductDetails, queryProductTotal } from '../actions/productActions'
import { addToCart } from '../actions/cartActions'
import LoadSpinner from './LoadSpinner'
import Confirm from './Confirm'
import './SelectItem.css';
import {Button, Flex, Select} from "antd";
import {CloseOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons"; // import the CSS file
function SelectItem({item, noFun}) {
    const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin
    const dispatch = useDispatch()
    const productDetails = useSelector(state => state.productDetails)
    const { error, loading, product } = productDetails
    const [color, setColor] = useState('');
    const [combo, setCombo] = useState('');
    useEffect(() => {
        const handleClickOutside = (event) => {
          const popup = document.querySelector('.SelectItem')
          console.log(event.target,888)
          if (popup && !popup.contains(event.target) && event.target.className.indexOf('btn')===-1) {
            noFun()
          }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
          document.removeEventListener('click', handleClickOutside)
        }
    }, [noFun])
    const [qty, setQty] = useState(1);
    const [tip, setTip] = useState('');
    useEffect(() => {
        if(item &&item.id) dispatch(listProductDetails(item.id));
        console.log(product)
    }, [dispatch, item]);
    const okFun = ()=>{
        if(product.ftotal <= 0) {
            return
        }
        if(!color) {
            setTip('please choose a type option');
            return;
        }
        if(!combo) {
            setTip('please choose a combo option');
            return;
        }

        const {data} = JSON.parse(item.pinfojsonmember)
        const obj = data.find(item=>{
            return item.color === color && item.combo === combo
        })
        let item2 = {...item}
        if(obj ) {
            item2.total = obj.total
            item2.color = color
            item2.combo = combo
            console.log(obj)
            item2.price = obj.price
        }

        dispatch(addToCart(item2, qty, color, combo));
        noFun();
    }
    const changeIptHandle = (key) => {
        return (value) => {
            if(key == 'color') {
                setColor(value);
                dispatch(queryProductTotal( value, combo, product))
            }else if(key == 'combo') {
                setCombo(value);
                dispatch(queryProductTotal( color, value, product))
            }
        }
    }
    return (
        <div className="f-loading bg-white">
            {tip&&<Confirm okFun={()=>setTip('')}   tip={tip} confirmText="OK" />}
            <div className="SelectItem greenCard" onClick={(e) => e.stopPropagation()} >
                <CloseOutlined onClick={noFun} className="close-btn" />
                {
                    loading ? <div className="fullcreen"><LoadSpinner /></div>: <div>

                        <h2 className="SelTitle" style={{textTransform: 'none'}}>
                            <div>{item.name}</div>
                        </h2>
                          <div className="layui-form lis">
                                          <Flex gap="middle" align="center">
                                              <div className="goods-attr-label">Color</div>
                                              <Select
                                                placeholder="Choose Color"
                                                value={color}
                                                style={{ width: "100%", height: "36px", textAlign: "left" }}
                                                onChange={changeIptHandle('color')}
                                                options={product&&product.colors&&product.colors.map((item,index) => ({ value: item, label: item }))}
                                              />
                                          </Flex>
                                        </div>
                                        <br />
                                        <div className="layui-form lis">
                                            <Flex gap="middle" align="center">
                                                <div className="goods-attr-label">Combo</div>
                                                <Select
                                                  placeholder="Choose Combo"
                                                  value={combo}
                                                  style={{ width: "100%", height: "36px", textAlign: "left" }}
                                                  onChange={changeIptHandle('combo')}
                                                  options={product&&product.combos&&product.combos.map((item,index) => ({ value: item, label: item }))}
                                                />
                                            </Flex>
                                        </div>
                                        <br />
                                         <Flex className="goods-actions" align="center" justify="space-between">
                                             <Flex align="center" justify="space-between">
                                                <div className="quantity-input flex-center">
                                                    <div className="quantity-btn quantity-btn-left" onClick={()=>setQty(qty-1>0?(qty-1) : 1)}><MinusOutlined /></div>
                                                    <input type="text" onChange={(e)=>setQty(e.target.value ? e.target.value*1 : "")} value={qty} />
                                                    <div className="quantity-btn quantity-btn-right" onClick={()=>setQty((qty+1)>product.ftotal ? product.ftotal : (qty+1))}><PlusOutlined /></div>
                                                </div>
                                                <div className="layui-form lis" style={{color: '#45BC73', fontSize: '28px',textAlign: 'left', fontWeight: 'normal'}}>
                                                    {
                                                        product.ftotal > 0 ? <div className="stock-pc inStock">
                                                            <img src="./images/index/gou.png" />
                                                            <div>In stock</div>
                                                        </div>:<div className="stock-mb notInStock">
                                                            <img src="./images/index/cha.png" />
                                                            <div>Not in stock</div>
                                                        </div>
                                                    }
                                                </div>
                                             </Flex>
                                             <Flex align="center" justify="space-between">
                                                 <div className="price">
                                                     £
                                                     {
                                                         product && product.info ?
                                                           (product.info.pricemember[userInfo && userInfo.name || 'A'] * product.info.discount * qty).toFixed(2) :
                                                           0
                                                     }
                                                 </div>
                                             </Flex>
                                        </Flex>
                        <div onClick={okFun} className={ product.ftotal > 0?" basket-btn":"disabled basket-btn"}>Add to Basket</div>

                    </div>
                }

            </div>
        </div>
    )
}

export default SelectItem
