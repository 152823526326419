export const GET_ADDRESS = 'GET_ADDRESS'
export const REQUEST_FAIL = 'REQUEST_FAIL'
export const GET_ADDRESS_REQUEST = 'GET_ADDRESS_REQUEST'
export const EDIT_ADDRESS = 'EDIT_ADDRESS'
export const EDIT_ADDRESS_COMPLETE = 'EDIT_ADDRESS_COMPLETE'

export const SET_PICKUP = 'SET_PICKUP'
export const GET_PICKUP_REQUEST = 'GET_PICKUP_REQUEST'
export const GET_PICKUP_COMPLETE = 'GET_PICKUP_COMPLETE'


