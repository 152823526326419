import React from 'react'
function Confirm({tip, okFun, noFun, confirmText, title, cancelText, children, className}) {
    // console.log(okFun, 999);
    return (
        <div className={"f-loading " + (className || '') }>
            
            <div className="Confirm">
                <div className="title-confirm">{title || 'Confirm Action'}</div>
                {tip && <div className="confirm-content">{tip}</div>}
                {children}
                <div  className="flex-confirm "><div onClick={okFun} className=" confirm-basket-btn">{confirmText || 'YES'}</div>
                {noFun&&<div className=" confirm-basket-btn no" onClick={noFun}>{cancelText || 'NO'}</div>} </div>
            </div>
        </div>
    )
}

export default Confirm
