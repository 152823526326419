import { fabric } from 'fabric';

export const SHAPE_LINES = [
  'bleed_dash',
  'base_dash',
  'safe_dash',
  'warning',
  'warning_inverse',
  'hole_dash',
];

export const TABS = {
  TEXT: 'text',
  IMAGE: 'image',
  BG_COLOR: 'bgColor',
  Sticker: 'sticker',
  COPY: 'copy',
};

export const SIDES = { FRONT: 'front', BACK: 'back' };

export const FONT_FAMILY = [
  'Aleo',
  'Amatic_SC',
  'Corinthia',
  'Fira_Sans',
  'Krona_One',
  'Kumar_One_Outline',
  'Lobster_Two',
  'Molle',
  'Monoton',
  'Nixie_One',
  'Permanent_Marker',
  'Sancreek',
  'Stint_Ultra_Expanded',
  'VT323',
];

export const DEFAULT_FONT = FONT_FAMILY[0];

export const getFrontBackgroundShape = async (shape) => {
  let result;
  fabric.loadSVGFromString(shape['bleed_fill'], (objects, options) => {
    result = fabric.util.groupSVGElements(objects, options);
    result.set({
      fill: 'rgb(255,255,255)',
    });
  });

  return result;
};

export const setOverlay = async ({ shape, fabric, front, back, withWarningText }) => {
  let frontOverlayShapes = [];
  let backOverlayShapes = [];
  let frontOverlayShapesWithoutWarning = [];
  let backOverlayShapesWithoutWarning = [];
  let frontOverlay;
  let backOverlay;

  SHAPE_LINES.map((shapeLine) => {
    fabric.loadSVGFromString(shape[shapeLine], (objects, options) => {
      const currentShape = fabric.util.groupSVGElements(objects, options);

      currentShape.set({
        fill: getFillForShape(shapeLine),
      });

      if (shapeLine === 'safe_dash') {
        if (currentShape?.path?.length) {
          currentShape.set({ stroke: 'green' });
        } else currentShape?._objects?.forEach((obj) => obj.set({ stroke: 'green' }));
      }
      if (shapeLine === 'warning') {
        frontOverlayShapes.push(currentShape);

        if (!withWarningText) {
          currentShape.set({ opacity: 0 });
        }
      } else if (shapeLine === 'warning_inverse') {
        backOverlayShapes.push(currentShape);

        if (!withWarningText) {
          currentShape.set({ opacity: 0 });
        }
      } else {
        frontOverlayShapes.push(currentShape);
        frontOverlayShapesWithoutWarning.push(currentShape);

        currentShape.clone((c) => {
          backOverlayShapes.push(c);
          backOverlayShapesWithoutWarning.push(c);
        });
      }

      if (frontOverlayShapes.length === SHAPE_LINES.length - 1) {
        frontOverlay = new fabric.Group(frontOverlayShapes);
        backOverlay = new fabric.Group(backOverlayShapes);
        backOverlay.set({ flipX: true });
        front.setOverlayImage(frontOverlay);
        back.setOverlayImage(backOverlay);
      }
    });
  });

  return {
    frontOverlayShapes,
    backOverlayShapes,
    frontOverlayShapesWithoutWarning,
    backOverlayShapesWithoutWarning,
    frontOverlay,
    backOverlay,
  };
};

export const getBackBackgroundShape = async (shape) => {
  const clonedShape = await clonePromise(shape);
  clonedShape.set({ flipX: true });

  return clonedShape;
};

export const resize = async ({
  containerRef,
  isMobile,
  front,
  back,
  backBackgroundShape,
  frontBackgroundShape,
}) => {
  const containerWidth = containerRef.offsetWidth;
  const containerHeight = containerRef.offsetHeight;

  const shapeWidth = frontBackgroundShape.width;
  const shapeHeight = frontBackgroundShape.height;
  const widthDelta = containerWidth / shapeWidth;
  const heightDelta = containerHeight / shapeHeight;
  let zoomBuffer = isMobile ? 100 : 300;
  let zoom;
  if (widthDelta > heightDelta) {
    zoom = containerHeight / (shapeHeight + zoomBuffer);
  } else {
    zoom = containerWidth / (shapeWidth + zoomBuffer);
  }

  const zoomBufferX = containerWidth / 2 - (shapeWidth * zoom) / 2;
  const zoomBufferY = containerHeight / 2 - (shapeHeight * zoom) / 2;

  front.viewportTransform[0] = zoom;
  front.viewportTransform[3] = zoom;
  front.viewportTransform[4] = Math.round(zoomBufferX);
  front.viewportTransform[5] = Math.round(zoomBufferY);
  back.viewportTransform = front.viewportTransform;

  front.setDimensions({ width: containerWidth, height: containerHeight });
  back.setDimensions({ width: containerWidth, height: containerHeight });

  front.getObjects().map((el) => el.setCoords());
  back.getObjects().map((el) => el.setCoords());
};

export const getImageDataFromSide = (side, withWarningText = false, shape) => {
  const MULTIPLIER = 2;
  const { width: shapeWidth, height: shapeHeight } = shape;
  const sideZoom = side.viewportTransform;
  side.viewportTransform = [0.5, 0, 0, 0.5, 0, 0];
  side.renderAll();
  const overlay = side.overlayImage;
  const topCrop = 0;
  const leftCrop = 0;
  let beforeOpacity = null;
  const imageSettings = {
    format: 'png',
    left: leftCrop,
    top: topCrop,
    width: shapeWidth / 2,
    height: shapeHeight / 2,
    multiplier: MULTIPLIER,
  };
  if (withWarningText) {
    const objects = overlay.getObjects();
    objects.map((element, i) => {
      if (i !== 3) {
        element.set({ opacity: 0 });
      } else {
        beforeOpacity = element.opacity;
      }
    });
  } else {
    beforeOpacity = 0;
    overlay.set({ opacity: 0 });
  }
  const imageData = side.toDataURL(imageSettings);
  overlay.getObjects().map((el, i) => {
    if (i === 3) {
      el.set({ opacity: beforeOpacity });
    } else {
      el.set({ opacity: 1 });
    }
  });
  overlay.set({ opacity: 1 });
  side.viewportTransform = sideZoom;
  side.renderAll();

  return imageData;
};

//设置背景颜色
export const setBackgroundColor = (props, color) => {
  const currentCanvas = props.canvas;
  currentCanvas.backgroundImage.set({ fill: color });
  currentCanvas.renderAll();
  handleObjectChange(props);
};

// 设置字体到canvas
export const addTextToCanvasFunction = async (text, props) => {
  const currentCanvas = props.canvas;
  const center = currentCanvas.getVpCenter();
  const MAX_CHARACTERS = 17;

  const splitWord = (str, length) => {
    var words = str.split(' ');
    for (var j = 0; j < words.length; j++) {
      var l = words[j].length;
      if (l > length) {
        var result = [],
          i = 0;
        while (i < l) {
          result.push(words[j].substr(i, length));
          i += length;
        }
        words[j] = result.join('-\n');
      }
    }
    return words.join('-\n');
  };

  const splitText = (txt) => {
    const arrTxt = txt.split(' ');
    return arrTxt.reduce((acc, current) => {
      if (current?.length > MAX_CHARACTERS)
        return acc + '\n' + splitWord(current, MAX_CHARACTERS - 1);
      if (acc?.split('\n').pop()?.length + current?.length > MAX_CHARACTERS)
        return acc + '\n' + current;
      return acc + ' ' + current;
    }, '');
  };

  const textObject = new fabric.Textbox(splitText(text), {
    top: center.y,
    left: center.x,
    originX: 'center',
    originY: 'center',
    fontSize: 70,
    width: 400,
  });
  currentCanvas.add(textObject);
  textObject.set({
    fontFamily: DEFAULT_FONT,
  });

  return textObject;
};

// 添加字体
export const addTextToCanvas = async (props, text) => {
  try {
    const textObject = await addTextToCanvasFunction(text, props);
    handleObjectChange(props);

    textObject.setCoords();
    const currentCanvas = props.canvas;
    currentCanvas.setActiveObject(textObject);
    renderAllCanvas(props.allCanvas);
  } catch (error) {
    console.log('error - >:', error);
  }
};

// 通用属性改变
export const changeCommon = (props, key, value) => {
  const currentCanvas = props.canvas;
  const activeObject = currentCanvas.getActiveObjects()[0];
  if (activeObject) {
    activeObject && activeObject.set(key, value);
    renderAllCanvas(props.allCanvas);
    handleObjectChange(props);
  }
};

// 操作历史记录
export const handleObjectChange = (props) => {
  const currentCanvas = props.canvas;
  const currentSnapshot = currentCanvas.toJSON();

  if (props.currentType === SIDES.FRONT) {
    props.historyMap.frontUndoHistory.push(currentSnapshot);
    props.historyMap.frontRedoHistory = [];
  } else {
    props.historyMap.backUndoHistory.push(currentSnapshot);
    props.historyMap.backRedoHistory = [];
  }

  props.historyMap.hasUndo = {
    front: props.historyMap.frontUndoHistory.length > 1,
    back: props.historyMap.backUndoHistory.length > 1,
  };

  props.historyMap.hasRedo = {
    front: props.historyMap.frontRedoHistory.length > 0,
    back: props.historyMap.backRedoHistory.length > 0,
  };
};

// 插入图片
export const insertImgFile = (file, props) => {
  const imgEl = document.createElement('img');
  imgEl.src = file;
  imgEl.style.display = 'none';
  // 插入页面
  document.body.appendChild(imgEl);

  const currentCanvas = props.canvas;

  const center = currentCanvas.getVpCenter();

  imgEl.onload = async () => {
    var imgInstance = new fabric.Image(imgEl, {
      top: center.y,
      left: center.x,
      originX: 'center',
      originY: 'center',
    });
    currentCanvas.add(imgInstance);
    currentCanvas.setActiveObject(imgInstance);
    renderAllCanvas(props.allCanvas);
    handleObjectChange(props);
  };
};

// 复制图层
export const duplicateImage = async (props) => {
  const currentCanvas = props.canvas;
  const activeGroup = currentCanvas.getActiveObjects();
  const activeObject = currentCanvas.getActiveObject();

  if (activeGroup) {
    activeGroup.forEach((el) => {
      const top = el.top + 20;
      const left = el.left + 20;
      el.clone((c) => {
        c.set({
          top: top,
          left: left,
        });
        currentCanvas.add(c);
      });
    });
  } else {
    if (activeObject != null) {
      activeObject.clone((c) => {
        c.set({
          top: c.top + 20,
          left: c.left + 20,
        }).setCoords();
        currentCanvas.add(c);
        currentCanvas.setActiveObject(c);
      });
    }
  }
  handleObjectChange(props);
};

// 复制一面到另外一面
export const duplicateToOtherSide = (props) => {
  duplicateImageToOtherSide(props);
  clearSelection(props.allCanvas);
  toggleSide(props);
  handleObjectChange(props);
};
export const duplicateImageToOtherSide = async ({ currentType, allCanvas }) => {
  const { front, back } = allCanvas;

  let frontData = front.toJSON();
  let backData = back.toJSON();

  if (currentType === SIDES.FRONT) {
    backData['objects'] = frontData['objects'];
    backData['backgroundImage'].fill = frontData['backgroundImage'].fill;
    back.loadFromJSON(backData);
  } else {
    frontData['objects'] = backData['objects'];
    frontData['backgroundImage'].fill = backData['backgroundImage'].fill;
    front.loadFromJSON(frontData);
  }
};
export const clearSelection = async ({ front, back }) => {
  front.discardActiveObject();
  back.discardActiveObject();
};
export const toggleSide = (props) => {
  clearSelection(props.allCanvas);
  const updatedSide = props.currentType === SIDES.FRONT ? SIDES.BACK : SIDES.FRONT;
  props.copyedToggle(updatedSide);
  renderAllCanvas(props.allCanvas);
};

export const setWithWarningText = (props) => {
  const { back, front } = props.allCanvas;
  [back, front].forEach((side) => toggleWarningTextShape(side, props.showWarning));
  renderAllCanvas(props.allCanvas);
};

export const toggleWarningTextShape = (side, show) => {
  side.overlayImage.getObjects().forEach((el, i) => {
    if (i === 3) {
      el.set({ opacity: show ? 1 : 0 });
    }
  });
};

export const saveDesign = (props) => {
  const {
    allCanvas: { front, back },
    showWarning,
  } = props;

  const frontData = getImageDataFromSide(front, showWarning, props);
  console.log('frontData - >:', frontData);
  const backData = getImageDataFromSide(back, showWarning, props);
  console.log('backData - >:', backData);

  // const frontJson = getShapeDataFromSide(front);
  // const backJson = getShapeDataFromSide(back);

  front.renderAll();
  back.renderAll();
};

export const getShapeDataFromSide = (side, isWarningDisplayed = true) => {
  const { backgroundImage, overlayImage, ...sideJson } = {
    ...side.toJSON(['width', 'height']),
    backgroundFillColor: side.backgroundImage.fill,
    isWarningDisplayed,
  };

  return sideJson;
};

// 翻转
export const flipImage = async (props) => {
  const currentCanvas = props.canvas;
  const activeObject = currentCanvas.getActiveObjects()[0];
  activeObject.set({ flipX: true });

  renderAllCanvas(props.allCanvas);
};

export const up = (props) => {
  const currentCanvas = props.canvas;
  const activeObject = currentCanvas.getActiveObjects()[0];
  activeObject && activeObject.bringForward();
  renderAllCanvas(props.allCanvas);
  handleObjectChange(props);
};
export const down = (props) => {
  const currentCanvas = props.canvas;
  const activeObject = currentCanvas.getActiveObjects()[0];
  activeObject && activeObject.sendBackwards();
  renderAllCanvas(props.allCanvas);
  handleObjectChange(props);
};

export const horizontalCenter = (props) => {
  const currentCanvas = props.canvas;
  const activeObject = currentCanvas.getActiveObjects()[0];

  currentCanvas.viewportCenterObjectH(activeObject);
  renderAllCanvas(props.allCanvas);
  handleObjectChange(props);
};
export const verticalCenter = (props) => {
  const currentCanvas = props.canvas;
  const activeObject = currentCanvas.getActiveObjects()[0];

  currentCanvas.viewportCenterObjectV(activeObject);
  renderAllCanvas(props.allCanvas);
  handleObjectChange(props);
};
export const deleteItem = (props) => {
  const currentCanvas = props.canvas;
  const activeObject = currentCanvas.getActiveObject();
  currentCanvas.remove(activeObject);
  renderAllCanvas(props.allCanvas);
  handleObjectChange(props);
};

export const zoomIn = (props) => {
  const currentCanvas = props.canvas;
  const activeObject = currentCanvas.getActiveObjects()[0];

  var scaleX = activeObject.scaleX;
  var scaleY = activeObject.scaleY;

  var tempScaleX = scaleX * 1.25;
  var tempScaleY = scaleY * 1.25;

  activeObject.scaleX = tempScaleX;
  activeObject.scaleY = tempScaleY;

  activeObject.setCoords();

  renderAllCanvas(props.allCanvas);
  handleObjectChange(props);
};
export const zoomOut = (props) => {
  const currentCanvas = props.canvas;
  const activeObject = currentCanvas.getActiveObjects()[0];

  var scaleX = activeObject.scaleX;
  var scaleY = activeObject.scaleY;

  var tempScaleX = scaleX * 0.8;
  var tempScaleY = scaleY * 0.8;

  activeObject.scaleX = tempScaleX;
  activeObject.scaleY = tempScaleY;

  activeObject.setCoords();

  renderAllCanvas(props.allCanvas);
  handleObjectChange(props);
};

export const renderAllCanvas = (allCanvas) => {
  const { front, back } = allCanvas;

  front.renderAll();
  back.renderAll();
};

export const recoverDesign = async ({ allCanvas, frontData, backData }) => {
  let { front, back } = allCanvas;
  let OldFrontData = front.toJSON();
  let OldBackData = back.toJSON();

  const backDataNew = { ...OldBackData, ...backData };
  backDataNew['backgroundImage'] = {
    ...OldBackData['backgroundImage'],
    fill: backData['backgroundFillColor'],
  };
  back.loadFromJSON(backDataNew);

  const frontDataNew = { ...OldFrontData, ...frontData };
  frontDataNew['backgroundImage'] = {
    ...OldFrontData['backgroundImage'],
    fill: frontData['backgroundFillColor'],
  };
  front.loadFromJSON(frontDataNew);
};

const getFillForShape = (shapeLine) => {
  if (shapeLine === 'hole_dash') {
    return 'rgb(255,255,255)';
  } else if (shapeLine === 'bleed_dash') {
    return 'red';
  }
};

const clonePromise = (shape) => {
  return new Promise((resolve, reject) => {
    shape.clone((c) => {
      resolve(c);
    });
  });
};
/** 116 */

export const hexToRGB = (hex) => {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return [r, g, b];
};
