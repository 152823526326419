import React, { useState, useEffect } from 'react'
import { getFresheners, saveScents } from '../actions/designActions'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import LoadSpinner from '../components/LoadSpinner'
import { Link } from 'react-router-dom'
import '../css/FreshenerScreen.css'
import {Button, Flex, Input, Select, Table, Tooltip, Typography} from "antd";
import {DeleteFilled} from "@ant-design/icons";
import { useMediaQuery } from 'react-responsive';

function FreshenerScreen({ match, history }) {
	const [scents, setScents] = useState([]);
	const [delivery, setDelivery] = useState('volvo');
	const [country, setCountry] = useState('volvo');
	const designInfo = useSelector(state => state.designInfo)
	const messageInfo = useSelector(state => state.message)
	const id = match.params.id
	const [showTip, setShowTip] = useState({});
	const [totalObj, setTotalObj] = useState({
		quantity: 0,
		total: 0
	});

	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-width: 1070px)'
	})

	const totalAmount = totalObj.total; // 假设这是你的总金额

    // 计算 VAT（20%）
    const vatAmount = (totalAmount * 0.2).toFixed(2);
	const colorList = [{
		"id": 3,
		"title": "White",
		"slug": "white",
		"color": "#ffffff",
		"premium": false,
		"attribute_id": 61
	}, {
		"id": 4,
		"title": "Yellow",
		"slug": "yellow",
		"color": "#eae81a",
		"premium": true,
		"attribute_id": 62
	}, {
		"id": 1,
		"title": "Red",
		"slug": "red",
		"color": "#ff3333",
		"premium": true,
		"attribute_id": 48
	}, {
		"id": 10,
		"title": "Purple",
		"slug": "purple",
		"color": "#6c55aa",
		"premium": true,
		"attribute_id": 73
	}, {
		"id": 8,
		"title": "PInk",
		"slug": "pink",
		"color": "#ff80c0",
		"premium": true,
		"attribute_id": 71
	}, {
		"id": 9,
		"title": "Orange",
		"slug": "orange",
		"color": "#ff8000",
		"premium": true,
		"attribute_id": 72
	}, {
		"id": 7,
		"title": "Green",
		"slug": "green",
		"color": "#008000",
		"premium": true,
		"attribute_id": 64
	}, {
		"id": 6,
		"title": "Brown",
		"slug": "brown",
		"color": "#b66924",
		"premium": true,
		"attribute_id": 65
	}, {
		"id": 2,
		"title": "Blue",
		"slug": "blue",
		"color": "#128fff",
		"premium": true,
		"attribute_id": 49
	}, {
		"id": 5,
		"title": "Black",
		"slug": "black",
		"color": "#000000",
		"premium": true,
		"attribute_id": 63
	}]
	const scentList = [{
		title: 'Baby Powder'
	},
	{
		title: 'Black Raspberry Vanilla'
	},
	{
		title: 'Cherry'
	},
	{
		title: 'Cinnamon'
	},
	{
		title: 'Citrus'
	},
	{
		title: 'Dark Ice'
	},
	{
		title: 'Fresh Air'
	},
	{
		title: 'Guava'
	},
	{
		title: 'Jasmine'
	},
	{
		title: 'Lemon'
	},
	{
		title: 'Fragrance'
	},
	{
		title: 'NuCar'
	},
	{
		title: 'Paris Hilton Replica'
	},
	{
		title: 'Peach'
	},
	{
		title: 'Pina'
	},
	{
		title: 'Strawberry'
	},
	{
		title: 'Sweet Pea'
	},
	{
		title: 'Vanilla'
	},
	]
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(getFresheners(id));
		addScent();
		if(sessionStorage.getItem('refresh')) {
			sessionStorage.setItem('refresh','');
			window.location.reload();
		}
	}, [])

    const linearPricing = (i, start, end, startPrice, endPrice) => {
        return startPrice + (endPrice - startPrice) * ((i - start) / (end - start));
    }

    const getPriceOfNum = (i) => {
        let price;
        if (i >= 1 && i <= 10) {
            price = linearPricing(i, 1, 10, 8.99, 3.99);
        } else if (i >= 11 && i <= 100) {
            price = linearPricing(i, 11, 100, 3.99, 1.5);
        } else if (i >= 101 && i <= 300) {
            price = linearPricing(i, 101, 300, 1.5, 0.75);
        } else if (i >= 301 && i <= 500) {
            price = linearPricing(i, 301, 500, 0.75, 0.45);
        } else if (i >= 501 && i <= 1000) {
            price = linearPricing(i, 501, 1000, 0.45, 0.35);
        } else if (i >= 1001 && i <= 2000) {
            price = linearPricing(i, 1001, 2000, 0.35, 0.3);
        } else if (i >= 2001 && i <= 3000) {
            price = linearPricing(i, 2001, 3000, 0.3, 0.25);
        } else if (i >= 3001 && i <= 5000) {
            price = linearPricing(i, 3001, 5000, 0.25, 0.245);
        } else if (i >= 5001 && i <= 6000) {
            price = linearPricing(i, 5001, 6000, 0.245, 0.24);
        } else if (i >= 6001 && i <= 10000) {
            price = linearPricing(i, 6001, 10000, 0.24, 0.24);
        } else {
            price = 0.24;  // 如果数量大于10000，价格保持在0.24
        }
        return price;
    }



	const addScent = () => {
		let flag = false;
		let newList = [];
		for(let i in scents) {
			let item = scents[i]
			var index = newList.findIndex((item2, index) => {
				return item.scent === item2.scent && item.color === item2.color
			})
			if (index < 0) {
				newList.push(scents[i])
				let showTipObj = {...showTip}
				showTipObj[i] = item.quantity < 3
				setShowTip(showTipObj);
			} else {
				flag = true
				newList[index].quantity = scents[i].quantity*1 + scents[index].quantity*1
			}
			if(item.quantity < 3) {
				flag = true
			}

		}
		if(flag) {
			setScents(newList);
			calcTotal(newList);
			return;
		}
		let obj = {
			scent: scentList[0].title,
			color: 0,
			quantity: 3
		}
		newList.push(obj);
		setScents(newList);
		calcTotal(newList);
	}
	const handleChange = (index, key) => {
		return (value) => {
			let arr = [...scents];
			arr[index][key] = value;
			setScents(arr);
			calcTotal(arr);
		}
	}
	const deleteScent = (index) => {
		return () => {
			if(scents.length <= 1) return;
			let arr = [...scents];
			arr.splice(index, 1);
			setScents(arr);
			calcTotal(arr);
		}
	}
	const calcTotal = (scents) => {
		let Qty = 0;
		let Sum = 0;
		scents.map(item=>{
			Qty+=item.quantity * 1;
			Sum+=(getPriceOfNum(item.quantity) * item.quantity) * 1
		});
		setTotalObj({
			quantity: Qty,
			total: Sum.toFixed(2)
		})
	}
	const startDesign = () => {
		let flag = false;
		let newList = [];

		for(let i in scents) {
			let item = scents[i]
			item.country = country
			item.kuaidi = delivery
			item.Zxs = item.quantity
			item.ziti = item.scent
			var index = newList.findIndex((item2, index) => {

				return item.scent === item2.scent && item.color === item2.color
			})
			if (index < 0) {

				newList.push(scents[i])
				let showTipObj = {...showTip}
				showTipObj[i] = item.quantity < 3
				setShowTip(showTipObj);
			} else {
				newList[index].quantity = scents[i].quantity*1 + scents[index].quantity*1
			}

			if(item.quantity < 3) {
				flag = true
			}

		}
		setScents(newList);
		calcTotal(newList);
		if(flag)  return
		// console.log('1111')
		dispatch(saveScents({
			total: totalObj.quantity,
			price: totalObj.total,
			scents
		}, ()=> {
			history.push('/designerScreen')
		}));
	}

	return (
		<div className="right FreshenerScreen">
			{messageInfo.msg && <Message variant={messageInfo.variant}>{messageInfo.msg}</Message>}
			{designInfo && designInfo.loading && <div className="fullcreen"><LoadSpinner /></div>}
			<div className="maxWidth" style={{ padding: '0 0.5rem' }}>
				<div className="title">Design Your Own Air Freshener</div>
				<div className="flex-between secction">
					<div className="cont">
						Select from the shapes below to start designing your custom air fre shener, or upload your own
						(PNG or JPG) using a template. There are plenty of scents to pick from: scent one, scent two,
						scent three, scent four, scent five, scent six, scent seven, scent eight, scent nine and scent
						ten.
					</div>
					<div className="btn">Request a Sample</div>
				</div>
			</div>

			<div style={{ backgroundColor: ' rgb(232,244,244)' }}>
				<div className="maxWidth" style={{ padding: '0 0.5rem' }}>
					<div className="content">
						<div className="left">
							<div className="left_title">Chosen Shape: {designInfo && designInfo.fresheners && designInfo.fresheners.size}</div>
							{
								isDesktopOrLaptop ? (
									<Table
										columns={[
											{
												title: 'Scent',
												dataIndex: 'scent',
												key: 'scent',
												align: 'center',
												render: (text, item, index) => (
													<Flex>
														<Select
															className="selectLi"
															value={item.scent}
															onChange={handleChange(index, 'scent')}
															options={
																scentList.map((item, i) => ({value: item.title, label: item.title}))
															}
														/>
													</Flex>
												)
											},
											{
												title: 'Color',
												dataIndex: 'color',
												key: 'color',
												align: 'center',
												render: (text, item, index) => (
													<Flex align="center">
														<div className="colorBox" style={{background: colorList[item.color].color}}></div>
														<Select
															className="selectLi"
															value={item.color}
															onChange={handleChange(index, 'color')}
															options={
																colorList.map((item, i) => ({value: i, label: item.title}))
															}
														/>
													</Flex>
												)
											},
											{
												title: 'Quantity',
												dataIndex: 'quantity',
												key: 'quantity',
												align: 'center',
												render: (text, item, index) => (
													<Tooltip title="Minimum order quantity is 3pcs" open={showTip[index]} defaultOpen>
														<Input style={{height: 40}} value={item.quantity} type="number" min="1" onChange={(e) => handleChange(index, 'quantity')(e.target.value)} />
													</Tooltip>
												)
											},
											{
												title: 'Subtotal',
												dataIndex: 'subtotal',
												key: 'subtotal',
												align: 'center',
												render: (text, item, index) => (
													<div style={{color: '#666'}}>£{(getPriceOfNum(item.quantity) * item.quantity).toFixed(2)}</div>
												)
											},
											{
												title: 'Actions',
												dataIndex: 'actions',
												key: 'actions',
												align: 'center',
												render: (text, item, index) => <Button danger type="text" icon={<DeleteFilled />} onClick={deleteScent(index)} />
											},
										]}
										dataSource={scents}
										pagination={false}
									/>
								) : (
									<Flex vertical gap={20}>
										{
											scents.map((item, index) => (
												<Flex key={item.id} vertical>
													<Flex className="cs-list-item" vertical>
														<Typography className="cs-list-item-label">Scent</Typography>
														<Select
															style={{height: 40}}
															value={item.scent}
															onChange={handleChange(index, 'scent')}
															options={
																scentList.map((item, i) => ({value: item.title, label: item.title}))
															}
														/>
													</Flex>
													<Flex className="cs-list-item" vertical>
														<Typography className="cs-list-item-label">Quantity</Typography>
														<Input style={{height: 40}} value={item.quantity} type="number" min="1" onChange={(e) => handleChange(index, 'quantity')(e.target.value)} />
														{
															showTip[index] && <Typography className="cs-list-item-tips">Minimum order quantity is 3pcs</Typography>
														}
													</Flex>
													<Flex className="cs-list-item" vertical>
														<Typography className="cs-list-item-label">Color</Typography>
														<Select
															style={{height: 40}}
															value={item.color}
															onChange={handleChange(index, 'color')}
															options={
																colorList.map((item, i) => ({value: i, label: item.title}))
															}
														/>
													</Flex>
													<Flex className="cs-list-item" justify="flex-end">
														<Button danger icon={<DeleteFilled />} onClick={deleteScent(index)} />
													</Flex>
												</Flex>
											))
										}
									</Flex>
								)
							}
											<div className="tips">

											</div>


											<div className="add" onClick={addScent}>Add a Scent</div>
											<div className="tips">
												<div>Minimum Quantity of 3 per line</div>
												<div>Quantities less than 500 will not be considered in any total quantity raye
									discounts</div>
												<div>*Premium string Additional costs may apply.</div>
											</div>
											<Link to='/shapes' className="backts">
											Back to Shapes
											</Link>
										</div>
							<div className="right">
								<div className="right_title">Product Summary</div>
								<div className="scent">
									<div className="flex-between">
										<div>Quantity:</div>
										<div className="totalQuantity">{totalObj.quantity}</div>
									</div>
								</div>
								<div className="subtotal">
									<div className="flex-between">
										<div>Subtotal:</div>
										<div>£{totalObj.total}</div>
									</div>
									<div className="flex-between">
										<div>Standard Shipping (UK):</div>
										<div>£0.00</div>
									</div>
								</div>
								<div>
									<div className="flex-center product-summary-select">
										<div className="selectName">Country</div>
										<div className="layui-form downPutOne">
											<Select
												style={{height: 40, width: "100%"}}
												value={country ? country : ''}
												onChange={(value) => setCountry(value)}
												options={
													[{ value: "volvo", label: "United Kingdom" }]
												}
											/>
										</div>

									</div>
									<div className="flex-center">
										<div className="selectName">Delivery</div>
										<div className="layui-form downPutOne">
											<Select
												style={{height: 40, width: "100%"}}
												value={delivery ? delivery : ''}
												onChange={(value) => setDelivery(value)}
												options={
													[{ value: "volvo", label: "30 days" }]
												}
											/>
										</div>

									</div>
                                    <div className="flex-between" style={{ marginTop: '0.2rem' }}>
                                        <div>
                                            <div className="total" style={{ fontWeight: 600 }}>Total:</div>
                                            <div className="vat">Including £{vatAmount} in VAT</div>
                                        </div>
                                        <div className="money total-price">£{totalAmount}</div>
                                    </div>
								</div>
								<div className="save sDesign2" onClick={startDesign}>Start Design</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	)
}

export default FreshenerScreen
