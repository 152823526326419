import React from 'react';
import { Link } from 'react-router-dom';
import {EyeOutlined, ShoppingCartOutlined} from "@ant-design/icons";
import {Flex} from "antd";

function ProductList({ products, userInfo, selectAddCart }) {
  return (
    <div className="uls flex-center">
      {products &&
        products.map((item, index) => (
          <div className="ulBox" key={index}>
            <Link to={`/product/${item.id}`}>
              <img src={item.image.split('?')[0]} />
            </Link>
            <div className="money p-title" style={{ justifyContent: 'center' }}>
              {item.name}
            </div>

            <Flex align="center" justify="space-between">
              <div className="money">
                {item.discount !== 1 ? (
                  <div>
                    <del>
                      £{(item.pricemember[userInfo && userInfo.name || 'A']).toFixed(2)}
                    </del>
                    <span style={{ color: '#FFC008', marginLeft: '0.2rem' }}>
                      £{(item.discount * item.pricemember[userInfo && userInfo.name || 'A']).toFixed(
                        2
                      )}
                    </span>
                  </div>
                ) : (
                  <span style={{ color: '#FFC008', marginLeft: '0.2rem' }}>
                    £{(item.pricemember[userInfo && userInfo.name || 'A']).toFixed(2)}
                  </span>
                )}
                {userInfo && userInfo.name && userInfo.name !== 'A' ? (
                  <img
                    src="./images/index/novip.jpg"
                    height="50px"
                    style={{ alignSelf: 'flex-start' }}
                  />
                ) : (
                  ''
                )}
              </div>
              <div className="swiper-btn-wrap flex-center">
                <Link to={`/product/${item.id}`} className="btnLeft flex"><EyeOutlined /></Link>
                <div className="flex btnRight"
                     onClick={selectAddCart(item)}
                >
                  <ShoppingCartOutlined />
                  {/*<img src="./images/index/box2.png" className="btnImg" />*/}
                </div>
              </div>
            </Flex>

            {/*<div className="flex-center">*/}
            {/*  <Link to={`/product/${item.id}`} className="btnLeft flex">*/}
            {/*    View Details*/}
            {/*  </Link>*/}
            {/*  <div className="flex btnRight" onClick={selectAddCart(item)}>*/}
            {/*    <img src="./images/index/box2.png" className="btnImg" />*/}
            {/*    +*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        ))}
    </div>
  );
}

export default ProductList;
