import React, { useState, useEffect } from 'react'
import '../css/DesignerScreen.css'
import { hide } from '../actions/headFootAction'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import DesignImg from '../components/DesignImg'
import { Pixie } from "pixie";
import {addDesignToCart} from '../actions/designActions'
import { addToCart } from '../actions/cartActions'
function DesignerScreen({ history }) {
	const [tab, setTab] = useState(1)
	const [pixie, setPixie] = useState()
	const [imgName, setImgName] = useState('')
	const [background, setBackground] = useState('')
	const [backgrounds, setBackgrounds] = useState(['rgba(0,0,0,0)','rgba(0,0,0,0)'])
	const [imgBase64Front, setImgBase64Front] = useState('')
	const [imgBase64Back, setImgBase64Back] = useState('')
	const [backState, setBackState] = useState()
	const [frontState, setFrontState] = useState()
	const [showPreview, setShowPreview] = useState(true)
	const [show, setShow] = useState(false)
	const [towimgbase64data, setTowimgbase64data] = useState()
	const designInfo = useSelector(state => state.designInfo)
	const messageInfo = useSelector(state => state.message)
	const dispatch = useDispatch()
	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin
	// console.log(designInfo, designInfo.fresheners.image);




	let pixieUrl = 'https://www.autosqueak.com', //配置域名
	cW = 1278,	//初始画布宽高
	cH = 723,

	imgArr = [
	  {
		url: designInfo.fresheners.image,  //配置显示照片
		thumbnail: designInfo.fresheners.image,
	  },
	];

	useEffect(()=>{
		console.log('useEffect1')
		sessionStorage.setItem('refresh',1);
		if (imgName == "") {
			setImgName('Untitled')
		}

	}, [dispatch, designInfo])
	useEffect(() => {
		console.log('useEffect2',imgName)
		if(!imgName || pixie) return;
		let productname = ''


		// if (imgName == "") {
		// 	setImgName('Untitled')
		// }

		const imgObj = new Image()
		imgObj.setAttribute('crossOrigin', 'anonymous');
		imgObj.onload = function (e) {
			//画图
			const canvas = document.createElement('canvas')
			canvas.width = imgObj.width
			canvas.height = imgObj.height

			let ctx = canvas.getContext('2d')
			// console.log(canvas,ctx,789,e)
			ctx.clearRect(0,0,canvas.width, canvas.height)

			ctx.drawImage(imgObj, 0, 0, imgObj.width, imgObj.height);
			const data = canvas.toDataURL('image/png');
			setImgBase64Front(data);
			setImgBase64Back(data);
			let init = ()=> {
				let options = {
					selector: '#container',
					// 初始化画图插件
					watermarkText: 'Pixie Demo',
					baseUrl: "",
					image: data,
					objectDefaults: {
						// global: {
						// 	backgroundColor: 'rgb(4, 147, 114)'
						// },
						transparentCorners: false,
						borderOpacityWhenMoving: 1,
						cornerStyle: 'circle', //rect or circle
						cornerColor: '#ccc',
						cornerStrokeColor: '#fff',
						cornerSize: 16,
						strokeWidth: 0.05,
						lockUniScaling: true, //preserver aspect ratio when resizing via handle
					},
					tools: {
						frame: {
							replaceDefault: true,
						items: [{
							name: 'black',
							mode: 'stretch',
							size: {
								min: 0,
								max: 0,
								default: 0,
							}
						},{
							name: 'oak',
							mode: 'stretch',
							size: {
								min: 0,
								max: 0,
								default: 0,
							}
						},{
							name: 'oak',
							mode: 'stretch',
							size: {
								min: 0,
								max: 0,
								default: 0,
							}
						},{
							name: 'oak',
							mode: 'stretch',
							size: {
								min: 0,
								max: 0,
								default: 0,
							}
						},{
							name: 'oak',
							mode: 'stretch',
							size: {
								min: 0,
								max: 0,
								default: 0,
							}
						},{
							name: 'oak',
							mode: 'stretch',
							size: {
								min: 0,
								max: 0,
								default: 0,
							}
						}]
						},
					  resize: {
						maxWidth: 360,
						maxHeight: 360
					  },
					  export: {
						defaultFormat: 'png', //png, jpeg or json
						defaultName: 'image', //default name for downloaded photo file
						defaultQuality: 1, //works with jpeg only, 0 to 1
					  },
					  background: {
						replaceDefault: true,
						items: [
							{
								name: 'red'
							},{
								name: 'black'
							}
						]
					  },
					  stickers: {
						replaceDefault: true,
						items: [
						  {
							name: 'emoticons',
							list: ['happy', 'sad', 'angry'],
							type: 'svg',
							thumbnailUrl: 'images/stickers/emoticons/happy.svg'
						  },
						  {
							name: 'xin',
							list: ['ma', 'niu'],
							type: 'svg',
							thumbnailUrl: 'images/stickers/xin/niu.svg'
						  },
						  {
							name: 'socialmedia',
							list: ['Facebook', 'Instagram1', 'Instagram2', 'Instagram3', 'google', 'google2', 'Linkedin', 'Snapchat'],
							type: 'svg',
							thumbnailUrl: 'images/stickers/socialmedia/Facebook.svg'
						  },
						  {
							name: 'flags',
							list: ['England', 'Kurdistan', 'Albania', 'France', 'Germany','Italy'],
							type: 'svg',
							thumbnailUrl: 'images/stickers/flags/England.svg'
						  },
						  {
							name: 'text',
							list: ['Open 7 days', 'Thanks for your custom', 'Find us on', 'Full Valets', 'Mini Valets','9AM-6PM', 'Buffing polish', 'Your local car wash', 'Your valeting specialist', 'warning1','warning2'],
							type: 'svg',
							thumbnailUrl: 'images/stickers/text/phrase.svg'
						  },
						  {
							name: 'icon',
							list: ['email1', 'email2', 'home1', 'home2', 'phone1', 'phone2', 'phone3'],
							type: 'svg',
							thumbnailUrl: 'images/stickers/icon/home1.svg'
						  },
						]
					  },
						zoom: {
							fitImageToScreen: true,
						}
					},
					ui: {
						menubar: {
							replaceDefaultItems: true,
							items: [
								{
									type: 'button',
          							label: imgName  ,
									  icon: [
										{
										  tag: 'path',
										  class: 'img-name-box'
										},
									  ],

									align: 'left',
									action: function(e) {
										let btn = document.querySelector('.pixie-root>div>.mr-auto button:first-of-type');
										let ipt = document.getElementById('img-name-input');
										if(ipt ) {
											ipt.remove();
											btn.innerHTML = ipt.value
											if(btn.parentElement) btn.parentElement.classList.remove('has-input');
											setImgName(ipt.value);
										}else if(btn) {
											let ipt = document.createElement('input');
											ipt.id = 'img-name-input';
											ipt.value = imgName
											btn.parentElement.insertBefore(ipt,btn);
											btn.parentElement.classList.add('has-input');
											btn.innerHTML = 'update'
										}
										// console.log(e, document.querySelector('.ml-auto button:first-of-type'))
									}

								  },
								  {
									type: 'button',
									icon: [
									  {
										tag: 'path',
										attr: {
										  d: 'M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z',
										},
									  },
									],
									align: 'left',
									action: editor => {
										editor.tools.import.uploadAndAddImage();
									  },
								  } ,{
									type: "zoomWidget",
									align: "center",
									// desktopOnly: true
								  },
								  {
									type: "undoWidget",
									align: "right"
								  },
							],
						  },
						// allowZoom: true,
						// replaceDefaultitems: true,

					// 	toolbar: {
					// 	  replaceDefaultLeftItems: true,
					// 	  replaceDefaultCenterItems: true,
					// 	  replaceDefaultRightItems: true,
					// 	  leftItems: [
					// 		{
					// 			type: 'zoomWidget',
					// 			showInCompactMode: true,
					// 			condition: {'tools.zoom.allowUserZoom': true},
					// 		  },

					// 	  ],
					// 	  centerItems: [
					// 		{
					// 		  type: 'zoomWidget',
					// 		  showInCompactMode: true,
					// 		  condition: {'tools.zoom.allowUserZoom': true},
					// 		},
					// 		// 添加一个按钮用来保存图片数据，用以上传到接口
					// 		//{
					// 		//    type: 'button',
					// 		//    text: '居中',
					// 		//    action: function (e) {
					// 		//        console.log(e)
					// 		//console.log(pixie.get('activeObject').get())
					// 		//        pixie.get('activeObject').get().center();
					// 		//    }
					// 		//},
					// 		//{
					// 		//    type: 'button',
					// 		//    text: '应用变更',
					// 		//    action: function () {
					// 		//        yybg(pixie)
					// 		//    }
					// 		//},

					// 	  ],
					// 	  rightItems: [
					// 		{
					// 		  type: 'button',
					// 		  icon: 'undo',
					// 		  action: function () {
					// 			  console.log(111)
					// 			//pixie.getTool('history').get('item_f')
					// 			pixie.getTool('history').undo()
					// 		  }
					// 		},
					// 		{
					// 		  type: 'button',
					// 		  icon: 'redo',
					// 		  action: function () {
					// 			console.log(22)
					// 			pixie.getTool('history').redo()
					// 		  }
					// 		},

					// 		{
					// 		  type: 'button',
					// 		  text: 'Preview',
					// 		  icon: 'eye-fill',
					// 		  showInCompactMode: true,
					// 		  action: function () {
					// 			// yybg(pixie)
					// 			// if (imgBase64Front == "") {
					// 			//   imgBase64Front = kb
					// 			// }
					// 			// if (imgBase64Back == "") {
					// 			//   imgBase64Back = kb
					// 			// }
					// 			// drawAndShareImage('testdiv')
					// 			// $('#preview-wrap').show();
					// 		  }
					// 		},

					// 	  ]
					//   },

					  nav: {
						position: 'top',
						replaceDefault: true,
						items: [
						  {name: 'text', icon: 'images/text-box-custom.svg', action: 'text'},
						  {
							name: 'add-image', icon: 'images/image-fill.svg', action: editor=>{
								editor.tools.import.uploadAndAddImage();
							}
						  },
						  {name: 'background', icon: 'images/background-custom.svg', action: 'background',
						  action:  (editor)=> {
							  const nav = document.querySelector('.pixie-root>.z-navbar');
							  const beforeBox = document.querySelector('.pixie-root>.z-navbar>div');
							  const afterBox = document.querySelector('.colors-operate-box')
							  beforeBox.style.display = 'none';
							  afterBox.style.display = 'flex';
							  nav.appendChild(afterBox);
							//   const btns = colorBox.querySelectorAll('.color');

							//   let bgColor = background
							//   console.log(pixie,99,backState);
							//   for(let i=0;i<btns.length;i++) {
							// 	  btns[i].onclick = toggle(tab,  btns[i].style.background, editor)

							//   }
							//   cancelBtn.onclick = ()=> {
							// 	beforeBox.style.display = 'block';
							// 	afterBox.remove();
							// 	toggle(tab, background)()
							//   }
							//   applyBtn.onclick = ()=> {
							// 	beforeBox.style.display = 'block';
							// 	afterBox.remove();
							// 	toggle(tab, bgColor)()
							//   }

							}
						  },
						  {name: 'stickers', icon: 'images/sticker-custom.svg', action: 'stickers'},
						  {name: 'merge', icon: 'images/merge-custom.svg', action: 'merge'},
						  {
							name: 'Duplicate to Other Side', icon: 'images/bag-push.svg', action: function () {

							}
						  },
						]
					  },
					  openImageDialog: {
						colorPresets: {

							items: [
							  'rgb(0,0,0)',
							  'rgb(255, 255, 255)',
							  'rgb(242, 38, 19)',
							  'rgb(249, 105, 14)',
							  'rgb(253, 227, 167)',
							  'rgb(4, 147, 114)',
							  'rgb(30, 139, 195)',
							  'rgb(142, 68, 173)',
							],
						  },
					  },

					},


					onLoad: function () {



					},


					onMainImageLoaded: function () {

						//let resizeTool = pixie.getTool('resize');
						//resizeTool.apply(cW, cH, false);
					  //console.log(cW, cH);
					}
				}
				Pixie.init(options).then(instance=>{
					setPixie(instance);
					if(instance) {
						let stateData = instance.getState()
						// console.log(stateData,'stateData')

						// console.log(localStorage.getItem('reOrder'));

						const reOrderStr = localStorage.getItem('reOrder');
						if(reOrderStr) {
							const reOrder = JSON.parse(localStorage.getItem('reOrder'));
							if(reOrder.product) {
								if(reOrder.product.state1) {
									setFrontState(JSON.parse(reOrder.product.state1))
									instance.tools.import.loadState(reOrder.product.state1);
								}
								if(reOrder.product.state2) setBackState(JSON.parse(reOrder.product.state2));
							}
						}else if(stateData) {
							setBackState(JSON.parse(JSON.stringify(stateData)))
							setFrontState(JSON.parse(JSON.stringify(stateData)))
						}
					}
					instance.setConfig({ tools: { zoom: { fitImageToScreen: false } } })
				})
			}
			init('#container')
		}
		imgObj.src = designInfo.fresheners.image
		dispatch(hide())
	}, [dispatch, imgName])

	////两图合并



	const drawAndShareImage = (e) => {
		if(pixie) {
			// console.log(pixie,88889)
			if(tab == 1) {
				setImgBase64Front(pixie.tools.export.getDataUrl())

			}else {
				setImgBase64Back(pixie.tools.export.getDataUrl())
			}
		}
		let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        canvas.width = 800; // 400
        canvas.height = 300; // 150

        context.rect(0, 0, canvas.width, canvas.height);
        context.fillStyle = "#fff";
        context.fill();

        let frontImage = new Image();
        frontImage.src = (tab == 1)? pixie.tools.export.getDataUrl(): imgBase64Front;
        frontImage.crossOrigin = 'Anonymous';
        frontImage.onload = function () {
          let w = 400, h = 400 * frontImage.height / frontImage.width
          let y = (300 - h) / 2
          context.drawImage(frontImage, 0, y, w, h);

        }

        let backImage = new Image();
        backImage.src =(tab != 1)? pixie.tools.export.getDataUrl(): imgBase64Back;   //你自己本地的图片或者在线图片
        backImage.crossOrigin = 'Anonymous';
        backImage.onload = function () {
          let w = 400, h = 400 * backImage.height / backImage.width
          let y = (300 - h) / 2
          context.drawImage(backImage, 400, y, w, h);

          let base64 = canvas.toDataURL("image/png");
		  setTowimgbase64data(base64)
		  setShow(true)
        }
	}

	// 上传数据的函数
	const upload = () => {
        if (imgBase64Front === '' || imgBase64Back === '') {
          //document.getElementById('bt-shop').innerHTML = '请确保正反面都应用了变更'
          return false;
		}
		return true
	}
	const cancelBubble = () => {
		window.event.cancelBubble = true
	}
	const toggle = function (num, bgColor){
		return (e) => {
			setBackground(bgColor)
			if(e) e.stopPropagation();
			if(tab == num && !bgColor) return;
			setTab(num)
			if(num == 1) {
				if(pixie) {
					let backState = pixie.getState()
					if(!bgColor) {
						setImgBase64Back(pixie.tools.export.getDataUrl())
						if(backState) setBackState(JSON.parse(JSON.stringify(backState)))

					}

					if(frontState) {
						if(bgColor)  {
							let frontState = pixie.getState()
							let options = typeof frontState=== 'string' ? JSON.parse(frontState) : JSON.parse(JSON.stringify(frontState));
							options.canvas.objects[0].backgroundColor = bgColor
							pixie.tools.import.loadState(JSON.stringify(options))
							setFrontState(JSON.stringify(options));
						}else {
							pixie.tools.import.loadState(frontState)
						}

					}

				}
			}else {
				if(pixie) {
					let frontState = pixie.getState()
					if(!bgColor) {
						setImgBase64Front(pixie.tools.export.getDataUrl());
						if(frontState) setFrontState(JSON.parse(JSON.stringify(frontState)))
					}
					if(backState) {
						if(bgColor)  {
							let backState = pixie.getState()
							let options = typeof backState=== 'string' ? JSON.parse(backState) : JSON.parse(JSON.stringify(backState));

							options.canvas.objects[0].backgroundColor = bgColor
							pixie.tools.import.loadState(JSON.stringify(options))
							setBackState(JSON.stringify(options));
						}else {
							pixie.tools.import.loadState(backState)
						}

					}


				}
			}
		}
	}

	const AddBasket = (e) => {
		e.preventDefault();
		// console.log(upload(),99)
		if(upload()){
			//两图合并
			drawAndShareImage()

		}
	}
	const AddCart = e=>{
		if(upload()){
			// console.log(designInfo,9999)
			// let pricemember=JSON.parse(designInfo.fresheners.pricemember)
			//上传数据的函数后
			dispatch(addDesignToCart(  {
				name: imgName,
				imgFront: imgBase64Front,
				imgBack: imgBase64Back,
				state1: frontState,
				state2: backState,
				jsondata: JSON.stringify(designInfo.scents),
				price: designInfo.price,
				total: designInfo.total,
				quality: 1,
				pk: designInfo.fresheners.id,
				imgdata: towimgbase64data
			},(productId)=>{

				dispatch(addToCart({

					...designInfo.fresheners,
					name: imgName,
					id: productId,
					price: designInfo.price,
					scentTotal: designInfo.total,
					image: towimgbase64data,
				},  1, designInfo.fresheners.color, designInfo.fresheners.combo, false));

				history.push('/cart')
				// dispatch(addToCart(item2, qty, color, combo));
			}))
			// addDesignToCart()


		}
	}
	const cancel = e=>{

		const beforeBox = document.querySelector('.pixie-root>.z-navbar>div');
		const afterBox = document.querySelector('.colors-operate-box')
		beforeBox.style.display = 'block';
		afterBox.style.display = 'none';


	}
	const cancelBg = e=>{
		cancel()
		toggle(tab,backgrounds[tab-1])();

	}
	const applyBg = e=>{
		cancel();
		let bg = [...backgrounds]
		bg[tab-1] = background
		setBackgrounds(bg)
		// toggle(tab,background)();

	}

	return (
		<div className="DesignerScreen">
			 {show?<DesignImg noFun={()=>{setShow(false)}} okFun={AddCart} ><div style={{fontSize: '0.36rem'}}>ADD TO CART</div>< img style={{maxWidth: '100%'}} src={towimgbase64data} /></DesignImg> : ''}
			<div id="preview-wrap" onClick={toggle(1)} style={{ display: showPreview ? 'none' : 'block' }}>
				<div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<div onClick={cancelBubble}
						style={{ background: '#fff', width: '800px', boxShadow: '0 5px 15px rgb(0 0 0 / 50%)' }}>
						<div style={{ display: 'line-block', fontSize: '36px', color: '#9f9f9f', padding: '16px 0', textAlign: 'center' }}>
							Note: Please click apply after operation.

						</div>
						<img id="testdiv" src="./images/design/fgh.gif" style={{ width: '100%', height: '50vh' }} />
						<div style={{ clear: 'both' }}></div>
						<div style={{ borderTop: '1px solid #e5e5e5', padding: '15px', display: 'flex', justifyContent: 'space-between' }}>
							<div style={{ fontSize: '14px' }}></div>
							<div className="save-btn" onClick={()=>setShowPreview(false)}>Back to Design</div>

						</div>
					</div>
				</div>
			</div>
			<div className="preview__header" data-view="ctaHeader" data-item-id="10721475" style={{ position: 'fixed', width: '100%', top: 0 }}>
				<div id="js-preview__actions" className="preview__actions">
					<div className="preview__action--buy">
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
							<a href="#"><div className="logo"></div></a>
							<div className="toggle-wrap">
								<div style={{ background: '#FFC008' }} onClick={toggle(1)} id="front">Front Design</div>
								<div style={{ background: '#87CAD2' }} onClick={toggle(2)} id="rear">Rear Design</div>
							</div>

							<a className="header-buy-now e-btn--3d -color-primary" onClick={AddBasket} id="endbtn">Add To Basket</a>
							<img id="end1btn" src="" className="header-buy-now e-btn--3d -color-primary" width="90" height="40" style={{ display: 'none' }} />
						</div>

					</div>
				</div>
			</div>
			<div  style={{position: 'relative', marginTop: '54px', height: 'calc(100vh - 54px)' }} >

				<div id="container" style={{  height: 'calc(100vh - 54px)' }} />
				{/* <input style={{ border: '1px solid #eee', position: 'absolute', top: 0,left: 0}}  value={imgName} onInput={e=>setImgName(e.target.value)} /> */}
			</div>
			<div className="colors-operate-box" style={{display: 'none'}}>
				<button onClick={cancelBg}  className="focus-visible:ring bg-transparent border hover:bg-hover disabled:text-disabled disabled:bg-transparent disabled:border-disabled-bg whitespace-nowrap align-middle inline-flex flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-full justify-center font-semibold text-xs h-30 px-14 min-w-92">Cancel</button>
				<div className="colors">
							  	<button type="button" mat-icon-button="" className="mat-focus-indicator color-picker-button mat-icon-button mat-button-base">
									  <span class="mat-button-wrapper">
										  <svg>
										<path d="M24.64 4C16.04 4 6.21 8.953 6.21 22.945 6.21 33.11 13.845 44 25.52 44h.175c4.383-.078 9.106-2.918 9.106-8.96 0-1.157-.434-2.157-.813-3.04a15.217 15.217 0 0 1-.285-.695c-.863-2.176.086-2.97 2.328-4.567 2.535-1.804 6.012-4.277 5.969-10.816C42 12.032 37.758 4 24.64 4zm.56 35.2c-2.243 0-4-1.759-4-4 0-2.24 1.757-4 4-4a3.96 3.96 0 0 1 3.995 4c0 2.241-1.758 4-3.996 4z" fill="#ffc107"></path>
										<path d="M38 16.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 1 1 7 0z" fill="#9c27b0"></path>
										<path d="M29 10.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 1 1 7 0z" fill="#2196f3"></path>
										<path d="M19 15.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 1 1 7 0z" fill="#4caf50"></path>
										<path d="M18 26.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 1 1 7 0z" fill="#ff3d00"></path>
						  </svg></span>
						  <span matripple="" className="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
						  <span class="mat-button-focus-overlay"></span>
						  </button>
						  <button onClick={toggle(tab,'rgb(0, 0, 0)')} type="button" className="no-style color control ng-star-inserted" style={{background: 'rgb(0, 0, 0)'}}></button>
						  <button onClick={toggle(tab,'rgb(255, 255, 255)')} type="button" class="no-style color control ng-star-inserted" style={{background: 'rgb(255, 255, 255)'}}></button>
						  <button onClick={toggle(tab,'rgb(242, 38, 19)')} type="button" className="no-style color control ng-star-inserted" style={{background: 'rgb(242, 38, 19)'}}></button>
						  <button onClick={toggle(tab,'rgb(249, 105, 14)')} type="button" class="no-style color control ng-star-inserted" style={{background: 'rgb(249, 105, 14)'}}></button>
						  <button onClick={toggle(tab,'rgb(253, 227, 167)')} type="button" className="no-style color control ng-star-inserted" style={{background: 'rgb(253, 227, 167)'}}></button>
						  <button onClick={toggle(tab,'rgb(4, 147, 114)')} type="button" class="no-style color control ng-star-inserted" style={{background: 'rgb(4, 147, 114)'}}></button>
						  <button onClick={toggle(tab,'rgb(30, 139, 195)')} type="button" className="no-style color control ng-star-inserted" style={{background: 'rgb(30, 139, 195)'}}></button>
						  <button onClick={toggle(tab,'rgb(142, 68, 173)')} type="button" class="no-style color control ng-star-inserted" style={{background: 'rgb(142, 68, 173)'}}></button>

						  </div>
				<button  onClick={applyBg}  className="focus-visible:ring text-on-primary bg-primary border border-primary hover:bg-primary-dark hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none whitespace-nowrap align-middle inline-flex flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-full justify-center font-semibold text-xs h-30 px-14">Apply</button>
			</div>
		</div>
	)
}

export default DesignerScreen
