import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadSpinner from '../components/LoadSpinner';
import Message from '../components/Message';
import SelectItem from '../components/SelectItem';
import '../css/HomeScreen.css';
import 'swiper/swiper-bundle.min.css';
import { listNewProducts } from '../actions/productActions';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Select, Pagination } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useReactive } from 'ahooks';

const imgs = {
  摩托车: './images/index/bike.png',
  汽车配件: './images/index/carparts.png'
};

const null_imgs = {
  230140297: './images/index/null1.png',
  230140299: './images/index/null2.png',
  230140295: './images/index/null3.png',
  230140294: './images/index/null4.png',
  230140296: './images/index/null4.png',
  230140298: './images/index/null5.png'
};

function StoreScreenNew({ match }) {
  const dispatch = useDispatch();

  const productsInfo = useSelector((state) => state.productNewList);

  const { loading, products } = productsInfo || { loading: false, products: {} };

  const messageInfo = useSelector((state) => state.message);

  const params = useParams();

  useEffect(() => {
    dispatch(
      listNewProducts({
        keyword: params.key || '',
        productCollectionId: params.plid || '',
      }),
    );
  }, [dispatch, params.key, params.plid]);

  const getList = () => {
    if (products?.data) {
      state.list = products?.data || [];
      console.log('state.list - >:', state.list);
    }
  };

  useEffect(() => {
    getList();
  }, [products?.data]);

  const state = useReactive({
    nowItem: null,
    show: false,
    sortBy: 'name_asc',
    list: [],
  });

  const noFun = () => {
    state.nowItem = {};
    state.show = false;
  };

  const handleSortChange = (value) => {
    state.sortBy = value;

    if (state.sortBy === 'name_asc') {
      state.list.sort((a, b) => a.subjectTrans.localeCompare(b.subjectTrans));
      return;
    }

    if (state.sortBy === 'name_desc') {
      state.list.sort((a, b) => b.subjectTrans.localeCompare(a.subjectTrans));
      return;
    }

    if (state.sortBy === 'price_asc') {
      state.list.sort((a, b) => parseFloat(a.priceInfo.price) - parseFloat(b.priceInfo.price));
      return;
    }

    if (state.sortBy === 'price_desc') {
      state.list.sort((a, b) => parseFloat(b.priceInfo.price) - parseFloat(a.priceInfo.price));
      return;
    }
  };

  // 截断函数
  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + '...';
  };

  return (
    <div className="home">
      {state.show && (
        <SelectItem
          item={state.nowItem}
          noFun={noFun}
        />
      )}

      {messageInfo.msg && <Message variant={messageInfo.variant}>{messageInfo.msg}</Message>}

      {loading ? (
        <div className="fullscreen">
          <LoadSpinner />
        </div>
      ) : (
        !messageInfo.msg && (
          <div>
            <div className="maxWidth">
                <div
                  className="imgs"
                  style={{
                    height: '3.7rem',
                    background: `url(${
                      params.key && imgs[params.key]
                        ? imgs[params.key]
                        : params.plid && null_imgs[params.plid]
                          ? null_imgs[params.plid]
                          : './images/index/cardefault.png'
                    }) no-repeat center center/cover`,
                    marginBottom: '0.6rem',
                  }}
                ></div>

              <Row className="uls flex-center">
                {state.list.map((item) => (
                  <Col
                    className="ulBox"
                    key={item.offerId}
                    xs={8} // 在移动设备上每行显示一个产品
                    sm={8} // 在小型设备上每行显示一个产品，剩余空间
                    md={6} // 在中型设备及以上每行显示两个产品
                  >
                    <Link to={`/productnew/${params.plid}/${params.key}/${item.offerId}`}>
                      <img
                        src={item.imageUrl}
                        alt={item.subjectTrans}
                        style={{ width: '100%' }}
                      />
                    </Link>
                    <div className="money p-title-multiline" style={{ justifyContent: 'center' }}>
                      {truncateText(item.subjectTrans, 50)} {/* 50 是你希望显示的最大字符数 */}
                    </div>
                    <Row align="middle" justify="space-between">
                      <div className="money">
                        <span style={{ color: '#FFC008', marginLeft: '0.2rem' }}>
                          £{((parseFloat(item.priceInfo.price) / 9) * 1.5).toFixed(2)}
                        </span>
                      </div>
                      <div className="swiper-btn-wrap flex-center">
                        <Link
                          to={`/productnew/${params.plid}/${params.key}/${item.offerId}`}
                          className="btnLeft flex"
                        >
                          <EyeOutlined />
                        </Link>
                      </div>
                    </Row>
                  </Col>
                ))}
              </Row>
              <br />
              <div className="flex-center">
                <Pagination
                  current={products.currentPage}
                  total={products.totalRecords}
                  pageSize={products.pageSize}
                  onChange={(page, pageSize) => {
                    dispatch(
                      listNewProducts({
                        keyword: params.key || '',
                        productCollectionId: params.plid || '',
                        page: page,
                        pageSize: pageSize,
                      }),
                    );
                  }}
                />
              </div>
              <br />
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default StoreScreenNew;