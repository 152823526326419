import React,{useState} from 'react'
function DesignImg({children, noFun, okFun}) {

    return (
        <div className="f-loading" >
            <div className="DesignImg" style={{position: 'relative',background: '#fff',width: '8rem','box-shadow':' 0 0.05rem 0.15rem rgb(0 0 0 / 50%)'}}>
                <img onClick={noFun} style={{position: 'absolute', right: '0.1rem', top: '0.1rem', cursor: 'pointer', width: '0.3rem'}}   src="./images/index/errs.png" />  
                <div style={{marginTop: '0.4rem'}}>{children}  </div>                
                                        
                <div  className="flex " ><div onClick={noFun} className=" basket-btn" style={{width: '1.76rem', 'margin-right': '0.5rem'}}>Return to Design</div> 
                <div className=" basket-btn no" onClick={ okFun}  style={{width: '1.76rem'}}>Continue Add To Cart</div> </div>
            </div>
        </div>
    )
}

export default DesignImg
