import React, { useState, useEffect } from 'react';
import LoadSpinner from '../components/LoadSpinner';
import Message from '../components/Message';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Navigation } from 'swiper';
import { useDispatch, useSelector } from 'react-redux';
import { newProductDetails, listProducts } from '../actions/productActions';
import { Link, useParams } from 'react-router-dom';
import Confirm from '../components/Confirm';
import SelectItem from '../components/SelectItem';
import '../css/ProductScreen.css';
import { Flex } from 'antd';
import { EyeOutlined, ShoppingCartOutlined } from '@ant-design/icons';

SwiperCore.use([Navigation]);

function ProductScreenNew({ match, history }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const params = useParams();

  const [image, setImage] = useState('');
  const [tip, setTip] = useState('');
  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.productDetails);
  const { error, loading, product } = productDetails;
  const productList = useSelector((state) => state.productList);
  const { loading2, products } = productList;
  const [slidesPerView, setSlidesPerView] = useState(4);
  const [nowItem, setNowItem] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(
      newProductDetails({
        offerId: params.id,
        keyword: params.key,
        productCollectionId: params.plid,
        beginPage: 1,
        pageSize: 10,
        country: 'en',
      }),
    );

    dispatch(listProducts(''));

    resize();
    window.onresize = resize;
  }, [dispatch]);

  const resize = () => {
    let w = window.innerWidth * 1;
    if (w < 540) {
      setSlidesPerView(1);
    } else if (w < 640) {
      setSlidesPerView(2);
    } else if (w < 935) {
      setSlidesPerView(3);
    } else {
      setSlidesPerView(4);
    }
  };

  const cleanImageUrl = (url = '') => {
    const index = url.indexOf('?');
    if (index > -1) {
      return url.substring(0, index);
    }
    return url;
  };

  const handleImg = (src) => {
    setImage(src);
  };

  const selectAddCart = (item, index) => {
    return () => {
      setNowItem(item);
      setShow(true);
    };
  };

  const noFun = () => {
    setNowItem({});
    setShow(false);
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="product">
      {show && (
        <SelectItem
          item={nowItem}
          noFun={noFun}
        />
      )}

      {tip && (
        <Confirm
          okFun={() => setTip('')}
          tip={tip}
          confirmText="OK"
        />
      )}

      {loading || loading2 ? (
        <div className="fullcreen">
          <LoadSpinner />
        </div>
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
          <div className="maxWidth">
            <div className="mob-title">
              <div className="product-name">{product?.subjectTrans}</div>
              <div className="flex-center">
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={goBack}
                >
                  <div className="new-btn">GO BACK</div>
                </a>
              </div>
            </div>

            <div className="top-wrap">
              <div className="left">
                <div className="scroll-content">
                  <div className="scroll-content-wrap">
                    <div className="left-swiper-container">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <div className="small-img">
                            <img
                              onClick={() => handleImg(product?.imageUrl)}
                              src={product?.imageUrl}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="swiper-scrollbar"></div>
                    </div>
                  </div>
                </div>
                <div className="pro-img-box">
                  <div className="product-img">
                    <img
                      className="main-img"
                      src={product?.imageUrl}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="left-mob">
                <div className="product-img">
                  <img
                    className="main-img"
                    src={product?.imageUrl}
                    alt=""
                  />
                </div>
                <div className="scroll-imgs">
                  <div className="small-img">
                    <img
                      onClick={() => handleImg(product?.imageUrl)}
                      src={product?.imageUrl}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="product-name">{product?.subjectTrans}</div>
                <div className="black">
                  <div className="goods-desc">
                    <div>{product?.subjectTrans}</div>
                  </div>
                  <Flex
                    align="center"
                    justify="space-between"
                  >
                    <div className="money">
                      <span style={{ color: '#FFC008', marginLeft: '0.2rem' }}>
                        £{((parseFloat(product?.priceInfo?.price) / 9) * 1.5).toFixed(2)}
                      </span>
                    </div>
                  </Flex>
                </div>
              </div>
            </div>
            <div
              className="relative"
              style={{ marginBottom: 100 }}
            >
              <div className="also-like">You May Also Like...</div>
              <Swiper
                direction={'horizontal'}
                loop={true}
                slidesPerView={slidesPerView}
                spaceBetween="-3%"
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                  disabledClass: 'disable',
                }}
              >
                {products &&
                  products.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div style={{ marginLeft: '15%' }}>
                          <Link
                            to={'/product/' + item.id}
                            className="swiperImg"
                          >
                            <div className="swiperImg">
                              <img src={item.image} />
                            </div>
                          </Link>
                          <div
                            className="swiper-name"
                            title={item.name}
                          >
                            {item.name}
                          </div>
                          <Flex
                            align="center"
                            justify="space-between"
                          >
                            <div className="swiper-price">
                              {item.discount !== 1 ? (
                                <div>
                                  <del>
                                    £
                                    {item.pricemember[(userInfo && userInfo.name) || 'A'].toFixed(
                                      2,
                                    )}
                                  </del>
                                  <span style={{ color: '#FFC008' }}>
                                    £
                                    {(
                                      item.discount *
                                      item.pricemember[(userInfo && userInfo.name) || 'A']
                                    ).toFixed(2)}
                                  </span>
                                </div>
                              ) : (
                                <span style={{ color: '#FFC008' }}>
                                  £{item.pricemember[(userInfo && userInfo.name) || 'A'].toFixed(2)}
                                </span>
                              )}
                            </div>
                            <div className="swiper-btn-wrap flex-center">
                              <Link
                                to={'/product/' + item.id}
                                className="btnLeft flex"
                              >
                                <EyeOutlined />
                              </Link>
                              <div
                                className="flex btnRight"
                                onClick={selectAddCart(item)}
                              >
                                <ShoppingCartOutlined />
                              </div>
                            </div>
                          </Flex>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
              <div className="swiper-button-prev swiper-button">
                <img
                  src="./images/index/left.png"
                  alt=""
                />
              </div>
              <div className="swiper-button-next swiper-button">
                <img
                  src="./images/index/right.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductScreenNew;
