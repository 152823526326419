import React from 'react';

class PDFDownloader extends React.Component {
  downloadPDF = () => {
      let {id} = this.props
      console.log('%c 开始测试 ', 'color:#fff; background:#00897b ')
      console.log(id)
      const requestData = {
      userorder_id: id,
    };

    fetch('https://www.butt-and-co.co.uk/download-pdf/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then(response => response.blob())
      .then(blob => {
        // Create a download link for the received PDF blob
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'downloaded_pdf.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  render() {

    return (
      <div>
        <button className="basket-btn" onClick={this.downloadPDF}>Invoice</button>
      </div>
    );
  }
}

export default PDFDownloader;
