import { FloatButton } from 'antd';
import {useMediaQuery} from "react-responsive";

function BackTop() {

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  return (
    <FloatButton.Group shape="circle" style={{ right: isMobile ? 40 : 80 }}>
      <FloatButton.BackTop visibilityHeight={500} />
    </FloatButton.Group>
  )
}

export default BackTop;
