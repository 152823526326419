import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import { getShapes } from '../actions/designActions';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import { Link } from 'react-router-dom';
import LoadSpinner from '../components/LoadSpinner';
import '../css/ShapesScreen.css';
function ShapesScreen({ history }) {
	const designInfo = useSelector(state => state.designInfo)
	const messageInfo = useSelector(state => state.message)
	// console.log(designInfo.shapes,8888);
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(getShapes());
	}, [dispatch])


	return (
		<div className="right ShapesScreen">
            <Helmet>
                <title>Custom Printed Car Air Fresheners - Your Logo, Any Shape, Multiple Fragrances</title>
                <meta name="description" content="Car Air Fresheners with Your Logo or Design. Speedy Turnaround. Bulk Discounts Available. Custom Printed Car Air Fresheners from 13.5p. Any Shape. 22+ Fragrances. No Set Up Cost. Make Any Shape You Want! Wide Variety of Fragrances"/>
                {/* Open Graph Data */}
                <meta property="og:title" content="My Designs - Custom Air Fresheners" />
                <meta property="og:description" content="Browse and reorder my custom air freshener designs." />
                <meta property="og:image" content="https://auto-clean.s3.eu-west-2.amazonaws.com/dingzhi_xjGjVn5.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4SOHMCJQR3PC67ND%2F20240111%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240111T043020Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=c9b77493f67d041b4a31c69c815f872d0fe1c4a42b86997a074e05a50bb2bfcb" />
                <meta property="og:url" content="https://www.autosqueak.com/#/shapes" />
                <meta property="og:type" content="website" />
                {/* Twitter Card Data */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="My Designs - Custom Air Fresheners" />
                <meta name="twitter:description" content="Browse and reorder my custom air freshener designs." />
                <meta name="twitter:image" content="https://auto-clean.s3.eu-west-2.amazonaws.com/dingzhi_xjGjVn5.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4SOHMCJQR3PC67ND%2F20240111%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240111T043020Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=c9b77493f67d041b4a31c69c815f872d0fe1c4a42b86997a074e05a50bb2bfcb" />
            </Helmet>
			{messageInfo.msg && <Message variant={messageInfo.variant}>{messageInfo.msg}</Message>}
			{designInfo && designInfo.loading&& <div className="fullcreen"><LoadSpinner /></div> }
			<div className="maxWidth" style={{padding: '0 0.5rem'}}>
				<div className="title">Design Your Own Air Freshener</div>
				<div className="flex-between secction">
					<div className="cont">
						Creating a custom air freshener is a great way to promote your brand and create a pleasant atmosphere for your customers. To start, choose a shape that represents your brand, select a scent from the options provided or create a custom scent, and then design your air freshener using a pre-made template or your own artwork. Remember to include your company name, logo, or other branding elements to help customers easily recognize your brand.
					</div>
					<div className="btn">Request a Sample</div>
				</div>
			</div>
			<div className="car">
				<div className="maxWidth">
					<div className="name">We use high quality masterials for the best results.</div>
				</div>
			</div>
			<div className="maxWidth">
				<div className="uls flex-center">
					{
						designInfo.shapes&&designInfo.shapes.map((item,i)=>{
							return (
								<div className="ulBox" key={i}>
									<img src={item.image.split('?')[0]} style={{width: "100%"}}/>
									<div className="money">{item.name} Starts from... £{item.price * item.discount}</div>
									{/* <div className="flex btnRight" style={{color: 'white'}}>
										Choose Template and Start
										<img src="./images/index/box2.png" className="btnImg" />
										<div>+</div>
									</div> */}
									<Link to={'/freshener/'+item.id}  className="flex btnRight" style={{color: 'white'}}>
										Design Now
									</Link>
								</div>
							)
						})
					}
					
				</div>
			</div>
		</div>
	)
}

export default ShapesScreen
