import styles from './ColorSelector.module.css';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

const colors = [
  "#ADD8E6",
  "#87CEEB",
  "#FFB6C1",
  "#FFA07A",
  "#20B2AA",
  "#778899",
  "#B0C4DE",
  "#FFFFE0",
  "#00FF00",
  "#32CD32",
  "#FFD700",
  "#FFA500",
  "#FF4500",
  "#DA70D6",
  "#EEE8AA",
  "#98FB98",
  "#AFEEEE",
  "#DB7093",
  "#FFEFD5",
  "#FFDAB9",
  "#ffffff",
  "#c1d3ec",
  "#b3e5f9",
  "#b8dfc7",
  "#f4f5b7",
  "#f8bec8",
  "#f9cee2",
  "#c5c0df",
  "#fef559",
  "#635ca7",
  "#0093d8",
  "#19245a",
  "#094021",
  "#62251e",
  "#591f3e",
  "#000000",
];

function ColorSelector({ activeColor, handleClick }) {

  const isMobile = useMediaQuery({
    query: "(max-width: 800px)",
  });

  return (
    <div className={[styles.bgColors, isMobile ? styles.bgMobileColors : ''].join(" ")}>
      {
        colors.map(color => (
          <div
            key={color}
            className={[
              styles.bgColor,
              isMobile ? styles.bgMobileColor : '',
              activeColor === color
                ? styles.activeBgColor
                : null,
            ].join(" ")}
            style={{ backgroundColor: color }}
            onClick={() => handleClick(color)}
          ></div>
        ))
      }
    </div>
  )
}

export default ColorSelector;
