import React from 'react';
import { Link } from 'react-router-dom';
import '../css/ShopByCategory.css';
import {useMediaQuery} from "react-responsive";

const categories = [
    { id: 1, name: 'Customised Air Fresheners', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/DALL%C2%B7E+2024-07-25+08.51.49+-+A+creative+and+inviting+image+of+a+customizable+paper+hanging+air+freshener.+The+air+freshener+is+blank+and+white%2C+shaped+like+a+classic+tree+silhouet.webp', linkUrl: '/shapes' },
    { id: 2, name: 'Chemical Products', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/carui1.png', linkUrl: '/store/3' },
    { id: 3, name: 'Air Fresheners', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/carui2.png', linkUrl: '/store/4' },
    { id: 4, name: 'H.P. Pump and Parts', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/carui3.png', linkUrl: '/store/6' },
    { id: 5, name: 'Vacuum Cleaners', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/carui4.png', linkUrl: '/store/7' },
    { id: 6, name: 'Aerosol Products', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/DALLE_2024-08-25_21.45.42_-_A_realistic_image_designed_for_a_website_category_button_de_prm6GKn.webp', linkUrl: '/store/8' },
    { id: 7, name: 'Compression Sprayers', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/carui6.png', linkUrl: '/store/5' },
    { id: 8, name: 'Valeting Accessories', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/carui7.png', linkUrl: '/store/9' },
    { id: 9, name: 'Microfibre', imageUrl: 'https://auto-clean.s3.eu-west-2.amazonaws.com/carui12.png', linkUrl: '/store/12' },

];

function ShopByCategory() {

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  return (
    <div className="shop-by-category">
      <h2>Shop by Category</h2>
      <div className="category-list">
        {categories.map((category, index) => (
          <Link key={category.id} to={category.linkUrl} className={["category-link", (isMobile ? (index === 0 || index === 3 || index === 8) : (index === 0 || index === 4 || index === 8)) ? "category-link-lg" : ""].join(" ")}>
            <div className="category">
              <img src={category.imageUrl} alt={category.name} />
                  <div className="category-overlay-img">
                   <h3 className="category-name">{category.name}</h3>
                 </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ShopByCategory;



