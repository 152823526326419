import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { logout } from '../actions/userActions';
import { CART_CLEAR_ITEMS } from '../constants/cartConstants';
import { setSidebarShow } from '../actions/cartActions';
import { Navbar, Nav, Container, Row, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Search from './search搜索框(1).jsx';
import { show, hide } from '../actions/headFootAction';
import { Drawer, Menu, Popover } from 'antd';

function Header(props) {
  const userLogin = useSelector((state) => state.userLogin);
  const [searchVisible, setSearchVisible] = useState(false);

  const cartItems = useSelector((state) => state.cart.cartItems);
  const sidebarShow = useSelector((state) => state.cart.sidebarShow);
  const [accountDropdownVisible, setAccountDropdownVisible] = useState(false);
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [navLink, setNavLink] = useState('home');
  const [showShop, setShowShop] = useState(false);

  const { userInfo } = userLogin;
  props.history.listen((location) => {
    if (location.pathname.indexOf('/designer') !== -1) {
      dispatch(hide());
    } else {
      dispatch(show());
    }
  });
  useEffect(() => {
    setNavLink(props.location.pathname);
    if (props && props.location) {
      let arr = [
        '/selAddress',
        '/placeorder/tourists-shipping',
        '/payment/tourists-shipping',
        '/order',
      ];
      if (
        arr.indexOf(props.location.pathname) != -1 ||
        props.location.pathname.indexOf('/placeorder') != -1 ||
        props.location.pathname.indexOf('/payment') != -1 ||
        props.location.pathname.indexOf('/order') != -1
      ) {
        setShowNav(false);
      } else {
        setShowNav(true);
      }
    } else {
      setShowNav(true);
    }
    window.onclick = (e) => {
      if (typeof e.target.className !== 'string') return;
      if (e.target.className.indexOf('lapaccount-icon') === -1) {
        setAccountDropdownVisible(false);
      }
    };
  }, [props.location.pathname]);
  const dispatch = useDispatch();

  const logoutHandler = (e) => {
    dispatch({ type: CART_CLEAR_ITEMS });
    dispatch(logout());
  };

  const onToggle = () => {
    if (props && props.location) {
      let arr = [
        '/cart',
        '/selAddress',
        '/placeorder/tourists-shipping',
        '/payment/tourists-shipping',
        '/order',
      ];
      if (
        arr.indexOf(props.location.pathname) != -1 ||
        props.location.pathname.indexOf('/placeorder') != -1 ||
        props.location.pathname.indexOf('/payment') != -1 ||
        props.location.pathname.indexOf('/order') != -1
      ) {
        return;
      }
    }
    console.log(sidebarShow, 9999);
    dispatch(setSidebarShow(!sidebarShow));
  };
  let [open, setOpen] = useState(false);

  function Open_menu() {
    if (window.$('.menu-btn').hasClass('open')) {
      window.$('.right-menu').animate({ width: 'toggle' }, 350);
      window.$('header .mask').stop().fadeOut();
      window.$('.menu-btn').removeClass('open');
    } else {
      window.$('.right-menu').stop().animate({ width: 'toggle' }, 350);
      window.$('header .mask').stop().fadeIn();
      window.$('.menu-btn').addClass('open');
    }
  }

  function Stop_menu() {
    window.$('.right-menu').animate({ width: 'toggle' }, 350);
    window.$('header .mask').stop().fadeOut();
    window.$('.menu-btn').removeClass('open');
  }
  const onClose = () => {
    setShowShop(false);
  };
  //	以下是JSX code， 用来写和html差不多的代码, 然后这个组件被打开之后会立刻渲染jsx的ui
  return (
    <header>
      <div className="head">
        <div className="scrolling-text">
          Free UK Delivery - Orders over £100
          {searchVisible ? 'show' : ''}
        </div>
      </div>
      <div className={'header search-head ' + (searchVisible ? 'show' : '')}>
        <Search setSearchVisible={setSearchVisible}></Search>
      </div>

      <div className="maxWidth">
        <div className="header flex-between">
          <Link to="/">
            <div className="logo logo-icon"></div>
          </Link>

          <div
            className={' flex-between ' + (showNav ? '' : 'hidden')}
            style={{ flex: 1 }}
          >
            <div className="shop nav">
              {/*<div class="flex-center search-bar">*/}
              {/*	<img src="./images/index/search.png" class="search" />*/}
              {/*	<input type="text" placeholder="Search for products" class="ipt" />*/}
              {/*</div>*/}
              <Search></Search>
            </div>
          </div>

          <div className="flex-center">
            <div
              className="search-icon"
              onClick={() => setSearchVisible(true)}
            >
              <img
                src="./images/index/search.png"
                className="mask_search"
              />
            </div>

            <div
              className="account-icon"
              onClick={() => setShowAccountDropdown(!showAccountDropdown)}
            >
              {showAccountDropdown && <div className="f-loading"></div>}
              <img
                src="./images/index/account.png"
                className="mask_search"
              />
              {showAccountDropdown ? (
                <div className="dropdown">
                  {userInfo && userInfo.token ? (
                    <>
                      <Link
                        to="/account"
                        className="account-btn"
                      >
                        {userInfo && userInfo.name && userInfo.name !== 'A' ? (
                          <img
                            className="vip-icon"
                            src="./images/index/vip.png"
                            height="30px"
                          />
                        ) : (
                          ''
                        )}
                        <span className="btn-text">My Account</span>
                      </Link>
                      <Link
                        to="/"
                        onClick={logoutHandler}
                        className="logout-btn"
                      >
                        Log Out
                      </Link>
                    </>
                  ) : (
                    <Link
                      to="/login"
                      className="login-btn"
                    >
                      Login / Register
                    </Link>
                  )}
                </div>
              ) : null}
            </div>
            <div className="relative accountlap-icon">
              <Popover
                content={
                  <div className="dropdown-menu">
                    <div className="right-items">
                      {userInfo && userInfo.token ? (
                        <Link
                          to="/account"
                          style={{ position: 'relative' }}
                        >
                          {userInfo &&
                          userInfo.name &&
                          userInfo.name !== 'A' ? (
                            <img
                              style={{
                                position: 'absolute',
                                top: '-48px',
                                left: '33%',
                                transform: 'translateX(-50%)',
                              }}
                              src="./images/index/vip.png"
                              height="30px"
                            />
                          ) : (
                            ''
                          )}
                          <div
                            className={
                              'lorR nav-item' +
                              (navLink === '/account' ? ' active' : '')
                            }
                          >
                            My Account
                          </div>
                        </Link>
                      ) : (
                        ''
                      )}
                      {userInfo && userInfo.token ? (
                        <Link
                          to="/"
                          onClick={logoutHandler}
                        >
                          <div className="lorR nav-item">Log Out</div>
                        </Link>
                      ) : (
                        <Link to="/login">
                          <div
                            className={
                              'lorR nav-item' +
                              (navLink === 'login' ? ' active' : '')
                            }
                          >
                            Login/Register
                          </div>
                        </Link>
                      )}
                      {userInfo &&
                        userInfo.manager &&
                        userInfo.manager == '1' && (
                          <>
                            <Link to="/admin/userlist">
                              <div
                                className={
                                  'lorR nav-item' +
                                  (navLink === 'admin/userlist'
                                    ? ' active'
                                    : '')
                                }
                              >
                                Products
                              </div>
                            </Link>
                            <Link to="/admin/productlist">
                              <div
                                className={
                                  'lorR nav-item' +
                                  (navLink === 'admin/productlist'
                                    ? ' active'
                                    : '')
                                }
                              >
                                User
                              </div>
                            </Link>
                            <Link to="/admin/orderlist">
                              <div
                                className={
                                  'lorR nav-item' +
                                  (navLink === 'admin/orderlist'
                                    ? ' active'
                                    : '')
                                }
                              >
                                Orders
                              </div>
                            </Link>
                          </>
                          // <NavDropdown title='Admin' id='adminmenue'>
                          //     <LinkContainer to='/admin/userlist'>
                          //         <NavDropdown.Item
                          //           onClick={() => window.location.href = 'https://www.butt-and-co.co.uk/crm/categorylist/'}>Products</NavDropdown.Item>
                          //     </LinkContainer>
                          //
                          //     <LinkContainer to='/admin/productlist'>
                          //         <NavDropdown.Item
                          //           onClick={() => window.location.href = 'https://www.123.com'}>User</NavDropdown.Item>
                          //     </LinkContainer>
                          //
                          //     <LinkContainer to='/admin/orderlist'>
                          //         <NavDropdown.Item>Orders</NavDropdown.Item>
                          //     </LinkContainer>
                          // </NavDropdown>
                        )}
                    </div>
                  </div>
                }
                title={null}
                trigger="click"
              >
                <img
                  src="./images/index/account.png"
                  className="box lapaccount-icon"
                />
              </Popover>
            </div>

            <div
              className="relative cart-icon"
              onClick={onToggle}
            >
              <img
                src="./images/index/box.png"
                className="box"
              />
              <div className="round-num">{cartItems || 0}</div>
            </div>

            <button
              className="all"
              style={{ marginLeft: '.2rem' }}
              onClick={Open_menu}
            >
              <div className="menu-btn">
                <div className="menu-btn__burger"></div>
              </div>
            </button>
          </div>
        </div>

        <div
          className={' flex-between  flex-grow ' + (showNav ? '' : 'hidden')}
          style={{ padding: '0.1rem 0' }}
        >
          <div className="flex-center flex-between flex-grow shop nav nav-wrapper">
            <div
              className="flex-center nav-first shop-nav active line1rem"
              onMouseEnter={() => {
                setShowShop(true);
              }}
              onMouseLeave={() => {
                setShowShop(false);
              }}
            >
              {/*<div onClick={onClose} className="f-loading bg-black"*/}
              {/*     style={{transform: showShop ? 'translateY(0)' : 'translateY(100%)'}}></div>*/}
              <div className="flex">
                <a href="#">
                  <div> Shop</div>
                </a>
                <img
                  src="./images/index/up1.png"
                  className="down"
                />
                <img
                  src="./images/index/UP.png"
                  className="down-y"
                />
              </div>
              <div className={'imgUl' + (showShop ? ' show-shop' : '')}>
                <div
                  className="flex-center wrap"
                  onClick={onClose}
                >
                  <Link
                    to="/store/3"
                    className="carui_box carui-box-icon"
                  >
                    <img
                      src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui1.png"
                      className="carUi"
                    />
                    <div className="carName">
                      <div>Chemical</div>
                      <div>Products</div>
                    </div>
                  </Link>
                  <Link
                    to="/store/4"
                    className="carui_box carui-box-icon"
                  >
                    <img
                      src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui2.png"
                      className="carUi"
                    />
                    <div className="carName">
                      <div>Air</div>
                      <div>Fresheners</div>
                    </div>
                  </Link>
                  <Link
                    to="/store/6"
                    className="carui_box carui-box-icon"
                  >
                    <img
                      src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui3.png"
                      className="carUi"
                    />
                    <div className="carName">
                      <div>H.P. Pump</div>
                      <div>and Parts</div>
                    </div>
                  </Link>
                  <Link
                    to="/store/7"
                    className="carui_box carui-box-icon"
                  >
                    <img
                      src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui4.png"
                      className="carUi"
                    />
                    <div className="carName">
                      <div>Vacuum</div>
                      <div>Cleaners</div>
                    </div>
                  </Link>
                  <Link
                    to="/store/8"
                    className="carui_box carui-box-icon"
                  >
                    <img
                      src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui5.png"
                      className="carUi"
                    />
                    <div className="carName">
                      <div>Aerosol</div>
                      <div>Products</div>
                    </div>
                  </Link>
                  <Link
                    to="/store/5"
                    className="carui_box carui-box-icon"
                  >
                    <img
                      src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui6.png"
                      className="carUi"
                    />
                    <div className="carName">
                      <div>Compression</div>
                      <div>Sprayers</div>
                    </div>
                  </Link>
                  <Link
                    to="/store/9"
                    className="carui_box carui-box-icon"
                  >
                    <img
                      src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui7.png"
                      className="carUi"
                    />
                    <div className="carName">
                      <div>Valeting</div>
                      <div>Accessories</div>
                    </div>
                  </Link>
                  <Link
                    to="/store/20"
                    className="carui_box carui-box-icon"
                  >
                    <img
                      src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui8.png"
                      className="carUi"
                    />
                    <div className="carName">
                      <div>Pipe Fixing</div>
                      <div>And Fitting</div>
                    </div>
                  </Link>
                  <Link
                    to="/store/12"
                    className="carui_box carui-box-icon"
                  >
                    <img
                      src="https://auto-clean.s3.eu-west-2.amazonaws.com/carui12.png"
                      className="carUi"
                    />
                    <div className="carName">
                      <div>Microfibre</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <Link
              to="/shapes"
              aria-label="Custom Air Freshener"
            >
              <button
                className={
                  'design nav-item' + (navLink === '/shapes' ? ' active' : '')
                }
              >
                Design My Air Freshener
              </button>
            </Link>
            <Link to="/about">
              <div
                className={
                  'about nav-item' + (navLink === '/about' ? ' active' : '')
                }
              >
                About
              </div>
            </Link>
            <Link to="/contact">
              <div
                className={
                  'contact nav-item' + (navLink === '/contact' ? ' active' : '')
                }
              >
                Contact
              </div>
            </Link>
          </div>
        </div>
        <div
          className="mask"
          onClick={Stop_menu}
        >
          <div className="right-menu">
            <div className="design">
              <Link to="/shapes">
                <div className="designButton">Design My Air Freshener</div>
              </Link>
            </div>

            <div className="flex-center">
              <div>Shop</div>
              <img src="./images/index/down3.png" />
            </div>
            <div className="shopContent">
              <div>
                <Link to="/store/3">Chemical Products</Link>
              </div>
              <div>
                <Link to="/store/4">Air Fresheners</Link>
              </div>
              <div>
                <Link to="/store/6">H.P. Pump and Parts</Link>
              </div>
              <div>
                <Link to="/store/7">Vacuum Cleaners</Link>
              </div>
              <div>
                <Link to="/store/8">Aerosol Products</Link>
              </div>
              <div>
                <Link to="/store/5">Compression Sprayers</Link>
              </div>
              <div>
                <Link to="/store/9">Valeting Accessories</Link>
              </div>
              <div>
                <Link to="/store/20">Pipe Fixing andFitting</Link>
              </div>
              <div>
                <Link to="/store/12">Microfibre</Link>
              </div>
              <div>
                <Link to="/storenew/230140300/null">UK hot sales</Link>
              </div>
            </div>
            <div className="line"></div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default withRouter(Header);
