import { SIDES } from '../tools';
import styles from '../../../css/DesignerScreenV2.module.css';

const CanvasDomComponents = ({ type, currentType }) => {
  const value = SIDES[type];
  const isVisible = currentType === value;

  return (
    <div
      id={`${value}-wrap`}
      className={!isVisible ? styles['dom-hidden'] : ''}
    >
      <canvas id={value}></canvas>
    </div>
  );
};

export default CanvasDomComponents;
