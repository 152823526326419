import React, { useState, useEffect } from 'react';
import axios from 'axios';
import  './搜索框(1).css';
import {useHistory} from "react-router-dom";
import {Input} from "antd";
import {CloseOutlined, SearchOutlined} from "@ant-design/icons";

const { Search } = Input;

function SearchBar({setSearchVisible}) {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();
  //是否移动端
  const isMobile = window.navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)
  useEffect(() => {
    const cachedHistory = localStorage.getItem('searchHistory');
    if (cachedHistory) {
      setSearchHistory(JSON.parse(cachedHistory));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
  }, [searchHistory]);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };

  const handleInputChange = async (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);

    if (inputValue) {
      const debouncedSearch = debounce(async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(
            `https://www.butt-and-co.co.uk/api/product/search?q=${inputValue}`
          );
          setResults(response.data);
          setShowResults(true);
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }, 500);

      debouncedSearch();

    } else {
      setShowResults(false);
    }
  };

  const handleProductSelect = (productId) => {
    // Perform the action when a product is selected
    console.log(`Selected product with ID ${productId}`);
    history.push(`/product/${productId}`);
    handleDropdownClose();
    setQuery('');
  };

  const handleDropdownClose = () => {
    setShowResults(false);
  };

  return (
    <div className="search-container">
      {
        isMobile ? (<div className="search-box" style={{color: '#334'}}>
          <input type="text" placeholder="Search" value={query} onChange={handleInputChange} />
          {/*<img src="./images/index/search.png" className="search-btn"/>*/}
          <SearchOutlined className="search-btn" />
          <CloseOutlined className="close-button" onClick={() => setSearchVisible(false)} />

        </div>):( <div className="search-box">

          <div className="flex-center search-bar">
            <Input addonBefore={<SearchOutlined />} placeholder="Search" value={query} onChange={handleInputChange} size="large" style={{width: '100%'}} />
          </div>
        </div>)
      }


      {showResults && (
        <div className="search-results">
          <button className="close-button" onClick={handleDropdownClose}>X</button>
          <div className="search-history">
            <h4>Search History</h4>
            {searchHistory.map((item) => (
              <div
                className="history-item"
                key={item}
                onClick={() => setQuery(item)}
              >
                {item}
              </div>
            ))}
          </div>
          <div className="search-matches">
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              results.map((product) => (
                <div
                  className="product"
                  key={product.id}
                  onClick={() => handleProductSelect(product.id)}
                >
                  <h3>{product.name}</h3>
                  <p>{product.price}</p>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default SearchBar;
